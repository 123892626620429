import { StateCreator, create } from "zustand"
import { devtools } from "zustand/middleware"
import { getDirtyValues, pick } from "../../../../_helpers/_helpers"
import { initialPatron, PatronModel } from "../../patrons/core/patronStore"
import { ActualTicketModel, BookingModel, BookingTicketModel } from "../../bookings/core/bookingsStore"
import { _getGuestAlphabets, _getGuestsByAlphabetLetter } from "./guestsApi"
import { CheckpointModel, TicketModel } from "../../events/core/eventsStore"
import { CheckApiModel, _postCheck } from "./checksApi"


export type GuestTicketModel = {
    id: string,
    booking: string
    ticket: TicketModel,
    value: number,
    status: string
    checks: any[]
    dateCreated: Date,
    dateUpdated: Date
}
export type GuestModel = {
    id: string
    name: string,
    email: string
    phone: string,
    tickets: GuestTicketModel[],
    bookings: BookingModel[]
}

export type CheckModel = {
    id: string,
    bookingTicket: ActualTicketModel,
    checkpoint: CheckpointModel,
    dateCreated: Date,
    dateUpdated: Date,
}

export type Pagination = {
    alphabet: string,
    page: number,
    limit: number,
    totalPages: number,
    totalResults: number,
}

export const initialPagination: Pagination = {
    alphabet: 'A',
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
}

export const initialGuest: GuestModel = {
    id: '',
    name: '',
    email: '',
    phone: '',
    tickets: [],
    bookings: []
}




type GuestsStore = {
    currentGuest: GuestModel,
    alphabets: []
    guests: GuestModel[],
    pagination: Pagination,
    getAlphabets: (eventId: string) => Promise<void>
    getByAlphabetLetter: (eventId: string, letter: string, query?: any) => Promise<void>
    resetGuests: () => void
    setCurrentGuest: (guest: GuestModel) => void
    unsetCurrentGuest: () => void
    postCheckIn: (bookingTicket: string, checkpoint: string) => Promise<any>
}


const createStore: StateCreator<GuestsStore> = (set, get) => ({
    currentGuest: { ...initialGuest },
    alphabets: [],
    guests: [],
    pagination: { ...initialPagination },

    getAlphabets: async (eventId) => {
        const r = await _getGuestAlphabets(eventId)
        set({ alphabets: r.data })
        return r.data
    },

    getByAlphabetLetter: async (eventId, letter, query = {}) => {
        const { pagination } = get()
        const q = pick({ ...pagination, ...query }, ['page', 'limit'])
        const response = await _getGuestsByAlphabetLetter(eventId, letter, q)
        set({ guests: response.data, pagination: q })
        return response.data
    },

    resetGuests: () => {
        set({ guests: [] })
    },

    setCurrentGuest: (guest: GuestModel) => {
        set({ currentGuest: guest })
    },

    unsetCurrentGuest: () => {
        set({ currentGuest: { ...initialGuest } })
    },

    postCheckIn: async (bookingTicket: string, checkpoint: string) => {
        if (!bookingTicket || !checkpoint) return;
        const check: CheckApiModel = {
            bookingTicket,
            checkpoint
        }
        const r = await _postCheck(check)
        return r.data
    }
})

export const guestsStore = create(devtools(createStore))
export const useGuestsStore = guestsStore