import {StateCreator, create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {
  _deleteCheckpoint,
  _deleteEvent,
  _deleteTicket,
  _getCheckpoints,
  _getEvent,
  _getEventByHandle,
  _getEventDashboard,
  _getEvents,
  _getPublicEvent,
  _getTickets,
  _handleExists,
  _patchCheckpoint,
  _patchCheckpointSettings,
  _patchEvent,
  _patchEventImage,
  _patchTicket,
  _postCheckpoint,
  _postCheckpointSettings,
  _postEvent,
  _postTicket,
  _searchEvents,
} from './eventsApi'
import {formatMoney, getDirtyValues, omit, pick} from '../../../../_helpers/_helpers'
import {accountsUsersStore} from '../../settings/core/accountsUsersStore'

export type CheckpointModel = {
  name: string
  event: string
  color: string
  id?: string
}

export type CheckpointSettingsModel = {
  checkpoint: CheckpointModel
  qty: number
  id: string
}

export type BookingPieItemModel = {
  id: number | null
  name?: string
  value?: number
  count?: number
}

export type PieChartDataModel = {
  labels: string[]
  series: number[]
  legend: string[]
}

export type EventLineChartDataModel = {
  labels: string[]
  series: number[]
  ticketsCount?: number
  ticketsValue?: number
}
export type EventDashboardModel = {
  bookingsPieData: PieChartDataModel
  bookingsLineData: EventLineChartDataModel
  salesLineData?: EventLineChartDataModel
}

export const initialEventDashboard: EventDashboardModel = {
  bookingsPieData: {labels: [], series: [], legend: []},
  bookingsLineData: {labels: [], series: [], ticketsCount: 0, ticketsValue: 0},
}

export const initialCheckpoint: CheckpointModel = {
  name: '',
  event: '',
  color: '',
}

export type CheckpointsQuery = {
  limit: number
  page: number
}

export type TicketModel = {
  id?: string
  name: string
  description: string
  price: number
  qty: number
  startDate: Date
  endDate: Date
  minQty: number
  maxQty: number
  checkpoints?: CheckpointModel[]
  checkpointSettings: CheckpointSettingsModel[]
  archived: boolean
  event: string
  forceMinQty: boolean
  enableVariablePrice: boolean
  isPrivate: boolean
  bookedTickets: number
  canBook: {
    canBook: boolean
    reason: string
    code: string
  }
}

export const initialTicket: TicketModel = {
  id: '',
  name: '',
  description: '',
  price: 0,
  qty: 1,
  startDate: new Date(),
  endDate: new Date(),
  minQty: 1,
  maxQty: 10,
  checkpointSettings: [],
  archived: false,
  event: '',
  forceMinQty: false,
  enableVariablePrice: false,
  isPrivate: false,
  bookedTickets: 0,
  canBook: {
    canBook: false,
    reason: '',
    code: '',
  },
}

export type EventStatsModel = {
  bookingsCount: number
  bookingsValue: number
  bookingsTicketsCount: number
  transactionsCount: number
  transactionsAmount: number
  ticketsTotalQty: any
  ticketsTotalValue: any
  minTicketPrice: number
  maxTicketPrice: number
}

export const initialEventsStats: EventStatsModel = {
  bookingsCount: 0,
  bookingsValue: 0,
  bookingsTicketsCount: 0,
  transactionsCount: 0,
  transactionsAmount: 0,
  ticketsTotalQty: 0,
  ticketsTotalValue: 0,
  minTicketPrice: 0,
  maxTicketPrice: 0,
}

export type EventModel = {
  id: string | null
  account: string
  billingModel: string
  commission: number
  sellTickets: boolean
  name: string
  handle: string
  startDate: string
  endDate: string
  location: string
  issueTickets: boolean
  autoApprove: boolean
  status: string
  visibility: string
  currency: string
  ticketsOpeningDate: string
  ticketsClosingDate: string
  excerpt: string
  content: string
  artwork: string
  banner: string
  buttonLabel: string
  organiserName: string
  organiserEmail: string
  organiserPhone: string
  organiserWebsite: string
  organiserX: string
  organiserIG: string
  organiserFB: string
  organiserWhatsapp: string
  organiserYoutube: string
  pendingEmail: string
  completedEmail: string
  cancelledEmail: string
  pendingNotification: string
  completedNotification: string
  cancelledNotification: string
  beforeEmail: string
  beforeNotification: string
  beforeDays: number
  afterEmail: string
  afterDays: number
  afterNotification: string
  archived: boolean
  tickets: TicketModel[]
  allowInstallments: boolean
  maxInstallments: number
  installmentInterval: number
  minBookingAmount: number
  installmentCutOffDate: string
  allowRefunds: boolean
  refundBeforeDays: number
  refundFeePercentage: number
  allowAffiliates: boolean
  affiliateCommission: number
  stats: EventStatsModel
  canBook: {
    canBook: boolean
    reason: string
    code: string
  }
}

export type Pagination = {
  page: number
  limit: number
  totalPages: number
  totalResults: number
}

export const initialPagination: Pagination = {
  page: 1,
  limit: 10,
  totalPages: 1,
  totalResults: 0,
}

export const initialEvent: EventModel = {
  id: null,
  account: '',
  billingModel: 'self',
  sellTickets: true,
  commission: 0,
  name: '',
  handle: '',
  startDate: '',
  endDate: '',
  location: '',
  issueTickets: false,
  autoApprove: false,
  status: 'draft',
  visibility: 'public',
  currency: 'ZAR',
  ticketsOpeningDate: '',
  ticketsClosingDate: '',
  excerpt: '',
  content: '',
  artwork: '',
  banner: '',
  buttonLabel: 'Book Now',
  organiserName: '',
  organiserEmail: '',
  organiserPhone: '',
  organiserWebsite: '',
  organiserX: '',
  organiserIG: '',
  organiserFB: '',
  organiserWhatsapp: '',
  organiserYoutube: '',

  pendingEmail:
    '<p>Hi {{bookingName}},</p> <p>Your booking for {{eventName}} is set to pending.</p> <p>{{organiser}}</p>',
  pendingNotification:
    'Hi {{bookingName}}, your booking for {{eventName}} is set to pending. {{organiser}}',

  completedEmail:
    '<p>Hi {{bookingName}},</p> <p>Your booking for {{eventName}} is set to completed.  You can view your booking by accessing the following link: {{bookingLink}}.</p><p>Please note, the link above takes you to the patron login page.  Enter the phone number you used for the booking.  Then, an OTP will be sent to you via SMS.  After entering it, your booking will appear on the next page.  You can use this method to retrieve all your bookings and tickets in future.</p><p>Enjoy {{eventName}}.</p><p>{{organiser}}</p>',
  completedNotification:
    'Hi {{bookingName}}, your booking for {{eventName}} is set to completed {{organiser}}.  Use the following link to retrieve your tickets:  {{bookingLink}}',

  cancelledEmail:
    '<p>Hi {{bookingName}},</p> <p>Your booking for {{eventName}} is set to cancelled</p> <p>{{organiser}}</p>',
  cancelledNotification:
    'Hi {bookingName}, your booking for {{eventName}} is set to cancelled. {{organiser}}',

  beforeNotification:
    'Hi {{bookingName}}.  This is a reminder that your booking for {eventName} is in {days} days. {{organiser}}',
  beforeEmail:
    '<p>Hi {{bookingName}}.</p> <p>This is a reminder that your booking for {eventName} is in {days} days.</p> <p>{{organiser}}</p>',
  beforeDays: 1,

  afterNotification: 'Hi {{bookingName}}.  Thank you for attending {{eventName}}. {{organiser}}',
  afterEmail:
    '<p>Hi {{bookingName}}.</p> <p>Thank you for attending {{eventName}}.</p> <p>{{organiser}}</p>',
  afterDays: 1,

  archived: false,
  tickets: [],

  allowInstallments: false,
  maxInstallments: 60,
  installmentInterval: 30,
  minBookingAmount: 2500,
  installmentCutOffDate: '',

  // refunds
  allowRefunds: false,
  refundBeforeDays: 3,
  refundFeePercentage: 10,

  // affiliates
  allowAffiliates: false,
  affiliateCommission: 10,

  // stats
  stats: initialEventsStats,

  // can book
  canBook: {
    canBook: false,
    reason: '',
    code: '',
  },
}

export type EventsQuery = {
  sortBy: string
  limit: number
  visibility?: string
  page: number
  scope: string
  archived: boolean
}

export const initialEventsQuery: EventsQuery = {
  sortBy: 'createdAt',
  limit: 10,
  page: 1,
  scope: 'future',
  archived: false,
}

type EventsStore = {
  currentEvent: EventModel
  events: EventModel[]
  pagination: Pagination
  query: EventsQuery
  bookingsPieData: PieChartDataModel
  bookingsLineData: EventLineChartDataModel
  salesLineData: EventLineChartDataModel

  // events
  setQuery: (query: Partial<EventsQuery>) => void
  saveEvent: (event: Partial<EventModel>) => Promise<void>
  saveEventImage: (fieldName: string, image: string) => Promise<void>
  handleExists: (handle: string) => Promise<boolean>
  getEvents: (accountId: string) => Promise<EventModel[]>
  getMoreEvents: (accountId: string) => Promise<EventModel[]>
  getEvent: (id: string) => Promise<void>
  getEventByHandle: (id: string) => Promise<EventModel>
  setCurrentEvent: (id: string, isPublic: boolean) => Promise<void>
  unsetCurrentEvent: () => void
  deleteEvent: (id: string) => Promise<void>
  getEventDashboard: (id: string) => Promise<EventDashboardModel>
  searchEvents: (query: string) => Promise<EventModel[]>

  // public event
  publicEvent: EventModel
  getPublicEvent: (id: string) => Promise<EventModel>

  // checkpoint functions
  checkpoints: CheckpointModel[]
  saveCheckpoint: (eventId: string, checkpoint: Partial<CheckpointModel>) => Promise<void>
  getCheckpoints: (eventId: string, query: CheckpointsQuery) => Promise<void>
  deleteCheckpoint: (eventId: string, id: string) => Promise<void>

  // saveTicket
  tickets: TicketModel[]
  saveTicket: (eventId: string, ticket: TicketModel) => Promise<void>
  getTickets: (eventId: string, query: any) => Promise<void>
  deleteTicket: (eventId: string, id: string) => Promise<void>
}

const createStore: StateCreator<EventsStore> = (set, get) => ({
  currentEvent: {...initialEvent},
  events: [],
  checkpoints: [],
  query: {...initialEventsQuery},
  pagination: {...initialPagination},
  bookingsPieData: {labels: [], series: [], legend: []},
  bookingsLineData: {labels: [], series: [], total: 0},
  salesLineData: {labels: [], series: [], total: 0},

  publicEvent: {
    ...initialEvent,
  },

  setQuery: (query: Partial<EventsQuery>) => {
    set({query: {...get().query, ...query}})
  },

  saveEvent: async (event: Partial<EventModel>) => {
    // get dirty values
    const {currentEvent} = get()

    // if event i snew post it,
    let response: any = {}

    if (!currentEvent.id) {
      const values = pick(event, [
        'name',
        'handle',
        'startDate',
        'endDate',
        'location',
        'afterEmail',
        'afterNotification',
        'cancelledEmail',
        'cancelledNotification',
        'completedEmail',
        'completedNotifications',
      ])
      response = await _postEvent(values)
      const newEvent = {
        ...initialEvent,
        ...response.data,
        artwork: response.data.artwork ? response.data.artwork : 'noimage',
      }

      // add event to the events list
      const {events} = get()
      const newEvents = [newEvent, ...events]
      set({events: newEvents})

      return newEvent
    } else {
      const dirtyValues = getDirtyValues(event, currentEvent)
      const values = omit(dirtyValues, ['id', 'canBook', 'stats', 'tickets'])
      response = await _patchEvent(currentEvent.id, values)
      const updatedEvent = {
        ...initialEvent,
        ...response.data,
        artwork: response.data.artwork ? response.data.artwork : 'noimage',
      }

      // update event based on id in response

      const {events} = get()
      const newEvents = events.map((event) => {
        if (event.id === response.data.id) {
          return updatedEvent
        }
        return event
      })
      set({events: newEvents})

      // update current event
      set({currentEvent: updatedEvent})

      return updatedEvent
    }
  },

  saveEventImage: async (fieldName: string, image: string) => {
    // save event image
    const {currentEvent} = get()
    const response = await _patchEventImage(currentEvent.id, fieldName, image)

    // update the current event
    set({currentEvent: {...currentEvent, ...response.data}})
  },

  getEvents: async (accountId: string) => {
    // get events
    const {query} = get()
    const response = await _getEvents({...query, account: accountId})
    const pagination: Pagination = {
      page: response.data.page,
      limit: response.data.limit,
      totalPages: response.data.totalPages,
      totalResults: response.data.totalResults,
    }

    // start each result with initial Values (to avoid uncontrolled inputs error)
    const events = response.data.results.map((event: EventModel) => {
      return {...initialEvent, ...event, artwork: event.artwork ? event.artwork : 'noimage'}
    })

    set({events, pagination})

    return events
  },

  searchEvents: async (query: string) => {
    // get events
    const response = await _searchEvents({query})

    // start each result with initial Values (to avoid uncontrolled inputs error)
    const events = response.data.map((event: EventModel) => {
      return {...initialEvent, ...event, artwork: event.artwork ? event.artwork : 'noimage'}
    })

    set({events})

    return events
  },

  getMoreEvents: async () => {
    // update query
    const {query, pagination} = get()
    const newQuery = {...query, page: pagination.page + 1}
    set({query: newQuery})

    // get events and append them to the events list
    const response = await _getEvents(newQuery)

    const newEvents = response.data.results.map((event: EventModel) => {
      return {...initialEvent, ...event, artwork: event.artwork ? event.artwork : 'noimage'}
    })

    const allEvents = [...get().events, ...newEvents]
    set({events: allEvents})

    // update pagination
    const newPagination: Pagination = {
      ...pagination,
      page: response.data.page,
      totalPages: response.data.totalPages,
      totalResults: response.data.totalResults,
    }
    set({pagination: newPagination})

    return allEvents
  },

  getEvent: async (id: string) => {
    // get event
    const response = await _getEvent(id)

    // new eventp
    const newEvent = {
      ...initialEvent,
      ...response.data,
      artwork: response.data.artwork ? response.data.artwork : 'noimage',
    }

    set({currentEvent: newEvent})
  },

  getEventByHandle: async (handle: string) => {
    // get event
    const response = await _getEventByHandle(handle)

    // new event
    const newEvent = {
      ...initialEvent,
      ...response.data,
      artwork: response.data.artwork ? response.data.artwork : 'noimage',
    }

    set({currentEvent: newEvent})

    return response.data
  },

  getEventDashboard: async (id: string) => {
    try {
      // get event
      const response = await _getEventDashboard(id)

      // PREPARE PIE CHART
      const {account} = accountsUsersStore.getState().selectedAccountsUsers
      const labels = response.data.bookingsPie.map((item: BookingPieItemModel) => {
        return item.name ? item.name : 'Others'
      })
      const series = response.data.bookingsPie.map((item: BookingPieItemModel) => {
        return item.value ? item.value : item.count
      })
      const legend = response.data.bookingsPie.map((item: BookingPieItemModel) => {
        return item.value
          ? (item.name !== undefined ? item.name : 'Other') +
              ' ' +
              formatMoney(item.value, account.currency, 0)
          : item.name + ' ' + item.count
      })

      // PREPARE DASHBOARD
      const dashboard: EventDashboardModel = {
        bookingsPieData: {labels, series, legend},
        bookingsLineData: {...response.data.bookingsLine},
        salesLineData: {...response.data.salesLine},
      }

      // SET IN STORE
      set({
        bookingsPieData: dashboard.bookingsPieData,
        bookingsLineData: dashboard.bookingsLineData,
        salesLineData: dashboard.salesLineData,
      })

      //RETURN
      return dashboard
    } catch (err) {
      //
    }
    return {
      bookingsPieData: {labels: [], series: [], legend: []},
      bookingsLineData: {labels: [], series: [], ticketsCount: 0, ticketsValue: 0},
    }
  },

  deleteEvent: async (id: string) => {
    // delete event
    await _deleteEvent(id)

    // remove event from the list
    const {events} = get()
    const newEvents = events.filter((event) => event.id !== id)
    set({events: newEvents})

    // unset current event
    get().unsetCurrentEvent()
  },

  handleExists: async (handle: string) => {
    try {
      // check if handle exists
      const response = await _handleExists(handle)
      if (response.data.exists === true) {
        return true
      }
      return false
    } catch {
      return false
    }
  },

  setCurrentEvent: async (id: string, isPublic: boolean = false) => {
    const {events} = get()
    const currentEvent = events.find((event) => event.id === id)

    if (currentEvent) {
      set({currentEvent})
    } else {
      if (isPublic === true) {
        await get().getPublicEvent(id)
      } else {
        await get().getEvent(id)
      }
    }
  },

  unsetCurrentEvent: () => {
    set({currentEvent: initialEvent})
  },

  saveCheckpoint: async (event: string, checkpoint: Partial<CheckpointModel>) => {
    // make sure event id was provided
    if (!event) return

    // if checkpoint is new post it,
    let response: any = {}
    if (!checkpoint.id) {
      delete checkpoint.event
      response = await _postCheckpoint(event, checkpoint)

      // add checkpoint to the list of checkpoints
      const {checkpoints} = get()
      const newCheckpoints = [response.data, ...checkpoints]
      set({checkpoints: newCheckpoints})
    } else {
      delete checkpoint.event
      response = await _patchCheckpoint(event, checkpoint.id, checkpoint)

      // update checkpoints based on id
      const {checkpoints} = get()
      const newCheckpoints = checkpoints.map((checkpoint) => {
        if (checkpoint.id === response.data.id) {
          return response.data
        }
        return checkpoint
      })
      set({checkpoints: newCheckpoints})
    }
  },

  getCheckpoints: async (eventId, query: CheckpointsQuery) => {
    // get checkpoints
    const response = await _getCheckpoints(eventId, query)

    // update checkpoints store
    set({checkpoints: response.data.results})
  },

  deleteCheckpoint: async (eventId, id: string) => {
    // delete checkpoint
    await _deleteCheckpoint(eventId, id)

    // remove checkpoint from the list
    const {checkpoints} = get()
    const newCheckpoints = checkpoints.filter((checkpoint) => checkpoint.id !== id)
    set({checkpoints: newCheckpoints})
  },

  getPublicEvent: async (id: string) => {
    // get event
    const response = await _getPublicEvent(id)
    const event = {...initialEvent, ...response.data}
    set({publicEvent: event})

    return event
  },

  // TICKET FUNCTIONS
  tickets: [],
  saveTicket: async (event: string, ticket: Partial<TicketModel>) => {
    // make sure event id was provided
    if (!event) return

    // if checkpoint is new post it,
    let response: any = {}
    let newTickets: any = []
    let newTicket: any = {}
    let ticketId = ticket.id ? ticket.id : null
    const cps = ticket.checkpointSettings
    const {tickets, checkpoints} = get()

    // CLEAN UP OBJECT

    const payload = pick(ticket, [
      'name',
      'description',
      'price',
      'qty',
      'startDate',
      'endDate',
      'minQty',
      'maxQty',
      'archived',
      'forceMinQty',
      'enableVariablePrice',
      'isPrivate',
    ])

    if (!ticketId) {
      // CREATE NEW TICKET
      response = await _postTicket(event, payload)
      newTicket = response.data

      // CREATE OR UPDATE CHECKPOINT SETTINGS
      if (cps) {
        const postRequests = cps.map((setting) => {
          const postSettings = {
            checkpoint: setting.checkpoint.id,
            qty: setting.qty,
          }
          if (setting.id) {
            return _patchCheckpointSettings(event, newTicket.id, setting.id, postSettings)
          } else {
            return _postCheckpointSettings(event, newTicket.id, postSettings)
          }
        })

        const checkpointSettingsResponses = await Promise.all(postRequests)

        newTicket = {
          ...newTicket,
          checkpointSettings: checkpointSettingsResponses.map((res) => {
            const cp = checkpoints.find((c) => c.id === res.data.checkpoint) || initialCheckpoint
            const r: CheckpointSettingsModel = {
              checkpoint: cp, // Add conditional check to ensure cp is defined
              qty: res.data.qty,
              id: res.data.id,
            }
            return r
          }),
        }
      }

      // ADD NEW TICKET TO THE LIST
      newTickets = [newTicket, ...tickets]
      set({tickets: newTickets})
    } else {
      // UPDATE TICKET
      response = await _patchTicket(event, ticketId, payload)
      newTicket = response.data

      // CREATE OR UPDATE CHECKPOINT SETTINGS
      if (cps) {
        const postRequests = cps.map((setting) => {
          const postSettings = {
            checkpoint: setting.checkpoint.id,
            qty: setting.qty,
          }
          if (setting.id) {
            return _patchCheckpointSettings(event, newTicket.id, setting.id, postSettings)
          } else {
            return _postCheckpointSettings(event, newTicket.id, postSettings)
          }
        })

        const checkpointSettingsResponses = await Promise.all(postRequests)
        newTicket = {
          ...newTicket,
          checkpointSettings: checkpointSettingsResponses.map((res) => {
            const cp = checkpoints.find((c) => c.id === res.data.checkpoint) || initialCheckpoint
            const r: CheckpointSettingsModel = {
              checkpoint: cp, // Add conditional check to ensure cp is defined
              qty: res.data.qty,
              id: res.data.id,
            }
            return r
          }),
        }
      }

      // UPDATE TICKET IN THE LIST
      newTickets = tickets.map((ticket) => {
        if (ticket.id === response.data.id) {
          return newTicket
        }
        return ticket
      })
      set({tickets: newTickets})
    }
  },

  getTickets: async (eventId: string, query: any) => {
    // get tickets
    const response = await _getTickets(eventId, query)

    // patch the checkpointSettings with the checkpoint based on id
    const {checkpoints} = get()
    const newTickets = response.data.results.map((ticket) => {
      const newCheckpointSettings = ticket.checkpointSettings.map((setting) => {
        const cp = checkpoints.find((c) => c.id === setting.checkpoint) || initialCheckpoint
        return {
          ...setting,
          checkpoint: cp,
        }
      })
      return {
        ...ticket,
        checkpointSettings: newCheckpointSettings,
      }
    })

    // update tickets store
    set({tickets: newTickets})
  },

  deleteTicket: async (eventId: string, ticketId: string) => {
    // delete ticket
    await _deleteTicket(eventId, ticketId)

    // remove ticket from the list
    const {tickets} = get()
    const newTickets = tickets.filter((ticket) => ticket.id !== ticketId)
    set({tickets: newTickets})
  },
})

export const eventsStore = create(devtools(createStore))
export const useEventsStore = eventsStore
