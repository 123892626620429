import {StateCreator, create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {pick} from '../../../../_helpers/_helpers'
import {_getTransactions, _postTransaction} from './transactionsApi'

export type TransactionModel = {
  id?: string
  account?: string
  event?: string
  patron?: string
  booking?: string
  status: string
  type: string
  amount: number
  description: string
  gateway: string
  reconciled: boolean
  gatewayTransactionId: string
  dateCreated?: Date
  dateUpdated?: Date
  limitToEvent?: boolean
}

export type Pagination = {
  account: string
  limit: number
  page: number
  totalPages: number
  totalResults: number
  event?: string
  booking?: string
  patron?: string
  sortBy?: string
  status?: string
  type?: string
}

export const initialTransaction = {
  account: '',
  id: '',
  event: '',
  patron: '',
  booking: '',
  status: 'pending',
  type: 'payment',
  amount: 0,
  description: '',
  gateway: '',
  required: true,
  gatewayTransactionId: '0',
  reconciled: false,
  limitToEvent: false,
}

export const initialPagination: Pagination = {
  account: '',
  limit: 10,
  page: 1,
  totalPages: 1,
  totalResults: 0,
}

type TransactionStore = {
  pagination: Pagination
  transactions: TransactionModel[]
  postTransaction: (booking: TransactionModel) => Promise<TransactionModel>
  getTransactions: (account: string, query: Partial<Pagination>) => Promise<void>
}

const createStore: StateCreator<TransactionStore> = (set, get) => ({
  pagination: {...initialPagination},
  transactions: [],

  postTransaction: async (booking: TransactionModel) => {
    // post transaction
    const payload = pick(booking, [
      'account',
      'event',
      'booking',
      'patron',
      'status',
      'type',
      'amount',
      'description',
      'gateway',
      'fees',
      'gatewayTransactionId',
      'reconciled',
      'limitToEvent',
    ])
    if (payload.event === '') delete payload.event

    const response = await _postTransaction(booking.booking, payload)

    // update transactions
    const {transactions} = get()
    const newTransactions = [...transactions, response.data]
    set({transactions: newTransactions})
    return response.data
  },

  getTransactions: async (account: string, query: Partial<Pagination>) => {
    const {pagination} = get()

    // get transactions
    const q = pick({...pagination, ...query, account}, [
      'event',
      'patron',
      'booking',
      'status',
      'type',
      'limit',
      'page',
      'account',
    ])

    // if status or type are empty remove them from the query
    const response = await _getTransactions(q)
    const newTransactions: TransactionModel[] = response.data.results.map((transaction) => {
      return {
        ...initialTransaction,
        ...transaction,
      }
    })

    const newPagination = {
      ...pagination,
      ...q,
      totalResults: response.data.totalResults,
      totalPages: response.data.totalPages,
    }
    // update currentBookings
    set({
      transactions: newTransactions,
      pagination: newPagination,
    })
  },
})

export const transactionStore = create(devtools(createStore))
export const useTransactionStore = transactionStore
