import {StateCreator, create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {
  _patchPatron,
  _postPatron,
  _deletePatron,
  _createPatronOTP,
  _verifyPatronOTP,
  _getPatronMe,
  CreatePatronType,
  _searchPatron,
} from './patronApi'
import axios from 'axios'

export type PatronModel = {
  id: string | null
  name: string
  email: string
  phone: number
  isBanned: boolean
  OTP: string | null
}

export const initialPatron: PatronModel = {
  id: null,
  name: '',
  email: '',
  phone: 27,
  isBanned: false,
  OTP: '',
}

type PatronStore = {
  currentPatron: PatronModel
  isPatronLoggedIn: boolean
  postPatron: (eventId: string, patron: PatronModel) => Promise<PatronModel> // Updated return type
  patchPatron: (patron: Partial<PatronModel>) => Promise<PatronModel> // Updated return type
  deletePatron: (patronId: string) => Promise<void>
  createPatronOTP: (type: string, data: string) => Promise<string>
  verifyOTP: (OTP: string) => Promise<void>
  unsetPatron: () => void
  setPatronFromToken: (patronToken: string) => Promise<void>
  loadPatronFromToken: () => Promise<void>
  searchPatron: (search) => Promise<PatronModel>
  setCurrentPatron: (patron: PatronModel) => void
}

const createStore: StateCreator<PatronStore> = (set, get) => ({
  currentPatron: {...initialPatron},
  isPatronLoggedIn: false,

  setCurrentPatron: (patron: PatronModel) => {
    set((state) => ({
      currentPatron: {
        ...state.currentPatron,
        ...patron,
      },
    }))
  },

  searchPatron: async (search) => {
    const r = await _searchPatron(search)
    return r.data
  },

  postPatron: async (eventId, patron: PatronModel) => {
    let savedPatron: any
    const payload: CreatePatronType = {
      name: patron.name,
      phone: patron.phone,
      email: patron.email,
      isBanned: false,
    }
    savedPatron = await _postPatron(eventId, payload)

    set((state) => ({
      currentPatron: {
        ...state.currentPatron,
        ...savedPatron.data.patron,
      },
    }))

    return get().currentPatron
  },
  patchPatron: async (patron: Partial<PatronModel>) => {
    let savedPatron: any
    const patronId = patron.id
    delete patron.id
    savedPatron = await _patchPatron(patronId, patron)

    set((state) => ({
      currentPatron: {
        ...state.currentPatron,
        ...savedPatron.data,
      },
    }))

    return get().currentPatron
  },

  deletePatron: async (patronId: string) => {
    await _deletePatron(patronId)

    set((state) => ({
      currentPatron: {...initialPatron},
    }))
  },

  createPatronOTP: async (type: string, data: string) => {
    // Implement this function
    const response = await _createPatronOTP(type, data)
    return response.data.OTP
  },

  verifyOTP: async (OTP: string) => {
    // Implement this function
    const response = await _verifyPatronOTP(parseFloat(OTP))

    // save patronToken to local storage
    const {setPatronFromToken} = get()
    setPatronFromToken(response.data.patronToken)
  },

  unsetPatron: () => {
    // clear the loca storage
    localStorage.removeItem('patronToken')

    // remove axios auth token
    axios.interceptors.request.use((config) => {
      delete config.headers.Authorization
      return config
    })

    // update the store
    set((state) => ({
      currentPatron: {...initialPatron},
      isPatronLoggedIn: false,
    }))
  },

  setPatronFromToken: async (patronToken: string) => {
    // set in local storage
    // if item exists in local storage, delete it
    if (localStorage.getItem('patronToken')) localStorage.removeItem('patronToken')
    localStorage.setItem('patronToken', patronToken)

    // remove auth token and add another one if it exists
    axios.interceptors.request.use((config) => {
      delete config.headers.Authorization
      const pt = localStorage.getItem('patronToken')
      if (pt) config.headers.Authorization = `Bearer ${pt}`
      return config
    })

    // get patron
    try {
      const r = await _getPatronMe()
      const newPatron = r.data

      set((state) => ({
        currentPatron: newPatron,
        isPatronLoggedIn: true,
      }))
    } catch (error: any) {
      // 2nd time, remove note from localstorage and logout
      // localStorage.removeItem('patronToken')
      // axios.interceptors.request.use((config) => {
      //   delete config.headers.Authorization
      //   return config
      // })
    }
  },

  loadPatronFromToken: async () => {
    const patronToken = localStorage.getItem('patronToken')

    if (patronToken) {
      const {setPatronFromToken} = get()
      await setPatronFromToken(patronToken)
    }
  },
})

export const patronStore = create(devtools(createStore))
export const usePatronStore = patronStore
