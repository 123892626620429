import axios from "axios";
import { UserModel } from "./accountsUsersStore";
const API_URL = process.env.REACT_APP_API_URL
export const ACCOUNTS_ENDPOINT = `/accounts`
export const ACCOUNT_USERS_ENDPOINT = `/accounts/users`
export const USERS_ENDPOINT = `/users`


export function _postAccount(args) {
  return axios.post(`${ACCOUNTS_ENDPOINT}`, args)
}

export function _patchAccount(id, args) {
  return axios.patch(`${ACCOUNTS_ENDPOINT}/${id}`, args)
}

export function _getAccounts(query) {
  return axios.get(`${ACCOUNTS_ENDPOINT}`, { params: { ...query } })
}

export function _searchAccounts(query) {
  return axios.get(`${ACCOUNTS_ENDPOINT}/search/`, { params: { ...query } })
}


export function _getAccount(id) {
  return axios.get(`${ACCOUNTS_ENDPOINT}/${id}`)
}


/**
 * _fetchAccount
 * @param $id 
 * @param args {UserModel}
 * @returns 
 */
export function _patchUser($id, args: Partial<UserModel>) {
  return axios.patch(`${USERS_ENDPOINT}/${$id}`, args)
}

export function _postUser(args: Partial<UserModel>) {
  return axios.post(`${USERS_ENDPOINT}/`, args)
}

export function _getUsers(accountId) {

  return axios.get(`${ACCOUNTS_ENDPOINT}/${accountId}/users`)
}