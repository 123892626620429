import React, {useEffect} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {TicketModel, useEventsStore} from '../core/eventsStore'
import {formatDateDMY, formatNumber, trimString} from '../../../../_helpers/_helpers'
import clsx from 'clsx'
type TicketItemProps = {
  ticket: TicketModel
  onDelete?: (id: string) => void
  onEdit?: (id: string) => void
}
const TicketItem: React.FC<TicketItemProps> = ({ticket, onDelete, onEdit}: TicketItemProps) => {
  const {currentEvent} = useEventsStore()

  const handleEdit = () => {
    if (onEdit && ticket.id) onEdit(ticket.id)
  }

  const handleDelete = () => {
    if (onDelete && ticket.id) onDelete(ticket.id)
  }
  return (
    <div className='d-flex w-100 border p-5 mb-3 bg-light-hover'>
      <div className='d-flex align-items-center'>
        {/* symbol */}
        <span className='symbol  me-5'>
          <span
            title={ticket.canBook.reason}
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            className={clsx('symbol-label symbol symbol-circle w-15px h-15px', {
              'bg-success': ticket.canBook.canBook === true,
              'bg-danger': ticket.canBook.canBook === false,
            })}
          ></span>
        </span>
      </div>
      <div className='d-flex w-100  px-5'>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center mb-3'>
            {ticket.isPrivate && (
              <KTIcon iconName='lock-3' iconType='solid' className='text-dark me-2 fs-3' />
            )}
            <span className='fw-bolder text-dark me-3 fs-5'>
              {ticket.name}
              {ticket.canBook.canBook === false ? (
                <span className='badge badge-light-danger ms-2'>{ticket.canBook.reason}</span>
              ) : (
                <>
                  {new Date(ticket.startDate).toISOString() &&
                    new Date(ticket.endDate).toISOString() && (
                      <span className='ms-2 text-muted fw-normal fs-6'>
                        From {formatDateDMY(new Date(ticket.startDate).toISOString())} to{' '}
                        {formatDateDMY(new Date(ticket.endDate).toISOString())}
                      </span>
                    )}
                </>
              )}
            </span>
          </div>
          <div className='d-flex'>
            <div className='d-flex w-100 me-10'>
              {currentEvent.sellTickets && (
                <span className='fw-bolder badge badge-light text-dark me-3'>
                  Price: <span className='fw-normal ms-2'>{formatNumber(ticket.price, 2)}</span>
                </span>
              )}
              <span className='fw-bolder badge badge-light text-dark me-3'>
                QTY: <span className='fw-normal ms-2'>{ticket.qty} Spaces</span>
              </span>
              <span className='fw-bolder badge badge-light text-dark me-3'>
                <span className='fw-normal me-2'>
                  {' '}
                  {ticket.checkpointSettings &&
                    ticket.checkpointSettings.reduce((acc, item) => acc + item.qty, 0)}{' '}
                </span>
                Check Points
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex '>
        <button className='btn btn-light-primary btn-sm me-3' onClick={handleEdit}>
          <KTIcon iconName='pencil' />
        </button>
        <button className='btn btn-light-danger btn-sm' onClick={handleDelete}>
          <KTIcon iconName='trash' />
        </button>
      </div>
    </div>
  )
}
export {TicketItem}
