import React, {FC, useEffect} from 'react'

import {ErrorMessage, Field, Form, Formik} from 'formik'
import {PatronModel, usePatronStore} from './core/patronStore'
import {useEventsStore} from '../events/core/eventsStore'
import {bookingStore} from '../bookings/core/bookingsStore'
import toast, {Toaster} from 'react-hot-toast'

import PhoneField from '../../../_components/PhoneField'
import {KTIcon} from '../../../_metronic/helpers'
import SubmitButton from '../../../_components/SubmitButton'
import {daysUntilDate, formatMoney} from '../../../_helpers/_helpers'
import {Link, useParams} from 'react-router-dom'

type Props = {
  onLoggedIn?: () => void
  onLoginError?: (error: any) => void
}

const PatronAuth: FC<Props> = ({onLoggedIn}) => {
  const [createNewPatron, setCreateNewPatron] = React.useState<boolean>(false)
  const [enterOTP, setEnterOTP] = React.useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [checkingPatronBookings, setCheckingPatronBookings] = React.useState<boolean>(false)
  const {publicEvent} = useEventsStore()
  const {currentPatron, isPatronLoggedIn, postPatron, createPatronOTP, verifyOTP, unsetPatron} =
    usePatronStore()

  const [buttonLabel, setButtonLabel] = React.useState<string>('Next')
  const [currentPhone, setCurrentPhone] = React.useState<string>('')
  const [isResendingOTP, setIsResendingOTP] = React.useState<boolean>(false)
  const {bookings, getBookings} = bookingStore()
  const {eventId} = useParams<{eventId: string}>()

  // FETCH PATRON BOOKINGS
  useEffect(() => {
    if (isPatronLoggedIn && eventId && currentPatron.id) {
      setCheckingPatronBookings(true)
      getBookings({event: eventId, patron: currentPatron.id, status: 'pending'})
        .then(() => {})
        .finally(() => {
          setCheckingPatronBookings(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPatron])

  const handleSubmit = async (values: PatronModel, actions) => {
    setIsSubmitting(true)

    if (values.phone.toString().length < 10) {
      toast.error('Please enter a valid phone number')
      setIsSubmitting(false)
      return
    }

    if (createNewPatron === false && enterOTP === false) {
      // CHECK PATRON ACCOUNT
      try {
        setCurrentPhone(values.phone.toString())
        await createPatronOTP('phone', values.phone.toString())
        setEnterOTP(true)
        setCreateNewPatron(false)
        toast.success('Please check your phone for OTP code.')
        setButtonLabel('Verify OTP')
      } catch (err: any) {
        if (err.response.status === 404) {
          setCreateNewPatron(true)
          setEnterOTP(false)
          setButtonLabel('Next')
        } else {
          toast.error(err.response.data.message)
        }
        setIsSubmitting(false)
      }
    } else if (enterOTP === true) {
      // VERIFY OTP
      try {
        if (!values.OTP) return

        await verifyOTP(values.OTP)
        setEnterOTP(false)
        setCreateNewPatron(false)
        setButtonLabel('Next')

        if (onLoggedIn) onLoggedIn()

        // rset the form
        actions.resetForm()

        // GO TO THE NEXT PAGE
      } catch (err: any) {
        setIsSubmitting(false)
        toast.error('Please enter the correct OTP code.')
      }
    } else if (createNewPatron === true) {
      // CREATE NEW PATRON
      try {
        if (publicEvent.id === null) throw new Error('Event not found')

        await postPatron(publicEvent.id, values)
        setCurrentPhone(values.phone.toString())
        await createPatronOTP('phone', values.phone.toString())
        setEnterOTP(true)
        setCreateNewPatron(false)
        setButtonLabel('Verify OTP')

        toast.success('Your details were saves successfully.  Please verify your phone number')
      } catch (err: any) {
        setIsSubmitting(false)
        toast.error(err.response.data.message)
      }
    }

    setIsSubmitting(false)
  }

  const handleResendOTP = async () => {
    try {
      if (currentPhone) {
        setIsResendingOTP(true)
        await createPatronOTP('phone', currentPhone)
        toast.success('Please check your phone for OTP code.')
        setIsResendingOTP(false)
      }
    } catch (err: any) {
      setIsResendingOTP(false)
      toast.error(err.response.data.message)
    }
  }

  const handleCancelClick = async (form) => {
    if (currentPatron.id) {
      await unsetPatron()
      setCreateNewPatron(false)
      setEnterOTP(false)
      return
    } else if (createNewPatron || enterOTP) {
      setCreateNewPatron(false)
      setEnterOTP(false)
    }
  }

  return (
    <>
      <Formik initialValues={currentPatron} enableReinitialize onSubmit={handleSubmit}>
        {(form) => {
          return (
            <Form>
              <Toaster position='top-center' />

              <div className='d-flex flex-column w-100'>
                {isPatronLoggedIn ? (
                  <>
                    <div className='mb-5 fw-bold'>
                      Hello {currentPatron.name.split(' ')[0]}, you are are successfully logged in.{' '}
                      <button
                        onClick={handleCancelClick}
                        className='text-primary btn btn-link d-inline'
                      >
                        Use another account?
                      </button>
                    </div>
                    {checkingPatronBookings === true && (
                      <>
                        <div className='separator separator-solid mb-5'></div>
                        <div className='d-flex align-items-center mb-5'>
                          <div className='spinner spinner-primary spinner-center spinner-border'></div>
                          <div className='ms-5'>Checking incomplete bookings. Just a sec...</div>
                        </div>
                      </>
                    )}

                    {bookings.length > 0 && checkingPatronBookings === false && (
                      <>
                        <div className='separator separator-solid mb-5'></div>
                        <div className='mb-5'>
                          <div className='d-flex flex-column'>
                            <div className='d-flex mb-5'>
                              <KTIcon
                                iconName='message-notif'
                                iconType='outline'
                                className='me-5 fs-2x'
                              />

                              {/* Notice */}
                              <div className='d-flex mb-5'>
                                Hmm... {currentPatron.name.split(' ')[0]}, you have
                                {bookings.length === 1
                                  ? ' an incomplete booking'
                                  : ' incomplete bookings'}
                                . Instead of creating another one, choosing from the list below to
                                complete payment.
                              </div>
                            </div>
                            <div className='d-flex flex-column'>
                              {bookings.length &&
                                bookings.map((booking, index) => (
                                  <div
                                    key={booking.id}
                                    className='d-flex bg-hover-light py-3 align-items-sm-center mb-3'
                                  >
                                    {/* begin::Section */}
                                    <Link
                                      to={`/bookings/${booking.id}`}
                                      className='d-flex flex-grow-1 flex-row-fluid flex-wrap'
                                    >
                                      <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                                        <div className='flex-grow-1 me-2'>
                                          <span className='text-dark fw-bolder d-block fs-6'>
                                            {booking.stats.ticketsCount}
                                            {booking.stats.ticketsCount === 1
                                              ? ' Ticket booked'
                                              : ' Tickets Booked'}
                                          </span>
                                          <span className='text-muted fw-semibold d-block fs-7'>
                                            {daysUntilDate(booking.dateCreated, true)} ago
                                          </span>
                                        </div>
                                        <div className='d-flex flex-column align-items-end'>
                                          <span className='badge badge-light text-dark fw-bold mt-2'>
                                            {formatMoney(
                                              booking.stats.ticketsValue -
                                                booking.stats.transactionsValue,
                                              booking.event.currency || 'ZAR',
                                              2
                                            )}
                                          </span>
                                          <span className='me-1 fs-7 text-muted'>
                                            Go to Booking
                                          </span>
                                        </div>
                                      </div>
                                      {/* seperator */}
                                      {index < bookings.length - 1 && (
                                        <div className='separator separator-solid my-2'></div>
                                      )}
                                    </Link>
                                    {/* end::Section */}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className=''>
                      <div className='mb-10 fv-row'>
                        <label className='form-label mb-3' aria-required>
                          Phone Number (required)
                        </label>
                        <PhoneField name='phone' />
                        <div className='text-danger mt-2'>{form.errors.phone}</div>
                      </div>

                      {enterOTP && (
                        <>
                          <div className='mb-10 fv-row'>
                            <label className='form-label mb-3'>
                              Please check your phone for an sms and enter the pin below.{' '}
                              <button
                                type='button'
                                onClick={handleResendOTP}
                                className='btn btn-link text-primary'
                              >
                                Resend OTP
                                {isResendingOTP && (
                                  <span className='spinner spinner-border-sm spinner-primary spinner-center spinner-border ms-2 fs-7'></span>
                                )}
                              </button>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg'
                              name='OTP'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='OTP' />
                            </div>
                          </div>
                        </>
                      )}
                      {createNewPatron && (
                        <>
                          <div className='mb-10 fv-row'>
                            <label className='form-label mb-3'>Email Address (optional)</label>
                            <Field
                              type='text'
                              className='form-control form-control-lg '
                              name='email'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='email' />
                            </div>
                          </div>

                          <div className='mb-10 fv-row'>
                            <label className='form-label mb-3'>Your Name (required)</label>

                            <Field
                              type='text'
                              className='form-control form-control-lg '
                              name='name'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='name' />
                            </div>
                          </div>
                        </>
                      )}

                      <div className='mb-10 fv-row '>
                        <SubmitButton
                          label={buttonLabel}
                          isValid={form.isValid}
                          isSubmitting={isSubmitting}
                          className='btn btn-primary w-100 mb-5'
                        />
                        {(createNewPatron || enterOTP) && (
                          <button
                            onClick={() => handleCancelClick(form)}
                            type='button'
                            className='btn btn-light-danger w-100'
                          >
                            <KTIcon iconName='angle-right' className='me-2' />
                            Cancel
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export {PatronAuth}
