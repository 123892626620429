import {FieldArray, Form, Formik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {daysUntilDate, formatNumber, getCurrencySymbol} from '../../../_helpers/_helpers'
import {eventsStore} from '../events/core/eventsStore'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {BookingTicketItem} from './PublicBookingPartials/BookingTicketItem'
import {usePatronStore} from '../patrons/core/patronStore'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import toast, {Toaster} from 'react-hot-toast'
import {BookingModel, BookingTicketModel, bookingStore, useBookingStore} from './core/bookingsStore'
import {PatronAuth} from '../patrons/PatronAuth'
import {useAuthStore} from '../auth/core/authStore'
import {KTIcon} from '../../../_metronic/helpers'
import {PaystackButton} from 'react-paystack'
import {useAccountsUsersStore} from '../settings/core/accountsUsersStore'
import {initialTransaction, useTransactionStore} from '../transactions/core/transactionsStore'
import {useAppSettingsStore} from '../settings/core/appSettingsStore'

const PublicBookingPage: FC = () => {
  const {currentBooking, syncTickets, saveBooking, resetCurrentBooking} = bookingStore()
  const {getCurrentAccount} = useAccountsUsersStore()
  const {isPatronLoggedIn, loadPatronFromToken, currentPatron} = usePatronStore()
  const {postTransaction} = useTransactionStore()
  const {publicEvent, getPublicEvent} = eventsStore()
  const {eventId} = useParams<{eventId: string}>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const {appSettings} = useAppSettingsStore()
  const [paymentProps, setPaymentProps] = useState<any>({})
  const {isLoggedIn, logout} = useAuthStore()
  const {setTempBooking, tempBooking} = useBookingStore()

  // SETUP PAY STACK WHEN PATRON CHANGES
  useEffect(() => {
    if (currentPatron.id) {
      // PAYSTACK
      setPaymentProps({
        publicKey: appSettings.paystackPublicKey,
        email: currentPatron.email ? currentPatron.email : `${currentPatron.phone}@skybookings.net`,
        currency: getCurrentAccount()?.currency ?? 'ZAR',
        text: 'Pay Now',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPatron])

  //
  useEffect(() => {
    resetCurrentBooking()
    setIsLoading(false)

    if (eventId && publicEvent.id !== eventId) {
      // get event id
      getPublicEvent(eventId).then((res) => {
        syncTickets(res.tickets)
      })
    } else {
      syncTickets(publicEvent.tickets)
    }

    // load patron
    if (localStorage.getItem('patronToken') && !currentPatron.id) {
      loadPatronFromToken()
    }

    //eslint-disable-next-line
  }, [eventId, publicEvent.id])

  const handleSubmit = async (values: BookingModel, action) => {
    setIsLoading(true)
    try {
      // 1. SETUP BOOKING
      const payload: BookingModel = {
        ...values,
        event: {id: publicEvent.id || ''},
        patron: {id: currentPatron.id || ''},
        value: values.bookingTickets.reduce(
          (acc, bookingTicket) => acc + bookingTicket.qty * bookingTicket.ticket.price,
          0
        ),
      }

      // CREATE BOOKING IF NOT SELLING TICKETS
      if (!publicEvent.sellTickets) {
        // const booking = await saveBooking(payload)
        const booking = await saveBooking(payload)

        // navigate to the booking page
        navigate(`/bookings/${booking.id}/`)
        setIsLoading(false)
      }
    } catch (err: any) {
      toast.error(err.data.response.message)
      return
    }
  }

  const handlePaymentDone = async (response) => {
    setIsLoading(true)
    try {
      if (!tempBooking) throw new Error('Booking payload not found')

      // save the booking
      const value = tempBooking.bookingTickets.reduce(
        (acc, bookingTicket) => acc + bookingTicket.qty * bookingTicket.ticket.price,
        0
      )

      const payload: BookingModel = {
        ...tempBooking,
        event: {id: eventId || ''},
        patron: {id: currentPatron.id || ''},
        value,
      }

      const booking = await saveBooking(payload)

      // 2. CREATE THE TRANSACTION
      const transactionPayload: any = {
        ...initialTransaction,
        patron: booking.patron.id,
        booking: booking.id,
        event: booking.event.id,
        status: 'complete',
        type: 'payment',
        amount: booking.value,
        description: 'Booking Payment: ' + response.reference,
        gateway: 'paystack',
        gatewayTransactionId: response.reference,
        reconciled: false,
        account: booking.event.account,
      }

      await postTransaction(transactionPayload)
      setIsLoading(false)

      // redirect to the tickets page
      resetCurrentBooking()
      navigate(`/bookings/${booking.id}/tickets`)
    } catch (err: any) {
      setIsLoading(false)
      toast.error(err.data.response.message)
    }
  }

  return (
    <>
      <Toaster position='top-center' />

      {/* HEADER */}
      <div className='d-flex mw-600px w-100 mt-10 container align-items-center'>
        <div className='d-flex py-3 align-items-sm-center'>
          {/* begin::Symbol */}
          <div className='me-5'>
            <div
              style={{
                backgroundImage: `url(${publicEvent.artwork})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundColor: 'black',
              }}
              className='w-50px h-50px symbol symbol-50px'
            />
          </div>
          {/* end::Symbol */}
          {/* begin::Section */}

          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <Link
                to={`/event/${publicEvent.id}`}
                className='d-flex flex-grow-1 flex-row-fluid flex-wrap'
              >
                <span className='text-dark fw-bolder d-block fs-6'>{publicEvent.name}</span>
              </Link>
              <span className='text-muted fw-semibold d-block fs-7'>
                Starts in {daysUntilDate(new Date(publicEvent?.startDate), true)}
              </span>
            </div>
          </div>

          {/* end::Section */}
        </div>
      </div>

      {/* PATRON LOGIN */}
      <div className='d-flex mw-600px w-100 mt-10 mb-10 container align-items-center'>
        <div className='w-100 card card-custom'>
          <div
            className='card-header collapsible cursor-pointer rotate'
            data-bs-toggle='collapse'
            data-bs-target='#card_patron_collapsible'
          >
            <h3 className='card-title fw-bolder'>
              <span className='me-3'>
                {!isPatronLoggedIn && (
                  <KTIcon iconName='information-2' iconType='solid' className='text-danger fs-2' />
                )}
                {isPatronLoggedIn && (
                  <KTIcon iconName='check-circle' iconType='solid' className='text-success fs-2' />
                )}
              </span>
              Step 1. Identify Yourself
            </h3>
            <div className='card-toolbar rotate-180'>
              <i className='ki-duotone ki-down fs-1'></i>
            </div>
          </div>
          <div id='card_patron_collapsible' className={`collapse show `}>
            <div className='card-body'>
              <PatronAuth />
            </div>
          </div>
        </div>
      </div>

      <Formik
        initialValues={currentBooking}
        validateOnChange={true}
        validate={(values) => {
          setTempBooking({
            ...values,
            event: {id: publicEvent.id || ''},
            patron: {id: currentPatron.id || ''},
            value: values.bookingTickets.reduce(
              (acc, bookingTicket) => acc + bookingTicket.qty * bookingTicket.ticket.price,
              0
            ),
          })
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(form) => {
          return (
            <Form>
              <div className='w-100 container d-flex mw-600px flex-column flex-row justify-content-center align-items-center'>
                <div className='w-100 mb-10 card card-custom'>
                  <div
                    className='card-header collapsible cursor-pointer rotate'
                    data-bs-toggle='collapse'
                    data-bs-target='#card_tickets_collapsible'
                  >
                    <h3 className='card-title fw-bolder fs-4'>
                      <div className='me-3'>
                        {' '}
                        {form.values.bookingTickets &&
                        form.values.bookingTickets.some((item) => item.qty > 0) ? (
                          <KTIcon
                            iconName='check-circle'
                            iconType='solid'
                            className='text-success fs-2'
                          />
                        ) : (
                          <KTIcon
                            iconName='information-2'
                            iconType='solid'
                            className='text-danger fs-2'
                          />
                        )}
                      </div>
                      Step 2. Choose Tickets
                    </h3>
                    <div className='card-toolbar rotate-180'>
                      <i className='ki-duotone ki-down fs-1'></i>
                    </div>
                  </div>
                  <div id='card_tickets_collapsible' className={`collapse show`}>
                    <div className='card-body'>
                      <FieldArray name='bookingTickets'>
                        {({form}) => {
                          return (
                            form.values.bookingTickets &&
                            form.values.bookingTickets.map(
                              (bookingTicket: BookingTicketModel, index) => (
                                <BookingTicketItem
                                  key={bookingTicket.ticket.id}
                                  index={index}
                                  bookingTicket={bookingTicket}
                                  form={form}
                                />
                              )
                            )
                          )
                        }}
                      </FieldArray>
                      {(!form.values.bookingTickets ||
                        !form.values.bookingTickets.some(
                          (bt) => bt.ticket?.canBook?.canBook === true
                        )) && (
                        <div className='text-center d-flex flex-column'>
                          <h3 className='text-center text-danger'>No tickets available</h3>
                          <span className='text-center text-muted mb-5'>
                            Tickets for this event are not available at the moment.
                            <Link to={`/event/${publicEvent.id}`}> Go back to event</Link>
                          </span>
                        </div>
                      )}
                      <div className='card-footer'>
                        {publicEvent.sellTickets && (
                          <h3 className='d-flex justify-content-end '>
                            Total: {getCurrencySymbol(publicEvent.currency)}{' '}
                            {formatNumber(
                              form.values.bookingTickets
                                ? form.values.bookingTickets.reduce(
                                    (acc, bookingTicket) =>
                                      acc + bookingTicket.qty * bookingTicket.ticket.price,
                                    0
                                  )
                                : 0,
                              0
                            )}
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {!publicEvent.sellTickets && (
                  <div className='w-100 mb-10 card card-custom'>
                    <div
                      className={clsx('card-header collapsible cursor-pointer rotate', {
                        'text-dark':
                          form.values.bookingTickets &&
                          form.values.bookingTickets.some((item) => item.qty > 0),
                        'text-muted':
                          form.values.bookingTickets &&
                          !form.values.bookingTickets.some((item) => item.qty > 0),
                      })}
                      data-bs-toggle='collapse'
                      data-bs-target='#card_payments_collapsible'
                    >
                      <h3 className='card-title fw-bolder'>
                        <div className='me-3'>
                          {' '}
                          {isPatronLoggedIn &&
                          form.values.bookingTickets &&
                          form.values.bookingTickets.some((item) => item.qty > 0) ? (
                            <KTIcon
                              iconName='check-circle'
                              iconType='solid'
                              className='text-success fs-2'
                            />
                          ) : (
                            <KTIcon
                              iconName='information-2'
                              iconType='solid'
                              className='text-danger fs-2'
                            />
                          )}
                        </div>
                        Step 3. Proceed to Pay
                      </h3>
                      <div className='card-toolbar rotate-180'>
                        <i className='ki-duotone ki-down fs-1'></i>
                      </div>
                    </div>
                    <div id='card_payments_collapsible' className={`collapse show`}>
                      <div className='card-body'>
                        {!isLoading &&
                        isPatronLoggedIn &&
                        form.values.bookingTickets &&
                        form.values.bookingTickets.some((item) => item.qty > 0) ? (
                          <>
                            {publicEvent.sellTickets ? (
                              <PaystackButton
                                {...paymentProps}
                                className={clsx('btn btn btn-lg w-100 fw-bolder', {
                                  'btn-primary':
                                    form.values.bookingTickets &&
                                    form.values.bookingTickets.some((item) => item.qty > 0),
                                  'btn-secondary':
                                    form.values.bookingTickets &&
                                    !form.values.bookingTickets.some((item) => item.qty > 0),
                                })}
                                amount={form.values.bookingTickets.reduce(
                                  (acc, bookingTicket) =>
                                    acc + bookingTicket.qty * bookingTicket.ticket.price * 100,
                                  0
                                )}
                                text={
                                  'Pay ' +
                                  getCurrencySymbol(publicEvent.currency) +
                                  ' ' +
                                  formatNumber(
                                    form.values.bookingTickets
                                      ? form.values.bookingTickets.reduce(
                                          (acc, bookingTicket) =>
                                            acc + bookingTicket.qty * bookingTicket.ticket.price,
                                          0
                                        )
                                      : 0,
                                    0
                                  )
                                }
                              />
                            ) : (
                              <button
                                type='submit'
                                className={clsx('btn btn-lg w-100 fw-bolder', {
                                  'btn-primary':
                                    form.values.bookingTickets &&
                                    form.values.bookingTickets.some((item) => item.qty > 0),
                                  'btn-secondary':
                                    form.values.bookingTickets &&
                                    !form.values.bookingTickets.some((item) => item.qty > 0),
                                })}
                              >
                                Confirm Booking
                              </button>
                            )}
                          </>
                        ) : (
                          <button
                            type='button'
                            disabled
                            className='btn btn-lg w-100 fw-bolder btn-light-danger'
                          >
                            {isLoading ? (
                              <>
                                <span className='spinner-border spinner-border-sm'></span>{' '}
                                Processing...
                              </>
                            ) : (
                              <>
                                {!isPatronLoggedIn && 'Please identify yourself to proceed'}
                                {isPatronLoggedIn &&
                                  !form.values.bookingTickets.some((item) => item.qty > 0) &&
                                  'Please select tickets to proceed'}
                              </>
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )
        }}
      </Formik>

      {publicEvent.sellTickets && tempBooking && (
        <div className='d-flex mw-600px w-100 container align-items-center'>
          <div className='w-100 mb-10 card card-custom'>
            <div
              className={clsx('card-header collapsible cursor-pointer rotate', {
                'text-dark':
                  tempBooking.bookingTickets &&
                  tempBooking.bookingTickets.some((item) => item.qty > 0),
                'text-muted':
                  tempBooking.bookingTickets &&
                  !tempBooking.bookingTickets.some((item) => item.qty > 0),
              })}
              data-bs-toggle='collapse'
              data-bs-target='#card_payments_collapsible'
            >
              <h3 className='card-title fw-bolder'>
                <div className='me-3'>
                  {' '}
                  {isPatronLoggedIn &&
                  tempBooking.bookingTickets &&
                  tempBooking.bookingTickets.some((item) => item.qty > 0) ? (
                    <KTIcon
                      iconName='check-circle'
                      iconType='solid'
                      className='text-success fs-2'
                    />
                  ) : (
                    <KTIcon
                      iconName='information-2'
                      iconType='solid'
                      className='text-danger fs-2'
                    />
                  )}
                </div>
                Step 3. Proceed to Pay
              </h3>
              <div className='card-toolbar rotate-180'>
                <i className='ki-duotone ki-down fs-1'></i>
              </div>
            </div>
            <div id='card_payments_collapsible' className={`collapse show`}>
              <div className='card-body'>
                {!isLoading &&
                isPatronLoggedIn &&
                tempBooking.bookingTickets &&
                tempBooking.bookingTickets.some((item) => item.qty > 0) ? (
                  <>
                    {publicEvent.sellTickets ? (
                      <PaystackButton
                        {...paymentProps}
                        onSuccess={(response) => handlePaymentDone(response)}
                        onClose={() => toast.error('Payment cancelled')}
                        className={clsx('btn btn btn-lg w-100 fw-bolder', {
                          'btn-primary':
                            tempBooking.bookingTickets &&
                            tempBooking.bookingTickets.some((item) => item.qty > 0),
                          'btn-secondary':
                            tempBooking.bookingTickets &&
                            !tempBooking.bookingTickets.some((item) => item.qty > 0),
                        })}
                        amount={tempBooking.bookingTickets.reduce(
                          (acc, bookingTicket) =>
                            acc + bookingTicket.qty * bookingTicket.ticket.price * 100,
                          0
                        )}
                        text={
                          'Pay ' +
                          getCurrencySymbol(publicEvent.currency) +
                          ' ' +
                          formatNumber(
                            tempBooking.bookingTickets
                              ? tempBooking.bookingTickets.reduce(
                                  (acc, bookingTicket) =>
                                    acc + bookingTicket.qty * bookingTicket.ticket.price,
                                  0
                                )
                              : 0,
                            0
                          )
                        }
                      />
                    ) : (
                      <button
                        type='submit'
                        className={clsx('btn btn-lg w-100 fw-bolder', {
                          'btn-primary':
                            tempBooking.bookingTickets &&
                            tempBooking.bookingTickets.some((item) => item.qty > 0),
                          'btn-secondary':
                            tempBooking.bookingTickets &&
                            !tempBooking.bookingTickets.some((item) => item.qty > 0),
                        })}
                      >
                        Confirm Booking
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    type='button'
                    disabled
                    className='btn btn-lg w-100 fw-bolder btn-light-danger'
                  >
                    {isLoading ? (
                      <>
                        <span className='spinner-border spinner-border-sm'></span> Processing...
                      </>
                    ) : (
                      <>
                        {!isPatronLoggedIn && 'Please identify yourself to proceed'}
                        {isPatronLoggedIn &&
                          !tempBooking.bookingTickets.some((item) => item.qty > 0) &&
                          'Please select tickets to proceed'}
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {isLoggedIn() && (
        <Modal show={true} centered>
          <Modal.Header>
            <Modal.Title className='text-center'>Admin User Detected</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='d-flex '>
              <KTIcon
                iconName='cross-circle'
                className='svg-icon-4x svg-icon fs-3x svg-icon-warning'
              />

              <span className='ms-3'>
                You are currently logged in as an admin user. Please logout to proceed.
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-light-danger' onClick={logout}>
              Logout
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export {PublicBookingPage}
