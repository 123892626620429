import {create, StateCreator} from 'zustand'
import {devtools} from 'zustand/middleware'

export type AppSettingsModel = {
  baseUrl: string
  apiUrl: string
  uploadsUrl: string
  fee: number
  gatewayFeePercentage: number
  gatewayFee: number
  version: string
  paystackPublicKey: string
  name: string
}

export const initialAppSettings: AppSettingsModel = {
  baseUrl: process.env.REACT_APP_PUBLIC_URL || 'http://localhost:4000',
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:3000/v1',
  uploadsUrl: process.env.REACT_APP_API_URL + '/uploads' || 'http://localhost:3000/v1/uploads',
  fee: Number(process.env.REACT_APP_SB_COMMISSION_PERCENTAGE) || 5,
  gatewayFeePercentage: Number(process.env.REACT_APP_PAYSTACK_FEES_PERCENTAGE) || 2.9,
  gatewayFee: Number(process.env.REACT_APP_PAYSTACK_FEES) || 1,
  version: process.env.REACT_APP_VERSION || '2.0.0',
  paystackPublicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || 'pk_test_1234567890',
  name: process.env.REACT_APP_THEME_NAME || 'skybookings',
}

type AppSettingsStore = {
  appSettings: AppSettingsModel
  setSettings: (appSettings: AppSettingsModel) => void
  getSettings: () => AppSettingsModel
}

const createStore: StateCreator<AppSettingsStore> = (set, get) => ({
  appSettings: initialAppSettings,
  setSettings: (appSettings: AppSettingsModel) => set({appSettings}),
  getSettings: () => get().appSettings,
})

export const appSettingsStore = create(devtools(createStore))
export const useAppSettingsStore = appSettingsStore
