import { StateCreator, create } from "zustand"
import { devtools } from "zustand/middleware"
import { pick } from "../../../../_helpers/_helpers"
import { _getEventBalance, _getEventStatement, _syncEventStatement } from "./eventStatementApi"


export type EventStatementModel = {
    id?: string,
    account?: string,
    transaction?: string,
    amount: number,
    balance: number,
    description: string,
    dateCreated?: Date,
}

export type Pagination = {

    limit: number,
    page: number,
    totalPages: number,
    totalResults: number
    event?: string,
    booking?: string,
    patron?: string,
    sortBy?: string
    status?: string,
    type?: string,
}


export const initialEventStatement = {
    id: '',
    event: '',
    patron: '',
    booking: '',
    status: 'pending',
    type: 'payment',
    amount: 0,
    description: '',
    gateway: '',
    required: true,
    gatewayEventStatementId: '0',
    reconciled: false,

}

export const initialPagination: Pagination = {
    limit: 10,
    page: 1,
    totalPages: 1,
    totalResults: 0,
}


type EventStatementStore = {
    eventBalance: number,
    pagination: Pagination,
    eventStatement: EventStatementModel[],
    getEventBalance: (account: string) => Promise<void>,
    getEventStatement: (account: string, query: Partial<Pagination>) => Promise<void>,
    syncEventStatement: (account: string) => Promise<void>,
}


const createStore: StateCreator<EventStatementStore> = (set, get) => ({
    eventBalance: 0,
    pagination: { ...initialPagination },
    eventStatement: [],

    getEventBalance: async (event: string) => {
        const response = await _getEventBalance(event)
        set({ eventBalance: response.data.balance })
    },

    getEventStatement: async (event: string, query: Partial<Pagination>) => {
        const { pagination } = get()

        // get eventStatements
        const q = pick({ ...pagination, ...query }, ['limit', 'page'])

        // if status or type are empty remove them from the query
        const response = await _getEventStatement(event, q)
        const newEventStatement: EventStatementModel[] = response.data.results.map((eventStatement) => {
            return {
                ...initialEventStatement,
                ...eventStatement,
            }
        })

        const newPagination = {
            ...pagination,
            ...q,
            totalResults: response.data.totalResults,
            totalPages: response.data.totalPages,
        }
        // update currentBookings
        set({
            eventBalance: response.data.balance,
            eventStatement: newEventStatement,
            pagination: newPagination,
        })
    },

    syncEventStatement: async (event: string) => {
        const { pagination } = get()
        const response = await _syncEventStatement(event)

        const newEventStatement: EventStatementModel[] = response.data.results.map((eventStatement) => {
            return {
                ...initialEventStatement,
                ...eventStatement,
            }
        })

        const newPagination = {
            ...pagination,
            eventBalance: response.data.balance,
            totalResults: response.data.totalResults,
            totalPages: response.data.totalPages,
        }

        // update currentBookings
        set({
            eventStatement: newEventStatement,
            pagination: newPagination,
        })
    }
})

export const eventStatementStore = create(devtools(createStore))
export const useEventStatementStore = eventStatementStore
