import axios from "axios"
import { GuestModel } from "./guestsStore"

export const GUESTS_ENDPOINT = `/guests`


export function _getGuestAlphabets($eventId: string) {
    return axios.get(`${GUESTS_ENDPOINT}/${$eventId}/alphabets`)
}

export function _getGuestsByAlphabetLetter($event, $letter, $query) {
    return axios.get(`${GUESTS_ENDPOINT}/${$event}/alphabets/${$letter}`, { params: { ...$query } })
}