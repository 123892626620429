import React, {useState} from 'react'
import {ErrorMessage, Field, Form, Formik, FormikValues} from 'formik'
import clsx from 'clsx'
import SubmitButton from '../../../../_components/SubmitButton'
import * as Yup from 'yup'
import 'react-quill/dist/quill.snow.css' // import styles

import ImageInput from '../../../../_components/ImageInput'
import {useEventsStore} from '../core/eventsStore'
import QuillEditor from '../../../../_components/QuillEditor'

// write validation schema with yup
const validationSchema = Yup.object().shape({
  excerpt: Yup.string().required('Excerpt is required'),
  buttonLabel: Yup.string().required('Button Label is required'),
  content: Yup.string(),
  artwork: Yup.string().required('Artwork is required'),
})

type Props = {
  onValidation?: (values: FormikValues) => void
  onSave?: (values: FormikValues) => void
  step?: string
}

const EventAppearance = (props: Props) => {
  const {step} = props
  const [loading, setLoading] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')
  const [saveIsError, setSaveIsError] = useState<boolean | undefined>(undefined)
  const {currentEvent, saveEvent, saveEventImage} = useEventsStore()

  const handleSubmit = (values, actions: FormikValues) => {
    setLoading(true)

    saveEvent(values)
      .then((response) => {
        setSaveIsError(false)
        setSaveMessage('Event successfully updated')

        setTimeout(() => {
          setSaveIsError(undefined)
        }, 3000)
      })

      .catch((err) => {
        setSaveIsError(true)
        setSaveMessage(err.message)
      })

      .finally(() => setLoading(false))
  }

  const handleSaveImage = async (imageUrl: string) => {
    try {
      // listen to upload progress from global axios.
      await saveEventImage('artwork', imageUrl)
    } catch (err: any) {}
  }

  const handleSaveBanner = async (imageUrl: string) => {
    try {
      // listen to upload progress from global axios.
      await saveEventImage('banner', imageUrl)
    } catch (err: any) {}
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_content_appearance'
        aria-expanded='true'
        aria-controls='kt_content_appearance'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{step && step + '. '}Content & Appearance</h3>
        </div>
      </div>

      <div id='kt_content_appearance' className='show'>
        <Formik
          validationSchema={validationSchema}
          initialValues={currentEvent}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(form) => {
            return (
              <Form>
                <>
                  {/* START::  Account Details */}
                  <div className='card-body border-top p-9'>
                    {/* Main Artwork */}
                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <div className='p-5 border-1 border-dashed rounded bg-light'>
                            <ImageInput
                              title={'Event Artwork'}
                              label={`Upload Event Artwork`}
                              description={`This is your main event artwork page.  Something you would typically post on social media`}
                              onImageSave={handleSaveImage}
                              aspectRatio={4 / 5}
                              initialImage={currentEvent.artwork}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Banner Artwork */}
                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <div className='p-5 col border-1 border-dashed rounded bg-light'>
                            <ImageInput
                              title={'Event Banner'}
                              label={`Upload Event Banner`}
                              description='This image will appear at the top of the booking page similar to a facebook/twitter/linkedin banner.  Try to keep it simple and clean'
                              onImageSave={handleSaveBanner}
                              aspectRatio={6 / 2}
                              initialImage={currentEvent.banner}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='separator separator-dashed my-10'></div>
                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <label className='required fw-bold fs-6 mb-3'>
                            Excerpt:{' '}
                            <span className='fw-normal ms-3 text-muted'>
                              Write a short summary of your event; an elevator pitch.
                            </span>
                          </label>
                          <Field
                            as='textarea'
                            className='form-control form-control-lg '
                            name='excerpt'
                          />
                          <ErrorMessage name='excerpt'>
                            {(msg) => <div className='text-danger'>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>

                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <label className='fw-bold fs-6 mb-3'>
                            Content
                            <span className='fw-normal  ms-3 text-muted'>
                              Write all the main content for your event
                            </span>
                          </label>
                          <QuillEditor name='content' />

                          <ErrorMessage name='content'>
                            {(msg) => <div className='text-danger'>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>

                    <div className='separator separator-dashed my-10'></div>
                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <label className='form-label required'>Booking Button Label</label>

                          <Field name='buttonLabel' className='form-control form-control-lg ' />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='buttonLabel' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    {(saveIsError === true || saveIsError === false) && (
                      <div className='d-flex align-items-center py-3 px-6'>
                        <span
                          className={clsx('text fw-bolder', {
                            'text-danger': saveIsError === true,
                            'text-success': saveIsError === false,
                          })}
                        >
                          {saveMessage}
                        </span>{' '}
                      </div>
                    )}

                    <SubmitButton
                      isValid={form.isValid}
                      isSubmitting={loading}
                      label='Save Changes'
                    />
                  </div>
                  {/* END:: Account Details  */}
                </>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {EventAppearance}
