/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../helpers'

type Props = {
  className: string
}

const EngageWidget10 = ({className}: Props) => (
  <div className={`card card-flush ${className}`}>
    <div className='card-body d-flex flex-column justify-content-center mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'>
      <div className='mb-10'>
        <div className='fs-2hx fw-bold text-gray-800 text-center mb-13'>
          <span className='me-2'>
            Welcome to
            <br />
            <span className='position-relative d-inline-block text-danger'>
              <Link
                to='/crafted/pages/profile/overview'
                className='text-danger
              opacity-75-hover'
              >
                Skybookings 2.0
              </Link>

              <span className='position-absolute opacity-15 bottom-0 start-0 border-4 border-danger border-bottom w-100'></span>
            </span>
          </span>
        </div>

        <div className='text-center'>
          <Link className='btn btn-lg btn-dark' to='/events/create'>
            Create Event
          </Link>
        </div>
      </div>
    </div>
  </div>
)
export {EngageWidget10}
