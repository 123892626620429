import clsx from 'clsx'
import {KTIcon} from '../../../../_metronic/helpers'
import {useEventsStore} from '../../../modules/events/core/eventsStore'
import {PayoutModel} from '../../../modules/transactions/core/payoutsStore'

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  payout: PayoutModel
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  toggleBtnIcon?: string
  toggleBtnLabel?: string
  menuPlacement?: string
  menuTrigger?: string
  onPayoutAction?: (payout: PayoutModel, status: string) => void
  isLoading?: boolean
}

const options = [
  {
    action: 'paid',
    label: 'Mark as Paid',
    icon: 'check-circle',
    iconColor: 'success',
  },

  {
    action: 'hold',
    label: 'Put On Hold',
    icon: 'minus-circle',
    iconColor: 'warning',
  },

  {
    action: 'reverse',
    label: 'Reverse Payout',
    icon: 'arrow-circle-left',
    iconColor: 'info',
  },

  {
    action: 'cancel',
    label: 'Cancel Payout',
    icon: 'cross-circle',
    iconColor: 'danger',
  },
]

const PayoutActionButton = ({
  payout,
  toggleBtnClass = 'btn btn-secondary btn-sm btn-active-primary',
  toggleBtnIconClass = 'fs-3',
  toggleBtnIcon = 'setting-2',
  toggleBtnLabel = 'Options',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
  onPayoutAction,
  isLoading = false,
}: Props) => {
  const {currentEvent} = useEventsStore()

  const handlePayoutOptionClick = (action: string) => {
    onPayoutAction && onPayoutAction(payout, action)
  }

  return (
    <>
      {payout.status !== 'cancelled' && (
        <>
          {/* begin::Menu toggle */}
          <button
            type='button'
            className={clsx('btn ', toggleBtnClass)}
            data-kt-menu-trigger={menuTrigger}
            data-kt-menu-attach='parent'
            data-kt-menu-placement={menuPlacement}
          >
            {isLoading ? (
              <span className='spinner-border spinner-border-sm align-middle ms-2 me-2' />
            ) : (
              <KTIcon iconName={toggleBtnIcon} iconType='outline' className={toggleBtnIconClass} />
            )}
            {toggleBtnLabel}
          </button>
          {/* begin::Menu toggle */}

          {/* begin::Menu */}
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
            data-kt-menu='true'
          >
            {options.map((option, index) => {
              let showOption = false
              switch (payout.status) {
                case 'complete':
                  showOption = option.action === 'reverse'
                  break
                case 'pending':
                  showOption =
                    option.action === 'paid' ||
                    option.action === 'hold' ||
                    option.action === 'cancel'
                  break
                case 'holding':
                  showOption = option.action === 'paid' || option.action === 'cancel'
                  break
                case 'cancelled':
                  showOption = false
                  break
                default:
                  showOption = false
              }

              return (
                <>
                  {showOption && (
                    <>
                      <div key={`option-${index}`} className='menu-item px-3 my-0'>
                        <div
                          className={clsx('menu-link px-3 py-2', {
                            active: currentEvent.status === option.action,
                          })}
                          onClick={() => handlePayoutOptionClick(option.action)}
                        >
                          <span className='menu-icon' data-kt-element='icon'>
                            <KTIcon
                              iconName={option.icon}
                              className={`fs-1 text-${option.iconColor}`}
                            />
                          </span>
                          <span className='menu-title'>{option.label}</span>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )
            })}
            {/* end::Menu item */}
          </div>
          {/* end::Menu */}
        </>
      )}
    </>
  )
}

export {PayoutActionButton}
