import React, {useEffect} from 'react'
import {useField} from 'formik'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // import styles

function QuillEditor({...props}: any) {
  const [field, meta, helpers] = useField(props)

  const handleChange = (value) => {
    // if value is empty set field error
    if (!value) {
      helpers.setError('Content is required')
    } else {
      helpers.setError('')
    }

    helpers.setValue(value)
  }

  return (
    <div>
      <ReactQuill className='myQuillEditor' value={field.value || ''} onChange={handleChange} />
      {meta.touched && meta.error ? <div className='error'>{meta.error}</div> : null}
    </div>
  )
}

export default QuillEditor
