import {useEffect, useState} from 'react'
import {useDashboardsStore} from '../core/dashboardStore'
import {Link} from 'react-router-dom'

type Props = {
  className: string
  description: string
  color: string
  img: string
  linkTo?: string
}

const EventsBookingsCard = ({className, description, color, img, linkTo = '/events'}: Props) => {
  const {dashboardData} = useDashboardsStore()
  const [progress, setProgress] = useState(0)
  const [pctDifference, setPctDifference] = useState(0)

  useEffect(() => {
    if (dashboardData.eventsBookingsCard) {
      // if last week's bookings are less than this weeks bookings set progress as 0
      if (dashboardData.eventsBookingsCard.countBookingTicketsLastWeek === 0) {
        setProgress(0)
        return
      } else {
        const percentageDifference = Math.floor(
          ((dashboardData.eventsBookingsCard.countBookingTicketsThisWeek -
            dashboardData.eventsBookingsCard.countBookingTicketsLastWeek) /
            dashboardData.eventsBookingsCard.countBookingTicketsLastWeek) *
            100
        )

        setPctDifference(percentageDifference)
        if (percentageDifference > 100) {
          setProgress(100)
        } else {
          setProgress(Math.floor(percentageDifference))
        }
      }
    }
  }, [dashboardData])

  return (
    <Link
      to={linkTo}
      className={`card card-flush border-2 border-hover bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
      style={{
        backgroundColor: color,
        backgroundImage: `url('${img}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat',
      }}
    >
      <div className='card-header pt-5'>
        <div className='card-title d-flex'>
          <span className='display-2 fw-bold text-white me-2 lh-1 ls-n2'>
            {dashboardData.eventsBookingsCard?.totalEvents}
          </span>
          <div className='d-flex flex-column'>
            <span className='text-white opacity-75 pt-1 fw-bolder fs-6'>{description}</span>
            <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>
              {dashboardData.eventsBookingsCard?.countBookingTickets} confirmed tickets
            </span>
          </div>
        </div>
      </div>
      <div className='card-body d-flex align-items-end pt-0'>
        <div className='d-flex align-items-start flex-column mt-3 w-100'>
          <div className='d-flex justify-content-between fw-bold fs-7 text-white opacity-75 w-100 mt-auto mb-2'>
            <span>
              {dashboardData.eventsBookingsCard?.countBookingTicketsThisWeek} tickets This Week
            </span>
            <span>{pctDifference}%</span>
          </div>

          <div className='h-8px mb-1 w-100 bg-white bg-opacity-50 rounded'>
            <div
              className='bg-white rounded h-8px'
              role='progressbar'
              style={{width: progress + '%'}}
              aria-valuenow={progress}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
          <span className='fs-8 text-light text-start'>
            Compared to {dashboardData.eventsBookingsCard?.countBookingTicketsLastWeek} last week
          </span>
        </div>
      </div>
    </Link>
  )
}
export {EventsBookingsCard}
