/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useEventsStore} from '../core/eventsStore'
import {KTIcon} from '../../../../_metronic/helpers'
import {formatMoney, formatNumber, trimCharacters} from '../../../../_helpers/_helpers'
import {Doughnut} from 'react-chartjs-2'
import {useAccountsUsersStore} from '../../settings/core/accountsUsersStore'
import {Modal} from 'react-bootstrap'

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
}

const AnalyticsTicketsProgressCards: FC<Props> = ({className}) => {
  const [bookedPct, setBookedPct] = useState(0)
  const [bookedTickets, setBookedTickets] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const {getEventDashboard, currentEvent, tickets, getTickets} = useEventsStore()
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const chartColors = [
    '#3e97ff',
    '#50cd89',
    '#f1416c',
    '#ffc700',
    'rgba(255, 159, 64, 1)',
    '#7e57c2', // Added color: Deep Purple
    '#ff7043', // Added color: Deep Orange
    'rgba(153, 102, 255, 1)', // Added color: Soft Purple
    '#26a69a', // Added color: Teal
    '#78909c', // Added color: Blue Grey
  ]

  useEffect(() => {
    if (!currentEvent.id) return

    // get pie data
    getEventDashboard(currentEvent?.id)
    getTickets(currentEvent?.id, {page: 1, limit: 99})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent.id])

  // UPDATE CHART DATA
  useEffect(() => {
    if (!currentEvent.id) return

    // calculate bookings percentage
    const pct = Math.floor(
      (currentEvent.stats.bookingsTicketsCount / currentEvent.stats.ticketsTotalQty) * 100
    )
    setBookedPct(pct)
    setBookedTickets(currentEvent.stats.bookingsTicketsCount)
  }, [currentEvent, tickets])

  return (
    <>
      {currentEvent.stats.bookingsCount > 0 ? (
        <>
          <button
            onClick={() => setShowModal(true)}
            className={`card border-hover border-1 border-secondary card-flush ${className}`}
          >
            <div className='card-body my-5 p-0 d-flex flex-wrap align-items-center'>
              {/* sales process for each ticket */}
              <div className='d-flex flex-wrap w-100'>
                <div className='d-flex me-3 me-3 mb-3 rounded align-items-center p-3'>
                  <div className='d-flex w-125px h-125px me-3'>
                    <Doughnut
                      data={{
                        labels: ['ticket.name', ''],
                        datasets: [
                          {
                            data: [bookedPct, 100 - bookedPct],
                            backgroundColor: [chartColors[0], '#f3f6f9'],
                            borderWidth: 0,
                          },
                        ],
                      }}
                      options={{
                        cutout: '50%',
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: false,
                          },
                          tooltip: {
                            enabled: false,
                          },
                        },
                      }}
                    />
                  </div>
                  <div className='d-flex flex-column text-center border-start ps-5 ms-5'>
                    <span className='fw-bold display-1'>{bookedPct}%</span>
                    <div className='d-flex justify-content-center text-center'>
                      <span className='text-dark fs-5'>
                        {bookedTickets}{' '}
                        {currentEvent.sellTickets ? 'tickets sold' : 'spaces booked'}
                      </span>
                    </div>
                    <span className='text-center text-primary'>(click to show more)</span>
                  </div>
                </div>
              </div>
            </div>
          </button>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmed Tickets</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='row'>
                {tickets.map((ticket, index) => {
                  const pct = Math.floor((ticket.bookedTickets / ticket.qty) * 100)

                  return (
                    <div key={ticket?.id} className='col-6 p-3 col-lg-6 d-flex align-items-center'>
                      <div className='d-flex w-50px h-50px me-3'>
                        <Doughnut
                          data={{
                            labels: [ticket.name, ''],
                            datasets: [
                              {
                                data: [pct, 100 - pct],
                                backgroundColor: [chartColors[index], '#f3f6f9'],
                                borderWidth: 0,
                              },
                            ],
                          }}
                          options={{
                            cutout: '50%',
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                display: false,
                              },
                              tooltip: {
                                enabled: false,
                              },
                            },
                          }}
                        />
                      </div>
                      <div className='d-flex flex-column'>
                        <span className='fw-bold'>{trimCharacters(ticket.name, 18)}</span>
                        <span className='fw-normal'>
                          {formatNumber(ticket.bookedTickets, 0)}, {pct}%,{' '}
                          {formatMoney(
                            ticket.bookedTickets * ticket.price,
                            selectedAccountsUsers.account.currency,
                            0
                          )}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type='button'
                className='btn btn-light btn-sm'
                onClick={() => {
                  setShowModal(false)
                }}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <div className='card w-100 w-lg-450px min-h-200 bg-transparent mt-3 border-1 border-dashed border-shadow-0'>
          <div className='card-body d-flex flex-center flex-column'>
            <KTIcon iconName='alert-circle' className='fs-2hx text-muted' />
            <span className='text-gray-400 fw-bold fs-3'>No bookings yet</span>
            <span className='text-gray-400 fs-6'>Bookings and sales data will appear here.</span>
          </div>
        </div>
      )}
    </>
  )
}

export {AnalyticsTicketsProgressCards}
