import {FC, useEffect, useState} from 'react'
import {useQueryRequest} from '../../apps/user-management/users-list/core/QueryRequestProvider'
import {useQueryResponse} from '../../apps/user-management/users-list/core/QueryResponseProvider'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {initialQueryState, KTIcon} from '../../../../_metronic/helpers'

import clsx from 'clsx'
import {CheckpointsQuery, useEventsStore} from '../../events/core/eventsStore'
import {useParams} from 'react-router-dom'

type Props = {
  onFilter: (filter: any) => void // filter: {role, last_login}
  isLoading?: boolean
}

const GuestListFilter: FC<Props> = ({onFilter, isLoading}) => {
  const [isFiltered, setIsFiltered] = useState<boolean>(false)
  const [checkpoint, setCheckpoint] = useState<string | undefined>()

  const {checkpoints, getCheckpoints} = useEventsStore()
  const {eventId} = useParams<{eventId: string}>()

  useEffect(() => {
    if (!eventId) return

    const q: CheckpointsQuery = {
      page: 1,
      limit: 25,
    }

    getCheckpoints(eventId, q)
  }, [])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setIsFiltered(false)
    setCheckpoint(undefined)
    onFilter({checkpoint: undefined})
  }

  const filterData = () => {
    setIsFiltered(true)

    // do the same for limit
    onFilter({checkpoint})
  }

  const changeCheckpoint = (checkpoint: string) => {
    if (checkpoint) setCheckpoint(checkpoint)
    if (!checkpoint) setCheckpoint(undefined)
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading && isLoading}
        type='button'
        className={clsx('btn btn-active-secondary', {
          'btn-info btn-color-white': isFiltered,
        })}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon
          iconName='setting-4'
          iconType={isFiltered ? 'solid' : 'outline'}
          className={'fs-2'}
        />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Limit Checkpoints:</label>
            {/* const bookingStatuses = ['pending', 'rejected', 'cancelled', 'complete']; */}
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => changeCheckpoint(e.target.value)}
              value={checkpoint}
            >
              <option value=''></option>
              {checkpoints &&
                checkpoints.map((checkpoint, index) => (
                  <option key={index} value={checkpoint?.id}>
                    {checkpoint.name}
                  </option>
                ))}
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {GuestListFilter}
