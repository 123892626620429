import {StateCreator, create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {EventModel, TicketModel, initialEvent} from '../../events/core/eventsStore'
import {
  PostBookingType,
  UpdateBookingType,
  _getBooking,
  _getBookingTickets,
  _getBookings,
  _getbookingTicket,
  _patchBookings,
  _postBookingTickets,
  _postBookings,
  _shareBookingTicket,
} from './bookingApi'
import {PatronModel, initialPatron} from '../../patrons/core/patronStore'
import {pick} from '../../../../_helpers/_helpers'

export type Check = {
  name: string
  status: string
  qty: number
  checks: number
}

export type BookingTicketModel = {
  ticket: TicketModel
  qty: number
  value: number
  actualTickets?: ActualTicketModel[]
  status: string
}

export type ActualTicketModel = {
  id: string
  booking: string
  ticket: string
  value: number
  status: string
  dateCreated: Date
  dateUpdated: Date
  checks?: Check[]
  sentTo?: PatronModel
}

export type BookingDashboardModel = {
  ticketsCount: number
  ticketsValue: number
  transactionsValue: number
  transactionsCount: number
  tickets: BookingTicketModel[]
  transactions: BookingTransactionModel[]
}

export type BookingStatsModel = {
  ticketsCount: number
  ticketsValue: number
  transactionsValue: number
  transactionsCount: number
}

export const initialBookingStats: BookingStatsModel = {
  ticketsCount: 0,
  ticketsValue: 0,
  transactionsValue: 0,
  transactionsCount: 0,
}

export type BookingModel = {
  id?: string
  event: Partial<EventModel>
  bookingTickets: BookingTicketModel[]
  patron: Partial<PatronModel>
  status: string
  value: number
  dateCreated: Date
  dateUpdated: Date
  stats: BookingStatsModel
  transactionPaymentMethod: string
  transactionDescription: string
  transactionAmount: number
  transactionStatus: string
}

export type BookingsPagination = {
  event?: string
  patron?: string
  status?: string
  limit: number
  page: number
  totalPages: number
  totalResults: number
  sortBy: string
}

export type BookingTicketPagination = {
  limit: number
  page: number
  totalPages: number
  totalResults: number
}

export type BookingTransactionModel = {
  id?: string
  booking: string
  status: string
  type: string
  amount: number
  description: string
  gateway: string
  reconciled: boolean
  gatewayTransactionId: string
  dateCreated?: Date
  dateUpdated?: Date
}

export type TransactionsPagination = {
  status?: string
  type?: string
  limit: number
  page: number
  totalPages: number
  totalResults: number
}

export const initialBookingTransaction = {
  booking: '',
  status: 'pending',
  type: 'payment',
  amount: 0,
  description: '',
  gateway: '',
  required: true,
  gatewayTransactionId: '0',
  reconciled: false,
}

export const initialBooking: BookingModel = {
  event: initialEvent,
  bookingTickets: [],
  patron: initialPatron,
  status: 'pending',
  value: 0,
  dateCreated: new Date(),
  dateUpdated: new Date(),
  stats: {...initialBookingStats},
  transactionAmount: 0,
  transactionDescription: 'Manual payment',
  transactionPaymentMethod: '',
  transactionStatus: '',
}

export const initialBookingsPagination: BookingsPagination = {
  limit: 25,
  page: 1,
  totalPages: 1,
  totalResults: 0,
  sortBy: 'dateCreated:desc',
}

export const initialBookingTicketsPagination: BookingTicketPagination = {
  limit: 12,
  page: 1,
  totalPages: 1,
  totalResults: 0,
}

type BookingStore = {
  pagination: BookingsPagination
  bookingTicketsPagination: BookingTicketPagination
  currentBooking: BookingModel
  currentBookingTicket: BookingTicketModel
  bookings: BookingModel[]
  tempBooking: BookingModel
  setTempBooking: (booking: BookingModel) => void
  updateCurrentBooking: (booking: Partial<BookingModel>) => void
  syncTickets: (tickets: TicketModel[]) => void
  getBookingTickets: (
    bookingId: string,
    pagination: Partial<BookingTicketPagination>
  ) => Promise<void>
  getBookingTicket: (btId: string) => Promise<BookingTicketModel>
  saveBooking: (booking: BookingModel) => Promise<BookingModel>
  getBookings: (query: Partial<BookingsPagination>) => Promise<BookingModel[]>
  getMoreBookings(query: Partial<BookingsPagination>): Promise<BookingModel[]>
  getBooking: (id: string, reload?: boolean) => Promise<BookingModel>
  resetCurrentBooking: () => void
  shareBookingTicket: (
    bookingId: string,
    bookingTicketId: string,
    recipient: string,
    recipientName: string
  ) => void
}

const createStore: StateCreator<BookingStore> = (set, get) => ({
  pagination: {...initialBookingsPagination},
  bookingTicketsPagination: {...initialBookingTicketsPagination},
  bookingTransactions: [],
  currentBooking: {...initialBooking},
  bookings: [],
  transactions: [],
  tempBooking: {...initialBooking},
  currentBookingTicket: {...initialBooking.bookingTickets[0]},

  shareBookingTicket: async (bookingId, bookingTicketId, recipient, recipientName) => {
    // check if recipient is email address or phone number
    let payload: any = recipient.includes('@')
      ? {email: recipient, recipientName}
      : {phone: recipient, recipientName}

    const nBookingTicket: any = await _shareBookingTicket(bookingId, bookingTicketId, payload)

    // update booking ticket with sentTo
    const {currentBooking} = get()
    const newBookingTickets = currentBooking.bookingTickets.map((bookingTicket) => {
      if (bookingTicket.ticket.id === bookingTicketId) {
        return {...bookingTicket, ...nBookingTicket.data}
      }
      return bookingTicket
    })

    const newBooking = {...currentBooking, bookingTickets: newBookingTickets}
    set({currentBooking: newBooking})
  },

  setTempBooking: (booking: BookingModel) => {
    set({tempBooking: booking})
  },

  updateCurrentBooking: (booking: Partial<BookingModel>) => {
    set((state) => ({
      currentBooking: {
        ...state.currentBooking,
        ...booking,
      },
    }))
  },

  syncTickets: (tickets: TicketModel[]) => {
    const bookingTickets: BookingTicketModel[] = tickets.map((ticket, index) => {
      const item: BookingTicketModel = {
        ticket: ticket,
        qty: ticket.forceMinQty ? ticket.minQty : 0,
        status: 'pending',
        value: 0,
      }
      return item
    })
    const {currentBooking} = get()
    const newCurrentBooking = {...currentBooking, bookingTickets: bookingTickets}
    set({currentBooking: newCurrentBooking})
  },

  saveBooking: async (booking: BookingModel) => {
    if (!booking.id && booking.bookingTickets) {
      // update booking
      const bookingPayload: PostBookingType = {
        event: booking.event.id || '',
        patron: booking.patron.id || '',
        status: booking.status,
      }

      const bookingResponse = await _postBookings(bookingPayload)
      const bookingId = bookingResponse.data.id

      // add booking tickets
      const bookingTicketsRequests = booking.bookingTickets.map(async (bookingTicket) => {
        if (bookingTicket.ticket.id && bookingTicket.qty > 0) {
          const payload = {
            ticket: bookingTicket.ticket.id,
            qty: bookingTicket.qty,
          }

          return await _postBookingTickets(bookingId, payload)
        }
      })

      const bookingTicketsResponse = await Promise.all(bookingTicketsRequests)

      // ATTACH ACTUAL TICKETS TO BOOKING TICKETS BASED ON TICKET ID
      const newBookingTickets: BookingTicketModel[] = booking.bookingTickets.map(
        (bookingTicket, index) => {
          const searchedTickets: any = bookingTicketsResponse.filter((res) => {
            if (res) {
              return res.data.find((rpt) => rpt.ticket === bookingTicket.ticket.id)
            }
            return false
          })
          const actualTickets: ActualTicketModel[] = searchedTickets.reduce(
            (acc: ActualTicketModel[], res: any) => {
              if (res) {
                const tickets = res.data.filter(
                  (rpt: any) => rpt.ticket === bookingTicket.ticket.id
                )
                const actuals = tickets.map((rpt: any) => ({...rpt}))
                return [...acc, ...actuals]
              }
              return acc
            },
            []
          )
          let totalValue = 0

          // loop through actual tickets and calculate the total value

          actualTickets.forEach((at) => {
            totalValue += at.value
          })

          return {
            ...bookingTicket,
            actualTickets: actualTickets,
            value: totalValue,
          }
        }
      )

      // CALCULATE VALUE OF BOOKING
      const bookingValue = booking.bookingTickets.reduce((acc, bookingTicket) => {
        return acc + bookingTicket.qty * bookingTicket.ticket.price
      }, 0)

      const newBooking: BookingModel = {
        ...bookingResponse.data,
        bookingTickets: newBookingTickets,
        value: bookingValue,
        transactions: [],
      }
      set({currentBooking: newBooking})
      return newBooking
    } else if (booking.id && booking.status) {
      // update booking
      const {id} = booking
      const payload: UpdateBookingType = {
        status: booking.status,
      }
      delete booking.id
      const response = await _patchBookings(id, payload)
      const {currentBooking} = get()
      const newBooking = {...currentBooking, status: response.data.status}
      set({currentBooking: newBooking})
      return newBooking
    } else {
      throw new Error('There was a problem saving bookings')
    }
  },

  getBookings: async (query: Partial<BookingsPagination>) => {
    const {pagination} = get()

    // get bookings
    const q = pick({...pagination, ...query}, [
      'event',
      'patron',
      'status',
      'limit',
      'page',
      'sortBy',
    ])

    // if event, patron or status are empty remove them from the query
    if (!q.event) delete q.event
    if (!q.patron) delete q.patron
    if (!q.status) delete q.status

    const response = await _getBookings(q)

    const newBookings: BookingModel[] = response.data.results.map((booking) => {
      return {
        ...initialBooking,
        ...booking,
      }
    })

    // set bookings
    set({
      bookings: newBookings,
      pagination: {
        ...pagination,
        ...query,
        totalPages: response.data.totalPages,
        totalResults: response.data.totalResults,
      },
    })

    return response.data
  },

  getMoreBookings: async (query: Partial<BookingsPagination>) => {
    const {pagination} = get()

    // get bookings
    const q = pick({...pagination, ...query}, ['event', 'patron', 'status', 'limit', 'page'])

    // if event, patron or status are empty remove them from the query
    if (!q.event) delete q.event
    if (!q.patron) delete q.patron
    if (!q.status) delete q.status

    const response = await _getBookings(q)

    const newBookings: BookingModel[] = response.data.results.map((booking) => {
      return {
        ...initialBooking,
        ...booking,
      }
    })

    // set bookings
    const {bookings} = get()

    set({
      bookings: [...bookings, ...newBookings],
      pagination: {
        ...pagination,
        ...query,
        totalPages: response.data.totalPages,
        totalResults: response.data.totalResults,
      },
    })

    return response.data
  },

  getBooking: async (id: string) => {
    const response = await _getBooking(id)
    const newBooking = {...initialBooking, ...response.data}
    set({currentBooking: newBooking})
    return newBooking
  },

  getBookingTickets: async (bookingId: string, pagination: Partial<BookingTicketPagination>) => {
    const {currentBooking, bookingTicketsPagination} = get()

    const p = pick({...bookingTicketsPagination, ...pagination}, [
      'limit',
      'page',
      'sort',
      'ticket',
    ])

    const response = await _getBookingTickets(bookingId, p)
    const bookingTickets: BookingTicketModel[] = []
    response.data.results.forEach((bt) => {
      // GET ACTUAL TICKETS

      const actualTicket: ActualTicketModel = {
        id: bt.id,
        booking: bt.booking,
        ticket: bt.ticket.id,
        value: bt.value,
        status: bt.status,
        checks: bt.checks,
        dateCreated: new Date(bt.dateCreated),
        dateUpdated: new Date(bt.dateUpdated),
        sentTo: bt.sentTo,
      }

      const bookingTicket = bookingTickets.find(
        (bookingTicket) => bookingTicket.ticket.id === bt.ticket.id
      )
      if (bookingTicket) {
        bookingTicket.actualTickets?.push(actualTicket)
        bookingTicket.qty += 1
        bookingTicket.value += bt.value
        bookingTicket.status = bt.status
      } else {
        const newBookingTicket: BookingTicketModel = {
          ...bt,
          ticket: bt.ticket,
          qty: 1,
          value: actualTicket.value,
          checks: bt.checks,
          status: bt.status,
          actualTickets: [actualTicket],
          sentTo: bt.sentTo,
        }
        bookingTickets.push(newBookingTicket)
      }
    })

    const newBooking = {...currentBooking, bookingTickets}
    set({currentBooking: newBooking})

    // update bookings list if it exists in the store
    const {bookings} = get()
    const newBookings = bookings.map((booking) => {
      if (booking.id === bookingId) {
        return newBooking
      }
      return booking
    })
    set({
      bookings: newBookings,
      bookingTicketsPagination: {
        ...p,
        totalpages: response.data.totalPages,
        totalResults: response.data.totalResults,
      },
    })
  },

  getBookingTicket: async (btId: string) => {
    const response = await _getbookingTicket(btId)
    const bt = response.data
    const actualTicket: ActualTicketModel = {
      id: bt.id,
      booking: bt.booking,
      ticket: bt.ticket.id,
      value: bt.value,
      status: bt.status,
      checks: bt.checks,
      dateCreated: new Date(bt.dateCreated),
      dateUpdated: new Date(bt.dateUpdated),
      sentTo: bt.sentTo,
    }

    const newBookingTicket: BookingTicketModel = {
      ...bt,
      ticket: bt.ticket,
      qty: 1,
      value: actualTicket.value,
      checks: bt.checks,
      status: bt.status,
      actualTickets: [actualTicket],
    }

    set({currentBookingTicket: newBookingTicket})

    return response.data
  },

  resetCurrentBooking: () => {
    set({currentBooking: {...initialBooking}})
  },
})

export const bookingStore = create(devtools(createStore))
export const useBookingStore = bookingStore
