import {create, StateCreator} from 'zustand'
import {devtools} from 'zustand/middleware'
import {getDirtyValues, omit, pick} from '../../../../_helpers/_helpers'
import {_getUsers, _patchAccount, _patchUser, _postUser} from './accountsUsersApi'
import {_deleteUser} from '../../auth/core/authApi'
import {DashboardModel} from '../../../pages/dashboard/core/dashboardStore'

//PERMISSION CONSTANTS
export const CAN_GET_USER = 'getUser'
export const CAN_CREATE_USER = 'createUser'
export const CAN_UPDATE_USER = 'updateUser'
export const CAN_DELETE_USER = 'deleteUser'
export const CAN_CREATE_ACCOUNT = 'createAccount'
export const CAN_GET_ACCOUNT = 'getAccount'
export const CAN_UPDATE_ACCOUNT = 'updateAccount'
export const CAN_DELETE_ACCOUNT = 'deleteAccount'
export const CAN_GET_ACCOUNTS_USERS = 'getAccountsUsers'
export const CAN_UPDATE_ACCOUNTS_USERS = 'updateAccountsUsers'
export const CAN_DELETE_ACCOUNTS_USERS = 'deleteAccountsUsers'
export const CAN_GET_USERS_BY_ACCOUNT = 'getUsersByAccount'
export const CAN_DEACTIVATE_ACCOUNT = 'deactiveAccount'

export type AccountModel = {
  id: string
  plan: string
  name: string
  image?: string
  handle: string
  bankName?: string
  bankAccountHolder?: string
  bankAccountNumber?: number
  bankBranch?: string
  fileId?: string
  fileBankLetter?: string
  fileProofOfAddress?: string
  status: string
  currency: string
  dateCreated?: string
  stats?: DashboardModel
  isVerified: boolean
}

export const accountInitialValues: AccountModel = {
  id: '',
  plan: 'independent',
  name: '',
  handle: '',
  bankName: '',
  bankAccountHolder: '',
  bankAccountNumber: 0,
  bankBranch: '',
  fileId: '',
  fileBankLetter: '',
  fileProofOfAddress: '',
  status: 'pending',
  currency: 'ZAR',
  isVerified: false,
}

export type UserModel = {
  id?: string
  firstName: string
  lastName: string
  email: string
  displayName: string
  password?: string
  isEmailVerified?: boolean
  role?: string
  status?: string
}

export const userInitialValues: UserModel = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  displayName: '',
  isEmailVerified: false,
  role: 'pending',
  status: 'active',
}

export type AccountsUsersModel = {
  id: string
  role: string
  rights: {}
  user: UserModel
  account: AccountModel
  status: string
}

export const accountsUsersInitialValues: AccountsUsersModel = {
  id: '',
  role: '',
  rights: {},
  user: {...userInitialValues},
  account: {...accountInitialValues},
  status: '',
}

type AccountsUsersStore = {
  // users
  getUsers: (accountId) => Promise<UserModel[]>
  patchUser: (newUser: UserModel) => Promise<void>
  postUser: (newUser: UserModel) => Promise<void>
  accountsUsers: AccountsUsersModel[]
  users: UserModel[]

  // accounts
  deleteUser: (userId: string) => Promise<void>
  patchAccount: (newAccount: AccountModel) => Promise<void>
  getAccount: () => AccountModel | undefined
  setAccountImage: (image: string) => void

  // user roles and rights
  currentUserCan: (right: string) => boolean
  rights: {}

  // selected accounts users store
  getCurrentUser: () => UserModel
  getCurrentAccount: () => AccountModel | undefined
  switchAdminToUser: (account: AccountModel) => void
  switchUserToAdmin: () => void
  adminAccount: AccountModel | undefined
  selectedAccountsUsers: AccountsUsersModel
  unsetSelectedAccountsUsers: () => void
  setSelectedAccountsUsers: (accountsUsersId: string) => void
  setupAccountsUsersStore: (rights: string[], accountsUsers: AccountsUsersModel[]) => void
}

const createStore: StateCreator<AccountsUsersStore> = (set, get) => ({
  rights: {},
  users: [],
  adminAccount: undefined,
  switchAdminToUser: (account: AccountModel) => {
    // get current account and set as admin
    const {getCurrentAccount, selectedAccountsUsers} = get()

    if (!getCurrentAccount()) return

    set({adminAccount: getCurrentAccount()})

    // now, set the selected account as the account
    set({selectedAccountsUsers: {...selectedAccountsUsers, account}})
  },
  switchUserToAdmin: () => {
    const {adminAccount, selectedAccountsUsers} = get()
    if (adminAccount) {
      set({selectedAccountsUsers: {...selectedAccountsUsers, account: adminAccount}})
    }
    set({adminAccount: undefined})
  },

  currentUserCan: (right: string) => {
    const {selectedAccountsUsers, rights} = get()
    if (selectedAccountsUsers) {
      const {role} = selectedAccountsUsers
      const roleRights = rights[role]
      const response = roleRights?.includes(right)

      return response
    }
    return false
  },

  getCurrentUser: () => {
    const {selectedAccountsUsers} = get()
    return selectedAccountsUsers?.user
  },

  getCurrentAccount: () => {
    const {selectedAccountsUsers} = get()
    return selectedAccountsUsers?.account
  },

  getUsers: async (accountId) => {
    // update axios header to include
    try {
      let aid = accountId
      if (!aid) {
        aid = get().getCurrentAccount()?.id
        if (!aid) return
      }

      const response = await _getUsers(aid)
      const users = response.data.map((u: AccountsUsersModel) => {
        const newUser: UserModel = {
          id: u.user.id,
          firstName: u.user.firstName,
          lastName: u.user.lastName,
          email: u.user.email,
          displayName: u.user.displayName,
          isEmailVerified: u.user.isEmailVerified,
          role: u.role,
          status: u.status,
        }

        return newUser
      })

      set({users})

      return users
    } catch (err) {
      throw new Error('There was a problem getting users')
    }
  },

  deleteUser: async (userId: string) => {
    try {
      await _deleteUser(userId)
    } catch (error) {
      throw new Error('There was a problem deleting the user')
    }
  },
  patchUser: async (userPayload: UserModel) => {
    // update axios header to include
    try {
      const {users} = get()
      const userId = userPayload.id
      if (userId) {
        //
        const dirty = pick(userPayload, ['firstName', 'lastName', 'email', 'displayName'])

        // check if there are any dirty values
        if (Object.keys(dirty).length > 0) {
          // patch the user
          await _patchUser(userId, dirty)

          // find the user that was updated in accountsUsers and update it
          const {accountsUsers} = get()
          const updatedAccountsUsers = accountsUsers.map((au: AccountsUsersModel) =>
            au.user.id === userId ? {...au, user: {...au.user, ...dirty}} : au
          )
          set({accountsUsers: updatedAccountsUsers})

          // check if selectedAccountsUsers is the same as the updated user and udpate if necessary
          const {selectedAccountsUsers} = get()
          if (selectedAccountsUsers?.user.id === userId) {
            set({
              selectedAccountsUsers: {
                ...selectedAccountsUsers,
                user: {...selectedAccountsUsers.user, ...dirty},
              },
            })
          }

          // update users
          const updatedUsers = users.map((u: UserModel) => (u.id === userId ? {...u, ...dirty} : u))
          set({users: updatedUsers})
        }
      }
    } catch (err) {
      throw new Error('There was a problem saving user')
    }
  },

  postUser: async (newUser: UserModel) => {
    // update axios header to include
    try {
      // remove id, isEmailVerified and status
      delete newUser.id
      delete newUser.isEmailVerified
      delete newUser.status

      const response = await _postUser(newUser)

      // add newUser to accountUsers and users
      const {accountsUsers} = get()
      const newAccountsUsers = [...accountsUsers, response.data.accountsUsers]
      set({accountsUsers: newAccountsUsers})

      const {getUsers, getCurrentAccount} = get()
      const account = getCurrentAccount() ?? ''
      getUsers(account) // this will update the users array
    } catch (err) {
      throw new Error('There was a problem saving user')
    }
  },
  accountsUsers: [],
  selectedAccountsUsers: {...accountsUsersInitialValues},
  setAccountImage: (imageUrl: string) => {
    const {selectedAccountsUsers} = get()
    if (selectedAccountsUsers) {
      set({
        selectedAccountsUsers: {
          ...selectedAccountsUsers,
          account: {...selectedAccountsUsers.account, image: imageUrl},
        },
      })
    }
  },

  patchAccount: async (newAccount: AccountModel) => {
    // update axios header to include
    try {
      const {selectedAccountsUsers, accountsUsers} = get()
      if (selectedAccountsUsers !== undefined) {
        const {account} = selectedAccountsUsers

        const dirty = getDirtyValues(newAccount, account)
        if (dirty) {
          const payload = omit(dirty, ['id'])
          await _patchAccount(account.id, payload)

          // update the account in accounts users array
          const updatedAccountsUsers = accountsUsers.map((au: AccountsUsersModel) =>
            au.account.id === newAccount.id ? {...au, account: newAccount} : au
          )
          set({accountsUsers: updatedAccountsUsers})

          // check if selectedAccountsUsers is the same as the updated account and udpate if necessary
          const {selectedAccountsUsers} = get()
          if (selectedAccountsUsers?.account.id === newAccount.id) {
            set({selectedAccountsUsers: {...selectedAccountsUsers, account: newAccount}})
          }
        }
      } else {
        throw new Error('Account is not selected')
      }
    } catch (err) {
      throw new Error('There was a problem saving account')
    }
  },

  /**
   * gets the account from the selectedAccountsUsers
   */
  getAccount: () => {
    const {selectedAccountsUsers} = get()
    let account = selectedAccountsUsers?.account

    // get the image url if it exists or set it to blank.jpg
    if (!account) return undefined

    return account
  },

  /**
   * This function should not be called directly.  Call selectAccount() in AuthStore instead
   */
  setSelectedAccountsUsers: (accountsUsersId: string) => {
    const {accountsUsers} = get()

    const selectedAccountsUsers = accountsUsers.find((a) => a.id === accountsUsersId)
    if (selectedAccountsUsers) {
      set({selectedAccountsUsers})
    }
  },

  /**
   * This function should not be called directly. setSelectedAccountsUsers Call deselectAccount() in AuthStore instead
   */
  unsetSelectedAccountsUsers: () => {
    set({selectedAccountsUsers: undefined})
  },

  setupAccountsUsersStore: (rights: any, accountsUsers: AccountsUsersModel[]) => {
    set({rights, accountsUsers})
  },
})

export const accountsUsersStore = create(devtools(createStore))
export const useAccountsUsersStore = accountsUsersStore
