import {useEffect, useState} from 'react'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import {useAccountsUsersStore} from '../../modules/settings/core/accountsUsersStore'
import {PageDataModel} from '../../../_components/SBPageTitle/PageDataStore'
import {Breadcrumbs} from '../../../_components/SBPageTitle/Breadcrumbs'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {KTIcon} from '../../../_metronic/helpers'
import clsx from 'clsx'
import {daysUntilDate, formatDateDMYHI, formatMoney} from '../../../_helpers/_helpers'
import {PayoutActionButton} from './Partials/PayoutActionButton'
import {RequestPayoutButton} from '../../modules/transactions/partials/RequestPayoutButton'
import {PayoutModel, usePayoutsStore} from '../../modules/transactions/core/payoutsStore'
import {useStatementStore} from '../../modules/transactions/core/statementsStore'
import toast, {Toaster} from 'react-hot-toast'

const AdminSettlementsPage = () => {
  const {pending, pagination, adminPayouts, postAdminPayouts, patchSettlement, getAdminPayouts} =
    usePayoutsStore()
  const {getBalance} = useStatementStore()
  const [crumbs, setCrumbs] = useState<PageDataModel[]>([])
  const [payoutsChecked, setPayoutsChecked] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isGettingPayouts, setIsGettingPayouts] = useState(false)
  const [payoutLoading, setPayoutLoading] = useState(false)
  const [currentPayout, setCurrentPayout] = useState<PayoutModel | null>(null)
  const {selectedAccountsUsers} = useAccountsUsersStore()

  useEffect(() => {
    if (!selectedAccountsUsers.account.id) return
    setIsLoading(true)
    Promise.all([
      getAdminPayouts(pagination),
      getBalance(selectedAccountsUsers.account.id),
    ]).finally(() => {
      setIsLoading(false)
      setPayoutsChecked(true)
    })

    // eslint-disable-next-line
  }, [selectedAccountsUsers.account.id])

  // Setup Breadcrumbs
  useEffect(() => {
    // check if event exists

    const crumbs: PageDataModel[] = [
      {
        object: 'Settlements',
        title: 'Settlements',
        link: '/admin/settlements',
      },
    ]
    setCrumbs(crumbs)
  }, [adminPayouts])

  const handlePayoutAdded = () => {
    setIsLoading(true)
    getAdminPayouts(pagination).finally(() => {
      setIsLoading(false)
    })
  }

  const handlePaginationChange = (page: number) => {
    setIsLoading(true)
    getAdminPayouts({...pagination, page}).finally(() => {
      setIsLoading(false)
    })
  }

  const handlePayoutAction = async (payout: PayoutModel, action: string) => {
    if (!payout.id) return false

    // SETUP STATUSES
    setPayoutLoading(true)
    setCurrentPayout(payout)

    // DO ACTION
    try {
      await patchSettlement(action, payout.id, {status: action})
      await getAdminPayouts({...pagination, page: 1})

      setPayoutLoading(false)
      setCurrentPayout(null)
    } catch (err) {
      setPayoutLoading(false)
      setCurrentPayout(null)
    }
  }

  const handlePostPayouts = async () => {
    try {
      setIsGettingPayouts(true)
      await postAdminPayouts()
      await getAdminPayouts({...pagination, page: 1})
      setIsGettingPayouts(false)
    } catch (err) {
      toast.error('There was a problem fetching payouts')
      setIsGettingPayouts(false)
    }
  }

  return (
    <>
      <Toaster />
      {/* HEADER */}
      <div className='row'>
        <div className='d-flex flex-column'>
          {/* BREADCRUMB */}
          <Breadcrumbs breadcrumbs={crumbs} className='mb-5' />

          <div className='d-flex justify-content-between mb-10'>
            <div className='d-flex flex-column'>
              <span className='fs-3 fw-bold'>
                Showing {adminPayouts.length} of {pagination.totalResults} Settlements
              </span>
              <span className='fs-5 text-muted'>{`showing page ${pagination.page} of ${pagination.totalPages}`}</span>
            </div>

            <div className='d-flex'>
              {/* get payouts */}
              <div className='d-flex flex-column'>
                <button
                  className='btn btn-light btn-active-warning btn-sm me-3'
                  onClick={handlePostPayouts}
                >
                  {isGettingPayouts ? (
                    <span
                      className='spinner-border spinner-border-sm me-2'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    <KTIcon className='fs-3 mb-2 ' iconType='outline' iconName='cheque' />
                  )}
                  Get Payouts
                </button>
              </div>

              {/* balance */}
              <div className='d-flex'>
                <div className='account-balance fw-bolder fs-1 text-warning text-end'>
                  {formatMoney(pending, selectedAccountsUsers.account.currency, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* PAYOUTS */}
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {payoutsChecked === true && adminPayouts.length === 0 ? (
            <div className='min-h-250px d-flex flex-grow-1 border-1 justify-content-center border-dashed rounded bg-light'>
              <div className='d-flex justify-content-center align-items-center flex-column'>
                <div className='d-flex'>
                  <KTIcon
                    className='svg-icon-3x svg-icon-primary mb-5 '
                    iconName='flaticon-safebox-1'
                  />
                  <span className='fs-2x fw-bolder text-dark'>No payouts have been made yet.</span>
                </div>
                <div className='fs-5 text-muted text-center'>
                  You can request a payout by clicking the button below.
                </div>
                <div className='d-flex flex-row p-5 '>
                  <RequestPayoutButton onPayoutAdded={handlePayoutAdded} />
                  {/* <button
                onClick={() => navigate('/bookings/create')}
                className='btn btn-primary btn-lg'
              >
                Create Booking
              </button> */}
                </div>
              </div>
            </div>
          ) : (
            <>
              {/* table  */}
              <table className='table table-striped table-hover table-row-bordered table-row-gray-300 gy-7'>
                <thead>
                  <tr className='fw-bolder fs-6 text-gray-800'>
                    <th className='ps-5'>Request Date</th>
                    <th className='ps-5'>Updated Date</th>
                    <th>Accounts & Request</th>
                    <th className='text-end'>Balances</th>
                    <th className='text-end pe-10'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {adminPayouts.map((payout) => (
                    <tr key={payout.id}>
                      <td className='ps-5'>
                        <div className='d-flex align-items-center'>
                          <span
                            className={clsx('badge badge-circle w-10px h-10px me-5', {
                              'badge-success': payout.status === 'complete',
                              'badge-warning': payout.status === 'pending',
                              'badge-info': payout.status === 'holding',
                              'badge-danger': payout.status === 'cancelled',
                            })}
                          />
                          <div className='d-flex flex-column'>
                            <span className='fw-bolder'>
                              {daysUntilDate(payout.dateCreated, true)} ago
                            </span>
                            <span className='fw-normal'>
                              {formatDateDMYHI(payout?.dateCreated?.toString())}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td className='ps-5'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex flex-column'>
                            <span className='fw-bolder'>
                              {daysUntilDate(payout.dateUpdated, true)} ago
                            </span>
                            <span className='fw-normal'>
                              {formatDateDMYHI(payout?.dateUpdated?.toString())}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex flex-column'>
                          <span className='fw-bold'>
                            Account: {payout.account?.name || 'Unknown'}
                            <span
                              className={clsx('ms-2 badge', {
                                'badge-success': payout.status === 'complete',
                                'badge-warning': payout.status === 'pending',
                                'badge-info': payout.status === 'holding',
                                'badge-danger': payout.status === 'cancelled',
                              })}
                            >
                              {payout.status}
                            </span>
                          </span>
                          <span>{payout.notes}</span>
                        </div>
                      </td>

                      <td className='text-end'>
                        <div className='d-flex flex-column'>
                          <span className='fw-bold'>
                            Payout Request:{' '}
                            {formatMoney(payout.amount, selectedAccountsUsers.account.currency, 0)}
                          </span>
                          <span className='text-muted'>
                            Account Balance:{' '}
                            {formatMoney(
                              payout?.statementBalance,
                              selectedAccountsUsers.account.currency,
                              0
                            )}
                          </span>
                        </div>
                      </td>

                      <td className='text-end pe-5'>
                        <PayoutActionButton
                          key={`payout-action-${payout.id}`} // Add a unique key prop here
                          onPayoutAction={handlePayoutAction}
                          isLoading={payoutLoading && currentPayout?.id === payout.id}
                          payout={payout}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {adminPayouts.length < pagination.totalResults && (
                <div className='my-10'>
                  <PaginationControl
                    page={pagination.page}
                    total={pagination.totalResults}
                    limit={pagination.limit}
                    changePage={handlePaginationChange}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default AdminSettlementsPage
