/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {EngageWidget10} from '../../../_metronic/partials/widgets'

import {useDashboardsStore} from './core/dashboardStore'
import {EventsBookingsCard} from './partials/EventsBookingsCard'
import {SalesCard} from './partials/SalesChart'

import {WalletCard} from './partials/WalletCard'
import {QuickLinks} from './partials/QuickLinks'
import {useAccountsUsersStore} from '../../modules/settings/core/accountsUsersStore'
import {SalesOrBookingsCard} from './partials/SalesOrBookingsCard'
import {AdminWalletCard} from './partials/AdminWalletCard'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'

const DashboardPage: FC = () => {
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const {getDashboardData} = useDashboardsStore()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!selectedAccountsUsers.account.id) return
    // get dashboard
    getDashboardData(selectedAccountsUsers.account.id).finally(() => {
      setIsLoading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountsUsers.account.id])

  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {/* begin::Row */}
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            {/* begin::Col */}
            <div className='col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10'>
              <EventsBookingsCard
                className='h-md-50 mb-5 mb-xl-10'
                description='Active Events'
                color='#000000'
                img={toAbsoluteUrl('/media/patterns/vector-2.png')}
              />
              <WalletCard className='h-md-50 mb-5 mb-xl-10' />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10'>
              <SalesCard className='h-lg-50 mb-5 mb-xl-10' />
              <QuickLinks className='h-lg-50' />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-12 col-lg-6 col-xxl-6'>
              <EngageWidget10 className='h-md-100' />
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </>
      )}
    </>
  )
}

const AdminDashboardPage: FC = () => {
  const {getAdminDashboardData} = useDashboardsStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    // get dashboard
    getAdminDashboardData().finally(() => {
      setIsLoading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {' '}
          {/* begin::Row */}
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            {/* begin::Col */}
            <div className='col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10'>
              <EventsBookingsCard
                className='h-md-50 mb-5 mb-xl-10'
                description='Active Events'
                color='#000000'
                img={toAbsoluteUrl('/media/patterns/vector-2.png')}
                linkTo='/admin/accounts'
              />
              <AdminWalletCard className='h-md-50 mb-5 mb-xl-10' />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10'>
              <SalesOrBookingsCard
                chartColor='primary'
                chartType='bookings'
                className='h-lg-50 mb-5 mb-xl-10'
              />
              <SalesOrBookingsCard
                chartColor='success'
                chartType='sales'
                className='h-lg-50 mb-5 mb-xl-10'
              />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-12 col-lg-6 col-xxl-6'>
              <EngageWidget10 className='h-md-100' />
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </>
      )}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {getCurrentAccount} = useAccountsUsersStore()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      {getCurrentAccount()?.plan !== 'skybookings' ? <DashboardPage /> : <AdminDashboardPage />}
    </>
  )
}

export {DashboardWrapper}
