/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {BookingTicketModel, ActualTicketModel, useBookingStore} from '../core/bookingsStore'
import {
  formatDateDMY,
  formatNumber,
  generateTicketQRCode,
  getCurrencySymbol,
} from '../../../../_helpers/_helpers'
import {EventModel} from '../../events/core/eventsStore'
import {KTIcon} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {useClientStore} from '../../clients/core/clientStore'

type Props = {
  bookingTicket: BookingTicketModel
  event: Partial<EventModel>
  enableSharing?: boolean
  className?: string
  onShare?: (bookingTicket: BookingTicketModel, ticket: ActualTicketModel) => void
}

const BookingTicketItem: FC<Props> = ({
  bookingTicket,
  event,
  enableSharing,
  className,
  onShare,
}) => {
  const {currentBooking} = useBookingStore()
  const {isClientLoggedIn} = useClientStore()

  return (
    <>
      {bookingTicket.actualTickets &&
        bookingTicket.actualTickets.map((actualTicket: ActualTicketModel) => {
          let statusColor = ''
          switch (actualTicket.status) {
            case 'pending':
              statusColor = 'warning'
              break
            case 'locked':
              statusColor = 'warning'
              break
            case 'cancelled':
              statusColor = 'danger'
              break
            case 'active':
              statusColor = 'success'
              break
            case 'checked':
              statusColor = 'info'
              break
            default:
              statusColor = 'secondary'
          }

          return (
            <>
              <div key={`at-${actualTicket.id}`} className={`col-md-6 col-xl-4 ${className}`}>
                <div
                  className={`card border border-secondary border-1 p-5 h-100 status-border ${statusColor}`}
                  style={{borderTopColor: statusColor}}
                >
                  <div className='d-flex flex-column flex-sm-row'>
                    {/* QR Code */}
                    <div className='w-40 d-flex justify-content-center align-items-center'>
                      <div
                        className={clsx(
                          'd-flex justify-content-center mt-10 mt-md-0 w-md-100 w-250px'
                        )}
                      >
                        <img
                          src={generateTicketQRCode(actualTicket.id)}
                          alt='card2'
                          className='p-2 w-100'
                        />
                      </div>
                    </div>
                    <div className='separator separator-dashed border-secondary my-10'></div>
                    {/* Event Details */}
                    <div className='d-flex flex-grow-1 flex-column p-0 ms-3 w-100'>
                      <div className='d-flex flex-stack align-items-start'>
                        <div className='fs-5 text-gray-800 me-1 fw-bolder'>
                          {bookingTicket.ticket.name}
                        </div>

                        {bookingTicket.status === 'active' && enableSharing && !isClientLoggedIn && (
                          <button
                            type='button'
                            onClick={() => onShare && onShare(bookingTicket, actualTicket)}
                            className={clsx('btn btn-sm me-2 btn-primary d-flex flex-grow', {
                              'btn-info': actualTicket.sentTo,
                            })}
                          >
                            <KTIcon iconName='send' iconType='solid' className='fs-4 me-1' />
                            {actualTicket.sentTo ? 'Shared' : 'Share'}
                          </button>
                        )}
                      </div>
                      <div className='fs-6 mb-2 text-gray-600'>{event.name}</div>
                      <div className='d-flex'>
                        <div className='fw-bold text-gray-600 me-3 mb-2 p-1'>
                          <KTIcon iconName='calendar' iconType='outline' className='fs-4 me-1' />
                          {formatDateDMY(event.startDate || '')}
                        </div>
                        {currentBooking.event?.sellTickets && actualTicket.value > 0 && (
                          <div className='fw-bold text-gray-600 p-1'>
                            {getCurrencySymbol(event.currency || 'ZAR') +
                              ' ' +
                              formatNumber(actualTicket.value, 0)}
                          </div>
                        )}
                      </div>
                      {actualTicket.sentTo && (
                        <div className='d-flex fw-bold p-1 mb-2'>
                          <KTIcon iconName='send' iconType='outline' className='fs-4 me-1' />
                          Ticket shared with: {actualTicket?.sentTo?.name}
                        </div>
                      )}

                      <div className='d-flex flex-rows flex-wrap'>
                        <KTIcon
                          iconName='check-circle'
                          iconType='outline'
                          className='fs-4 ms-1 me-1'
                        />
                        {actualTicket.checks &&
                          actualTicket.checks
                            .filter((check) => check.qty > 0)
                            .map((check, index) => {
                              return (
                                <span
                                  key={`${check.name}-${index}`}
                                  className={clsx('badge badge-light-primary me-3 mb-3', {
                                    'badge-light-danger': check.status === 'checked',
                                  })}
                                >
                                  {`${check.name} (${check.checks}/${check.qty})`}
                                </span>
                              )
                            })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        })}
    </>
  )
}

export {BookingTicketItem}
