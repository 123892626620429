import {FC, useEffect, useState} from 'react'
import {useQueryRequest} from '../../apps/user-management/users-list/core/QueryRequestProvider'
import {useQueryResponse} from '../../apps/user-management/users-list/core/QueryResponseProvider'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {initialQueryState, KTIcon} from '../../../../_metronic/helpers'
import {useBookingStore} from '../core/bookingsStore'
import clsx from 'clsx'
import {isMobileDevice} from '../../../../_metronic/assets/ts/_utils'

type Props = {
  onFilter: (filter: any) => void // filter: {role, last_login}
  isLoading?: boolean
  className?: string
}

const BookingsListFilter: FC<Props> = ({onFilter, isLoading, className}) => {
  const [isFiltered, setIsFiltered] = useState<boolean>(false)
  const [status, setStatus] = useState<string | undefined>()
  const [limit, setLimit] = useState<number | undefined>(25)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setIsFiltered(false)
    setStatus(undefined)
    setLimit(25)
    onFilter({status: undefined, limit: 25})
  }

  const filterData = () => {
    setIsFiltered(true)

    // do the same for limit
    onFilter({limit, status})
  }

  const changeStatus = (status: string) => {
    if (status) setStatus(status)
    if (!status) setStatus(undefined)
  }

  const changeLimit = (limit: number) => {
    setLimit(limit)
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading && isLoading}
        type='button'
        className={`btn btn-sm btn-active-secondary ${className}`}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon
          iconName='filter'
          iconType={isFiltered ? 'solid' : 'outline'}
          className={clsx('fs-2 text-darker', {
            'text-primary': isFiltered,
          })}
        />
        {isMobileDevice() ? '' : 'Filter'}
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Status:</label>
            {/* const bookingStatuses = ['pending', 'rejected', 'cancelled', 'complete']; */}
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => changeStatus(e.target.value)}
              value={status}
            >
              <option value=''></option>
              <option value='complete'>Complete</option>
              <option value='pending'>Pending</option>
              <option value='rejected'>Rejected</option>
              <option value='cancelled'>Cancelled</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* start:  Page Size Filtration */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Page Size:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => {
                changeLimit(parseInt(e.target.value))
              }}
              value={limit}
            >
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </div>

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {BookingsListFilter}
