import {useBookingStore} from '../core/bookingsStore'
import {Link} from 'react-router-dom'

import {formatNumber, getCurrencySymbol} from '../../../../_helpers/_helpers'
import {useEventsStore} from '../../events/core/eventsStore'
import clsx from 'clsx'
import {bookingStatusLabel} from '../../../../_helpers/_templateHelpers'
import {isMobileDevice} from '../../../../_metronic/assets/ts/_utils'

const BookingsTable = () => {
  const {bookings} = useBookingStore()
  const {currentEvent} = useEventsStore()

  return (
    <>
      {/* begin::Table */}
      <div className='table-responsive'>
        <div className='text-gray-600 fw-bold'>
          {/* end::Table head */}
          {/* begin::Table body */}
          <div className='d-flex flex-column'>
            {bookings.length > 0 &&
              bookings.map((booking) => {
                const tiv = (booking.stats && booking.stats.ticketsValue) || 0
                const trv = (booking.stats && booking.stats.transactionsValue) || 0

                const isPaid = tiv > trv ? true : false

                return (
                  <Link
                    key={booking.id}
                    to={`/bookings/${booking.id}/`}
                    className='bg-hover-light px-5 border-1 border-bottom  d-flex py-5'
                  >
                    {/* START:: SOMETHING */}
                    <div className='bg-hover d-flex text-start flex-column flex-grow-1'>
                      <div className='mb-2 d-flex'>
                        <span className='text-dark fw-bold text-hover-primary mb-1 fs-4 me-3'>
                          {booking.patron.name}
                        </span>
                        <span
                          className={clsx(
                            'badge badge-light-success',
                            {'badge-light-success': booking.status === 'confirmed'},
                            {'badge-light-warning': booking.status === 'pending'},
                            {'badge-light-danger': booking.status === 'cancelled'}
                          )}
                        >
                          {booking.status}
                        </span>
                      </div>
                      <span
                        className='text-start fw-normal text-muted'
                        dangerouslySetInnerHTML={{__html: bookingStatusLabel(booking)}}
                      />
                    </div>
                    {/* END:: STATUS */}

                    {/* START:: VALUE */}

                    {currentEvent.sellTickets && (
                      <>
                        <div className='text-end '>
                          <span className='fs-6 text-dark fw-semifolder mb-2'>
                            {getCurrencySymbol(currentEvent.currency) +
                              ' ' +
                              formatNumber(booking.stats.ticketsValue)}
                          </span>
                          {/* paid */}
                          {isPaid ? (
                            <span className='text-mute fw-normal fs-7 d-block'>
                              {' Paid: '}
                              {getCurrencySymbol(currentEvent.currency) +
                                ' ' +
                                formatNumber(booking.stats.transactionsValue)}
                            </span>
                          ) : (
                            <span className='text-mute fw-normal fs-7 d-block'>Paid in full</span>
                          )}
                        </div>
                      </>
                    )}

                    {/* END:: VALUE */}
                  </Link>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export {BookingsTable}
