import React, {useEffect, useState} from 'react'
import {IDetectedBarcode, Scanner} from '@yudiel/react-qr-scanner'
import {useGuestsStore} from '../guests/core/guestsStore'
import {useScannersStore} from './core/scannerStore'
import {useNavigate, useParams} from 'react-router-dom'
import {useEventsStore} from '../events/core/eventsStore'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import clsx from 'clsx'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'

const ScanPage = () => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const [constraints, setConstraints] = useState<MediaTrackConstraints>({})
  const [showCamera, setShowCamera] = useState(true)
  const [selectedDevice, setSelectedDevice] = useState('')
  const [ticketValidity, setTicketValidity] = useState<boolean | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>()
  const [isLoading, setIsLoading] = useState(false)
  const [isScanning, setIsScanning] = useState(false)
  const {postCheckIn} = useGuestsStore()
  const {currentScanner, getScanner} = useScannersStore()
  const {currentEvent, getEvent} = useEventsStore()
  const {scannerId} = useParams()
  const {eventId} = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!eventId) return
    if (!scannerId) return

    if (!currentScanner.id) {
      setIsLoading(true)
      getEvent(eventId)
      getScanner(eventId, scannerId).finally(() => {
        setIsLoading(false)
      })
    }
  }, [])

  useEffect(() => {
    if (isMobileDevice()) {
      setConstraints({facingMode: 'environment'})
    } else {
      navigator.mediaDevices
        .enumerateDevices()
        .then((systemDevices) => {
          setDevices(systemDevices)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [])

  const handleScan = async (barcodes: IDetectedBarcode[]) => {
    if (!eventId) return
    if (!currentScanner.checkpoint.id) return

    const bc = barcodes[0]
    const {rawValue, format} = bc
    if (format && rawValue) {
      /**
       * extract bookingTicketId from rawValue, which is a URL formatted as follows:
       * '{baseUrl}/check/{bookingTicketId}'
       */
      const ticketId = rawValue.split('/').pop()
      if (!ticketId) return

      try {
        setIsScanning(true)
        setErrorMessage('')

        await postCheckIn(ticketId, currentScanner.checkpoint.id)
        setIsScanning(false)
        setTicketValidity(true)
        setShowCamera(false)
      } catch (error: any) {
        // print error object as text
        setErrorMessage(
          error?.response?.data?.message || error
            ? error?.response?.data?.message || error
            : 'An error occurred'
        )
        setShowCamera(false)
        setTicketValidity(false)
        setIsScanning(false)
      }
    }
  }

  const handleSwitchDevice = async (deviceId) => {
    try {
      setConstraints({deviceId})
      setSelectedDevice(deviceId)

      await new Promise((resolve) => setTimeout(resolve, 1000))
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className='d-flex flex-column flex-grow-1'>
      {/* HEADER */}
      <div className='header p-3'>
        <div className='container'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center'>
              <div
                onClick={() => {
                  navigate(`/events/${eventId}`)
                }}
                style={{
                  backgroundImage: `url(${currentEvent.artwork})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundColor: 'black',
                }}
                className='w-40px h-40px symbol symbol-40px border-1 border-grey(400)'
              />
              <div className='d-flex flex-column mt-1'>
                <h1 className='fs-7 fs-lg-3 fw-bolder pb-0 mb-0 title ms-3'>{currentEvent.name}</h1>
                <span className='fs-6 fs-lg-2 fw-normal text-muted pb-0 ms-3'>
                  {currentScanner.checkpoint.name}
                </span>
              </div>
            </div>
            {showCamera && !isMobileDevice() && (
              <>
                <div className='d-flex'>
                  <button
                    className='btn btn-icon'
                    data-kt-menu-trigger={'click'}
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement={'bottom-end'}
                  >
                    <KTIcon iconName='scan-barcode' iconType='outline' className='fs-2x' />
                  </button>
                  <div
                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-3 fs-base w-175px'
                    data-kt-menu='true'
                  >
                    {/* begin::Menu item */}
                    <div className='menu-item bg-hover-light px-3 my-0'>
                      {devices.map((device, index) => (
                        <div
                          key={`device-${device.deviceId}-${index}`}
                          className={clsx('menu-link px-3 py-3')}
                          onClick={() => {
                            handleSwitchDevice(device.deviceId)
                          }}
                        >
                          <span className='menu-title'>{device.label}</span>
                        </div>
                      ))}
                    </div>
                    {/* end::Menu item */}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* END HEADER */}

      {/* START CAMERA */}
      <div className='d-flex flex-column vh-100 flex-grow-1'>
        {showCamera && (
          <>
            {isScanning ? (
              <PageLoadingSpinner />
            ) : (
              <div className='d-flex flex-column'>
                <div className='p-5 mb-10'>
                  <Scanner scanDelay={500} constraints={constraints} onScan={handleScan} />
                </div>
              </div>
            )}
          </>
        )}

        {ticketValidity !== null && (
          <div
            className={clsx(`p-10 d-flex flex-grow-1 justify-content-center flex-column`, {
              'bg-success': ticketValidity,
              'bg-danger': !ticketValidity,
            })}
          >
            {ticketValidity ? (
              <div className='bg-success w-100 rounded d-flex flex-column justify-content-center align-items-center bg-success'>
                <span className='fs-2 text-white fw-bolder'>Ticket Valid</span>
                <span className='fs-4 text-white ms-5'>{currentScanner.checkpoint.name}</span>
              </div>
            ) : (
              <div className='div w-100 rounded d-flex flex-column justify-content-center align-items-center bg-danger'>
                <span className='fs-2 text-white fw-bolder'>Ticket Not Valid</span>
                <span className='fs-4 text-white ms-5'>{errorMessage}</span>
              </div>
            )}

            <button
              onClick={() => {
                setShowCamera(true)
                setTicketValidity(null)
              }}
              className='btn btn-light fw-bolder btn-xl mt-5'
            >
              Scan Again
            </button>
          </div>
        )}

        {showCamera && ticketValidity === null && <></>}
      </div>
      {/* END:: CAMERA */}
    </div>
  )
}

export default ScanPage
