import React, {useEffect, useState} from 'react'
import {ErrorMessage, Field, Form, Formik, FormikValues} from 'formik'
import 'react-quill/dist/quill.snow.css' // import styles
import {useEventsStore} from '../core/eventsStore'
import 'react-autocomplete-input/dist/bundle.css'
import {CheckpointEditor} from './CheckpointEditor'
import TicketEditor from './TicketEditor'

import * as Yup from 'yup'
import NumberInput from '../../../../_components/NumberInput'
import DateInput from '../../../../_components/DateInput'
import CurrencyInput from '../../../../_components/CurrencyInput'
import SubmitButton from '../../../../_components/SubmitButton'
import clsx from 'clsx'

const validationSchema = Yup.object({
  allowInstallments: Yup.boolean().required().label('Allow Installments'),
  maxInstallments: Yup.string().required().label('Max Installments'),
  installmentInterval: Yup.string().required().label('Installment Interval'),
  minBookingAmount: Yup.string().required().label('Minimum Booking Amount'),
  installmentCutOffDate: Yup.string().label('Installment Cut Off Date'),

  // refudns
  allowRefunds: Yup.boolean().required().label('Allow Refunds'),
  refundBeforeDays: Yup.number().required().label('Refund Before Days'),
  refundFeePercentage: Yup.number().required().label('Refund Fee Percentage'),
})

// write validation schema with yup
type Props = {
  onValidation?: (values: FormikValues) => void
  onSave?: (values: FormikValues) => void
  step?: string
}

const TicketsEditor = (props: Props) => {
  const {step} = props
  const {checkpoints, getTickets, currentEvent, tickets, saveEvent} = useEventsStore()
  const [saveMessage, setSaveMessage] = useState('')
  const [saveIsError, setSaveIsError] = useState<boolean | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (currentEvent.id) getTickets(currentEvent.id, {limit: 100, page: 1})
  }, [currentEvent.id])

  const handleSubmit = (values, actions: FormikValues) => {
    // Add your logic here
    setLoading(true)

    saveEvent(values)
      .then((response) => {
        setSaveIsError(false)
        setSaveMessage('Account successfully updated')

        // emit
        if (props.onSave) props.onSave(values)

        setTimeout(() => {
          setSaveMessage('')
          setSaveIsError(undefined)
        }, 3000)
      })

      .catch((err) => {
        setSaveIsError(true)
        setSaveMessage(err.message)
      })

      .finally(() => setLoading(false))
  }

  const getRefundErrors = (form) => {
    if (form.values.allowRefunds) {
      if (form.errors.refundFeePercentage) return form.errors.refundFeePercentage
      if (form.errors.refundBeforeDays) return form.errors.refundBeforeDays
    }
    return ''
  }

  const getInstallmentErrors = (form) => {
    if (form.values.allowInstallments) {
      if (form.errors.maxInstallments) return form.errors.maxInstallments
      if (form.errors.installmentInterval) return form.errors.installmentInterval
      if (form.errors.minBookingAmount) return form.errors.minBookingAmount
      if (form.errors.installmentCutOffDate) return form.errors.installmentCutOffDate
    }
    return ''
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_event_tickets'
        aria-expanded='true'
        aria-controls='kt_event_tickets'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{step && step + '. '}Tickets &amp; Payment Policies</h3>
        </div>
      </div>

      <div id='kt_event_tickets' className='show'>
        <div className='card-body border-top p-9'>
          <div className='accordion accordion-flush' id='ticketsAccordion'>
            {/* STEP 1:: Checkpoints */}
            <div className='accordion-item mb-3'>
              <h2 className='accordion-header' id='flush-checkpoints'>
                <button
                  className='accordion-button collapsed bg-light fs-4 text-dark'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#flush-checkpointsCollapse'
                  aria-expanded='false'
                  aria-controls='flush-checkpointsCollapse'
                >
                  <span className='fw-bold'>#1 Setup Check Points</span>
                  <span className='text-muted ms-5'>
                    Setup where you will check tickets. e.g. gate, parking, Bar, etc.
                  </span>
                </button>
              </h2>
              <div
                id='flush-checkpointsCollapse'
                className='accordion-collapse collapse'
                aria-labelledby='flush-checkpoints'
                data-bs-parent='#ticketsAccordion'
              >
                <div className='accordion-body'>
                  <div className='fv-row mb-10'>
                    <CheckpointEditor />
                  </div>
                </div>
              </div>
            </div>
            {/* end:: checkpoints */}

            {/* STEP 2 && 3:: Tickets */}
            {checkpoints && checkpoints.length > 0 && (
              <>
                <div className='accordion-item mb-3'>
                  <h2 className='accordion-header' id='flush-tickets'>
                    <button
                      className='accordion-button collapsed bg-light fs-4 text-dark'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseTickets'
                      aria-expanded='false'
                      aria-controls='flush-collapseTickets'
                    >
                      <span className='fw-bold'>#2 Setup Tickets</span>
                      <span className='text-muted ms-5'>e.g. VIP, Regular, Early Bird, etc.</span>
                    </button>
                  </h2>
                  <div
                    id='flush-collapseTickets'
                    className='accordion-collapse collapse'
                    aria-labelledby='flush-tickets'
                    data-bs-parent='#ticketsAccordion'
                  >
                    <div className='accordion-body'>
                      <div className='fv-row mb-10'>
                        <div className='d-flex flex-column w-100 mb-5'>
                          <TicketEditor />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* STEP 3:: PAYMENT OPTIONS & TERMS */}
                {currentEvent.sellTickets && tickets && tickets.length > 0 && (
                  <Formik
                    initialValues={currentEvent}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                  >
                    {(form) => (
                      <Form>
                        <div className='accordion-item mb-3'>
                          <h2 className='accordion-header' id='flush-payment'>
                            <button
                              className='accordion-button collapsed bg-light fs-4 text-dark'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#flush-collapsePayment'
                              aria-expanded='false'
                              aria-controls='flush-collapsePayment'
                            >
                              <span className='fw-bold'>#3 Payment Policies &amp; Refunds</span>
                              <span className='text-muted ms-5'>
                                Setup payment options and terms for the tickets.
                              </span>
                            </button>
                          </h2>
                          <div
                            id='flush-collapsePayment'
                            className='accordion-collapse collapse'
                            aria-labelledby='flush-payment'
                            data-bs-parent='#ticketsAccordion'
                          >
                            <div className='accordion-body'>
                              {/* Part Payments */}
                              <div className='fv-row mt-10 mb-10'>
                                {/* Toggle ALLOW PART PAYMENTS*/}
                                <div className='row'>
                                  <div className='col'>
                                    <label className='form-check form-check-custom text-dark form-check-solid align-items-start mb-5'>
                                      <Field
                                        disabled
                                        className='form-check-input me-3'
                                        type='checkbox'
                                        name='allowInstallments'
                                      />

                                      <span className='form-check-label d-flex flex-column align-items-start'>
                                        <span className='fw-bolder fs-5 text-dark mb-0'>
                                          Allow part payments (i.e. installments)
                                        </span>
                                        <span className='fw-normal fs-5 text-dark mb-0'>
                                          (coming soon)
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/* Part Payments */}
                              {form.values.allowInstallments === true && (
                                <>
                                  <div className='fv-row mb-10'>
                                    <div className='row'>
                                      <div className='col-lg-6'>
                                        <label className='form-label required'>
                                          Max Installments
                                        </label>
                                        <NumberInput name='maxInstallments' max={60} min={2} />
                                        <span className='d-flex ms-2 my-2 text-muted'>
                                          Maximum number of installments allowed
                                        </span>
                                        <div className='text-danger mt-2'>
                                          <ErrorMessage name='maxInstallments' />
                                        </div>
                                      </div>
                                      <div className='col-lg-6'>
                                        <label className='form-label required'>
                                          Installment Interval
                                        </label>
                                        <Field
                                          name='installmentInterval'
                                          as='select'
                                          className='form-control form-control-lg form-control-solid'
                                        >
                                          <option value='7'>Weekly</option>
                                          <option value='30'>Monthly</option>
                                        </Field>
                                        <span className='d-flex ms-2 my-2 text-muted'>
                                          Charge patron's card every{' '}
                                          {form.values.installmentInterval} days
                                        </span>
                                        <div className='text-danger mt-2'>
                                          <ErrorMessage name='installmentInterval' />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='fv-row mb-10'>
                                    <div className='row'>
                                      <div className='col-lg-6'>
                                        <label className='form-label required'>
                                          Minimum Booking Amount
                                        </label>
                                        <CurrencyInput
                                          currency={currentEvent.currency}
                                          name='minBookingAmount'
                                        />
                                        <span className='d-flex my-2'>
                                          Only offer installments for bookings above this amount.
                                        </span>
                                        <div className='text-danger mt-2'>
                                          <ErrorMessage name='minBookingAmount' />
                                        </div>
                                      </div>

                                      <div className='col-lg-6'>
                                        <label className='form-label'>
                                          Installment Cut Off Date
                                        </label>
                                        <DateInput name='installmentCutOffDate' data-enable-time />
                                        <span className='d-flex my-2 text-muted'>
                                          Date when all installments must be paid in full.
                                        </span>
                                        <div className='text-danger mt-2'>
                                          <ErrorMessage name='installmentInterval' />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className='separator separator-dashed mb-10'></div>
                              {/* REFUNDS */}
                              <div className='fv-row mt-10 mb-10'>
                                {/* Toggle ALLOW REFUNDS*/}
                                <div className='row'>
                                  <div className='col'>
                                    <label className='form-check form-check-custom text-dark form-check-solid align-items-start mb-5'>
                                      <Field
                                        className='form-check-input me-3'
                                        disabled
                                        type='checkbox'
                                        name='allowRefunds'
                                      />

                                      <span className='form-check-label d-flex flex-column align-items-start'>
                                        <span className='fw-bolder fs-5 text-dark mb-0'>
                                          Allow Refunds?
                                        </span>
                                        <span className='fw-normal fs-5 text-dark mb-0'>
                                          (coming soon)
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/* Refunds form */}
                              {form.values.allowRefunds === true && (
                                <>
                                  <div className='fv-row mb-10'>
                                    <div className='row'>
                                      <div className='col-lg-6'>
                                        <label className='form-label required'>
                                          Allow refunds max {form.values.refundBeforeDays} days
                                          before event
                                        </label>
                                        <NumberInput name='refundBeforeDays' min={1} max={10} />
                                        <div className='text-danger mt-2'>
                                          <ErrorMessage name='refundBeforeDays' />
                                        </div>
                                      </div>
                                      <div className='col-lg-6'>
                                        <label className='form-label required'>
                                          Refund Fee Percentage
                                        </label>
                                        <NumberInput name='refundFeePercentage' />
                                        <span className='d-flex ms-2 my-2 text-muted'>
                                          Percentage of the ticket price to charge as a refund fee.
                                        </span>
                                        <div className='text-danger mt-2'>
                                          <ErrorMessage name='refundFeePercentage' />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className='separator separator-dashed mb-10'></div>
                              <div className='d-flex justify-content-end'>
                                <div
                                  className={clsx('mt-2 me-3', {
                                    'text-error': saveIsError || !form.isValid,
                                    'text-success': !saveIsError,
                                  })}
                                >
                                  {saveMessage ||
                                    getInstallmentErrors(form) ||
                                    getRefundErrors(form)}
                                </div>
                                <SubmitButton
                                  isValid={form.isValid}
                                  isSubmitting={loading}
                                  className='btn-info'
                                  label='Save Payment Options'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </>
            )}
            {/* end:: TERMS */}
          </div>
        </div>
      </div>
    </div>
  )
}

export {TicketsEditor}
