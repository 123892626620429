import {FC, useEffect, useState} from 'react'
import {PageDataModel} from '../../../_components/SBPageTitle/PageDataStore'
import {useEventsStore} from '../events/core/eventsStore'
import {useNavigate, useParams} from 'react-router-dom'
import {Breadcrumbs} from '../../../_components/SBPageTitle/Breadcrumbs'
import {GuestModel, useGuestsStore} from './core/guestsStore'
import {Toast} from 'react-bootstrap'
import toast from 'react-hot-toast'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {KTIcon} from '../../../_metronic/helpers'
import {daysUntilDate, formatMoney, trimCharacters} from '../../../_helpers/_helpers'
import clsx from 'clsx'
import GuestCheckInButton from './GuestLIstPartials/GuestCheckInButton'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {GuestListFilter} from './GuestLIstPartials/GuestListFilter'

const GuestListPage: FC = () => {
  const [crumbs, setCrumbs] = useState<PageDataModel[]>([])
  const {currentEvent, getEvent} = useEventsStore()
  const {eventId} = useParams<{eventId: string}>()
  const {alphabet} = useParams<{alphabet: string}>()
  const [eventLoading, setEventLoading] = useState<boolean>(true)
  const {alphabets, guests, resetGuests, getAlphabets, getByAlphabetLetter} = useGuestsStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedAlphabet, setSelectedAlphabet] = useState<string>('')
  const [checkpointFilter, setCheckpointFilter] = useState<string | undefined>()
  const navigate = useNavigate()

  // SETUP PAGE
  useEffect(() => {
    if (!eventId) return

    if (alphabet && !currentEvent.id) {
      // 1. get current event
      setEventLoading(true)
      setIsLoading(true)
      getEvent(eventId)
        .then(async () => {
          // 2. get guests by alphabet
          await getAlphabetGuests(alphabet)
        })
        .finally(() => {
          setEventLoading(false)
          setIsLoading(false)
        })
    } else if (!alphabet) {
      setIsLoading(true)
      resetGuests()
      getAlphabets(eventId).finally(() => {
        setIsLoading(false)
      })

      if (!currentEvent.id) {
        setEventLoading(true)
        getEvent(eventId).finally(() => {
          setEventLoading(false)
        })
      }
    }
  }, [alphabet])

  // Setup Breadcrumbs
  useEffect(() => {
    // check if event exists

    const crumbs: PageDataModel[] = [
      {
        object: 'Events',
        title: 'All',
        link: '/events',
      },
      {
        object: `${trimCharacters(currentEvent.name, 12)}`,
        title: `${trimCharacters(currentEvent.name, 12)}`,
        link: `/events/${eventId}`,
      },
      {
        object: 'Guests',
        title: `Guests`,
        link: `/events/${eventId}/guests`,
      },
    ]

    if (guests.length > 0) {
      crumbs.push({
        object: selectedAlphabet,
        title: selectedAlphabet,
        link: `/events/${eventId}/guests`,
      })
    }

    setCrumbs(crumbs)
  }, [eventLoading, guests])

  const handleAlphabetClick = async (alphabet: string) => {
    getAlphabetGuests(alphabet)
  }

  const handleBackClick = () => {
    resetGuests()
    navigate(`/events/${eventId}/guests`)
  }

  const getAlphabetGuests = async (alphabet: string) => {
    if (!eventId) return
    try {
      setSelectedAlphabet(alphabet)
      setIsLoading(true)
      await getByAlphabetLetter(eventId, alphabet)
      navigate(`/events/${eventId}/guests/${alphabet}`)
      setIsLoading(false)
    } catch (error) {
      toast.error('There was a problem fetching guests')
      setIsLoading(false)
    }
  }

  const handleGuestCheckIn = (guest: GuestModel) => {
    // reload the guest bookings after checkin
    if (currentEvent.id) getAlphabetGuests(selectedAlphabet)
  }

  const handleFilter = async (filter: any) => {
    setCheckpointFilter(filter.checkpoint)
  }

  return (
    <>
      <Toast />
      {/* HEADER */}
      <div className='row'>
        <div className='d-flex flex-column'>
          {/* BREADCRUMB */}
          <Breadcrumbs breadcrumbs={crumbs} className='mb-5' />

          <div className='d-flex mb-10'>
            <div className='d-flex flex-grow-1 align-items-start flex-column'>
              {guests.length > 0 && (
                <button className='btn btn-link btn-color-primary' onClick={handleBackClick}>
                  <KTIcon iconName='left' className='me-2' iconType='outline' />
                  Back to Alphabets
                </button>
              )}
              <span className='fs-3 fw-bold'>Guests for {currentEvent.name}</span>
              {/* <span className='fs-5 text-muted'>{`showing page ${pagination.page} of ${pagination.totalPages}`}</span> */}
            </div>
            <div className='d-flex flex-rows align-items-end'>
              <GuestListFilter onFilter={handleFilter} isLoading={isLoading} />
            </div>
          </div>
        </div>
      </div>

      {/* ALPHABETS */}
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          {guests.length === 0 ? (
            <>
              {alphabets.length > 0 ? (
                <>
                  <div className='d-flex flex-wrap'>
                    {alphabets.map((alphabet, index) => (
                      <div key={index} className='d-flex flex-row'>
                        <button
                          className='btn btn-secondary w-100px h-100px symbol fw-bolder symbol-circle border-1 fs-2x me-5 mb-5'
                          onClick={() => handleAlphabetClick(alphabet)}
                        >
                          {alphabet}
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  {alphabets.length === 0 && (
                    <div className='min-h-250px d-flex flex-grow-1 border-1 justify-content-center border-dashed rounded bg-light'>
                      <div className='d-flex justify-content-center align-items-center flex-column'>
                        <div className='d-flex'>
                          <KTIcon
                            className='svg-icon-3x svg-icon-primary mb-5 '
                            iconName='flaticon-safebox-1'
                          />
                          <span className='fs-2x fw-bolder text-dark'>
                            Oh no! You do not have any guests at the moment.
                          </span>
                        </div>
                        <div className='fs-4 text-muted text-center'>
                          When patrons book your event, their names will be automatically added to
                          this guest list.
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {isMobileDevice() ? (
                <div className='d-flex flex-column'>
                  {guests.map((guest: GuestModel, index) => (
                    <>
                      {guest.tickets.map((ticket, index) => (
                        <div className='card card-custom mb-5' key={index}>
                          {/* ALPHABET and name */}
                          <div className='card-header d-flex min-w-lg-250px'>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-45px me-5'>
                                {/* first letter */}
                                <span
                                  className={clsx(`symbol-label text-white fw-bolder`, {
                                    'bg-success': ticket.status === 'active',
                                    'bg-danger': ticket.status === 'checked',
                                    'bg-secondary': ticket.status === 'pending',
                                    'bg-dark':
                                      ticket.status === 'cancelled' || ticket.status === 'locked',
                                  })}
                                >
                                  {guest.name.charAt(0)}
                                </span>
                              </div>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold text-hover-primary fs-3'>
                                  {guest.name}
                                </span>
                                <span className='text-muted'>
                                  {daysUntilDate(new Date(ticket.dateCreated), true) + ' ago'}
                                </span>
                              </div>
                            </div>

                            {/* TICKET NAME */}
                            <div className='d-flex text-end min-w-lg-200px flex-column pt-4 mb-5'>
                              <a
                                href='#'
                                className='text-dark fw-bold text-hover-primary d-block fs-5'
                              >
                                {ticket.ticket.name}
                              </a>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {formatMoney(ticket.value, currentEvent.currency, 0)}
                              </span>
                            </div>
                          </div>

                          <div className='card-body'>
                            {/* CHECKPOINTS SETTINGS */}
                            <div className='text-start d-flex flex-wrap flex-grow-1 align-items-start mb-5'>
                              {ticket.ticket.checkpointSettings?.map(
                                (checkpointSetting: any, index) => {
                                  const checkpoint = ticket.ticket?.checkpoints?.find((cp: any) => {
                                    if (cp._id === checkpointSetting.checkpoint) {
                                      return true
                                    } else {
                                      return false
                                    }
                                  })

                                  // count checks where checkpoint is the same as the current checkpoint
                                  const checks = ticket.checks.filter(
                                    (check) => check.checkpoint === checkpointSetting.checkpoint
                                  ).length

                                  // if checkpoint is in checks
                                  const checked = checks < checkpointSetting.qty ? false : true

                                  return (
                                    <span
                                      key={index}
                                      className={clsx('badge badge-md fw-bolder p-3 me-2 mb-2', {
                                        'badge-light-danger': checked,
                                        'badge-light-success': !checked,
                                      })}
                                    >
                                      {`${checkpoint?.name} (${checks}/${checkpointSetting.qty})`}
                                    </span>
                                  )
                                }
                              )}
                            </div>

                            {/* ACTION */}
                            <div className='text-end d-flex flex-column'>
                              <GuestCheckInButton
                                onGuestCheckedIn={handleGuestCheckIn}
                                ticket={ticket}
                                guest={guest}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                </div>
              ) : (
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>Guest Tickets</th>
                        <th className='min-w-140px'>Ticket</th>
                        <th className='min-w-120px'>Checkpoints</th>
                        <th className='min-w-100px text-end'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {guests.map((guest: GuestModel, index) => (
                        <>
                          {guest.tickets.map((ticket, index) => (
                            <tr key={index}>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='symbol symbol-45px me-5'>
                                    {/* first letter */}
                                    <span
                                      className={clsx(`symbol-label text-white fw-bolder`, {
                                        'bg-success': ticket.status === 'active',
                                        'bg-danger': ticket.status === 'checked',
                                        'bg-secondary': ticket.status === 'pending',
                                        'bg-dark':
                                          ticket.status === 'cancelled' ||
                                          ticket.status === 'locked',
                                      })}
                                    >
                                      {guest.name.charAt(0)}
                                    </span>
                                  </div>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='text-dark fw-bold text-hover-primary fs-3'>
                                      {guest.name}
                                    </span>
                                    <span className='text-muted'>
                                      {daysUntilDate(new Date(ticket.dateCreated), true) + ' ago'}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className='text-dark fw-bold text-hover-primary d-block fs-5'>
                                  {ticket.ticket.name}
                                </span>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                  {formatMoney(ticket.value, currentEvent.currency, 0)}
                                </span>
                              </td>
                              <td className='text-start'>
                                {ticket.ticket.checkpointSettings?.map(
                                  (checkpointSetting: any, index) => {
                                    // 1. CHECK IF CHECKPOINT FILTER IS SET AND SKIP IF NOT MATCHING
                                    const skip =
                                      checkpointFilter &&
                                      checkpointFilter !== checkpointSetting.checkpoint

                                    //2. GET CURRENT CHECKPOINT (POPULATED)
                                    const checkpoint = ticket.ticket?.checkpoints?.find(
                                      (cp: any) => {
                                        if (cp._id === checkpointSetting.checkpoint) {
                                          return true
                                        } else {
                                          return false
                                        }
                                      }
                                    )

                                    // count checks where checkpoint is the same as the current checkpoint
                                    const checks = ticket.checks.filter(
                                      (check) => check.checkpoint === checkpointSetting.checkpoint
                                    ).length

                                    // if checkpoint is in checks
                                    const checked = checks < checkpointSetting.qty ? false : true

                                    return (
                                      <>
                                        {!skip && (
                                          <>
                                            <span
                                              key={index}
                                              className={clsx('badge fw-bolder me-2', {
                                                'badge-light-danger': checked,
                                                'badge-light-success': !checked,
                                              })}
                                            >
                                              {`${checkpoint?.name} (${checks}/${checkpointSetting.qty})`}
                                            </span>
                                          </>
                                        )}
                                      </>
                                    )
                                  }
                                )}
                              </td>
                              <td className='text-end'>
                                <GuestCheckInButton
                                  onGuestCheckedIn={handleGuestCheckIn}
                                  checkpointFilter={checkpointFilter}
                                  ticket={ticket}
                                  guest={guest}
                                />
                              </td>
                            </tr>
                          ))}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default GuestListPage
