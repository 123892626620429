import axios from "axios"
import { RegistrationModel } from "./authStore"

export const LOGIN_URL = `/auth/login`
export const REGISTER_URL = `/auth/register`
export const FORGOT_PASSWORD_URL = `/auth/forgot-password`
export const RESET_PASSWORD_URL = `/auth/reset-password`
export const VERIFY_EMAIL_URL = `/auth/verify-email`
export const HANDLE_EXISTS = `/auth/handle-exists`
export const EMAIL_EXISTS = `/auth/email-exists`
export const SELECT_ACCOUNT = `/auth/select-account`
export const REFRESH_TOKENS = `/auth/refresh-tokens`
export const VERIFY_TOKEN = `/auth/verify-token`
export const DELETE_USER = `/auth/user`
export const GET_RIGHTS = `/auth/rights`

// Server should return AuthModel
export function _login(email: string, password: string) {

  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function _register(args: RegistrationModel) {

  return axios.post(REGISTER_URL,
    args)
}


/**
 * Check if handle exists
 * @param handle
 * @returns {Promise<AxiosResponse<T>>} 
 */
export function _handleExists(handle: string) {

  return axios.post<{ exists: boolean }>(HANDLE_EXISTS, {
    handle,
  })
}

/**
 * Check if email exists
 * @param email 
 * @returns 
 */
export function _emailExists(email: string) {

  return axios.post<{ exists: boolean }>(EMAIL_EXISTS, {
    email,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function _forgotPassword(email: string) {

  return axios.post<{ result: boolean }>(FORGOT_PASSWORD_URL, {
    email,
  })
}

export function _resetPassword({ token, password }: { token: string, password: string }) {

  return axios.post<{ result: boolean }>(RESET_PASSWORD_URL, {
    token, password
  })
}

export function _verifyEmail(token: string | null) {

  return axios.post<{ result: boolean }>(VERIFY_EMAIL_URL, {
    token,
  })
}

export function _selectAccount(auId: string) {

  // get the updated token

  return axios.post(SELECT_ACCOUNT + `/${auId}`)
}

export function _getFreshTokens(refreshToken: string) {

  return axios.post(REFRESH_TOKENS, { refreshToken })
}

export function _verifyToken(accessToken: string) {

  return axios.post(VERIFY_TOKEN, { accessToken: accessToken })
}

export function _getRights() {
  return axios.get(GET_RIGHTS)
}

export function _deleteUser(userId) {
  return axios.delete(DELETE_USER + `/${userId}`)
}