import {useEffect, useState} from 'react'
import {Breadcrumbs} from '../../../_components/SBPageTitle/Breadcrumbs'
import {KTIcon} from '../../../_metronic/helpers'

import {EventSalesOrBookingsCard} from './EventSinglePartials/EventSalesOrBookingsCard'
import {useParams} from 'react-router-dom'
import {PageDataModel} from '../../../_components/SBPageTitle/PageDataStore'
import {useEventsStore} from './core/eventsStore'
import {trimCharacters} from '../../../_helpers/_helpers'
import {AnalyticsTicketsCards} from './EventAnalyticsPartials/AnalyticsTicketsCard'
import {AnalyticsTicketsProgressCards} from './EventAnalyticsPartials/AnalyticsTicketsProgressCard'

const EventAnalyticsPage = () => {
  const [crumbs, setCrumbs] = useState<PageDataModel[]>([])
  const {eventId} = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const {getEventDashboard, currentEvent, getEvent} = useEventsStore()

  // SETUP PAGE
  useEffect(() => {
    // if current event is not set
    if (eventId) {
      if (currentEvent.id) {
        setIsLoading(true)
        getEventDashboard(eventId).finally(() => {
          setIsLoading(false)
        })
      } else {
        // promise all:  get event and dashboard
        Promise.all([getEvent(eventId), getEventDashboard(eventId)]).finally(() => {
          setIsLoading(false)
        })
      }
    }
    // eslint-disable-next-line
  }, [eventId])

  // SETUP BREADCRUMBS
  useEffect(() => {
    // check if event exists
    const crumbs: PageDataModel[] = [
      {
        object: 'Events',
        title: 'All',
        link: '/events',
      },
      {
        object: `${trimCharacters(currentEvent.name, 12)}`,
        title: `${trimCharacters(currentEvent.name, 12)}`,
        link: `/events/${eventId}`,
      },
      {
        object: 'Analytics',
        title: `Analytics`,
        link: `/events/${eventId}/analytics`,
      },
    ]

    setCrumbs(crumbs)

    // eslint-disable-next-line
  }, [isLoading, currentEvent])

  return (
    <>
      {/* HEADER */}
      <div className='row'>
        <div className='d-flex flex-column'>
          {/* BREADCRUMB */}
          <Breadcrumbs breadcrumbs={crumbs} className='mb-5' />

          <div className='d-flex justify-content-between mb-10'>
            <div className='d-flex flex-column'>
              <span className='fs-5 fs-lg-3 fw-bold'>Analytics for {currentEvent.name} </span>
              <span className='d-flex fs-7 text-muted align-items-center'>
                <KTIcon
                  iconName='information-3'
                  className='text-warning me-2 fs-3'
                  iconType='solid'
                />
                <span className='fw-normal'>
                  Page Under Construction: More analytics coming soon. Please report any
                  inaccuracies
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* BODY */}
      <div className='d-flex flex-wrap'>
        <AnalyticsTicketsProgressCards className='p-3 m-3 m-0 w-100 w-lg-400px' />
        <AnalyticsTicketsCards className='p-0 m-3 m-0 w-100 w-lg-400px' />
        <EventSalesOrBookingsCard
          className='m-3 w-100 m0-0 w-lg-400px'
          chartType='bookings'
          chartColor='warning'
        />
      </div>
    </>
  )
}

export {EventAnalyticsPage}
