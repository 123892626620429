import {Route, Routes, Navigate} from 'react-router-dom'
import {ClientPage} from '../modules/clients/ClientPage'
import {GuestLayout} from '../../_metronic/layout/GuestLayout'
import {SingleEventPage} from '../modules/events/SingleEventPage'
import {BookingsPage} from '../modules/bookings/BookingsPage'
import {EventStatementPage} from '../modules/events/EventStatementPage'
import {SingleBookingPage} from '../modules/bookings/SingleBookingPage'
import {EventAnalyticsPage} from '../modules/events/EventAnalyticsPage'

const ClientRoutes = () => {
  return (
    <Routes>
      <Route element={<GuestLayout />}>
        {/* redirect to client page after successful client login  */}
        <Route path='auth/*' element={<Navigate to='/client' />} />

        {/* ALL ROUTE INCLUDE client/ ) */}
        <Route path='events/:eventId/*' element={<SingleEventPage />} />
        <Route path='events/:eventId/bookings/*' element={<BookingsPage />} />
        <Route path='events/:eventId/statement/*' element={<EventStatementPage />} />
        <Route path='events/:eventId/analytics/*' element={<EventAnalyticsPage />} />
        <Route path='client' element={<ClientPage />} />
        <Route path='events' element={<Navigate to='/client' />} />

        {/* BOOKINGS */}
        <Route path='bookings/:bookingId/*' element={<SingleBookingPage />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {ClientRoutes}
