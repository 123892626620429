/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useEventsStore} from './core/eventsStore'
import {useNavigate, useParams} from 'react-router-dom'
import {eventDateLabel} from '../../../_helpers/_templateHelpers'
import {formatDateDMYHI, formatMoney} from '../../../_helpers/_helpers'
import {KTIcon} from '../../../_metronic/helpers'
import MapViewer from '../../../_components/MapViewer'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import clsx from 'clsx'
import {Helmet} from 'react-helmet'
import {useAppSettingsStore} from '../settings/core/appSettingsStore'

const EventPublicPage: FC = () => {
  const {getPublicEvent, publicEvent} = useEventsStore()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const {eventId} = useParams<{eventId: string}>()
  const [bookingsPCT, setBookingsPCT] = useState<number>(0)
  const {appSettings} = useAppSettingsStore()
  const navigate = useNavigate()
  const [ogData, setOgData] = useState({
    title: '',
    description: '',
    url: '',
    image: '',
    siteName: '',
    type: 'event',
    street_address: '',
    start_time: '',
    end_time: '',
  })

  useState(() => {
    // get event id
    setIsLoading(true)
    // if current event is not set
    if (eventId && eventId !== 'null') {
      getPublicEvent(eventId)
        .then((event) => {
          const pct = Math.ceil(
            (event.stats.bookingsCount / (event.stats.ticketsTotalQty || 1)) * 100
          )
          setBookingsPCT(pct)

          // set og data
          const og = {
            title: event.name,
            description: event.excerpt,
            url: `${appSettings.baseUrl}/events/${event.id}`,
            image: event.artwork,
            siteName: appSettings.name,
            type: 'event',
            street_address: '',
            start_time: event.startDate,
            end_time: event.endDate,
          }

          if (event.location) og.street_address = event.location
          setOgData(og)
        })

        .finally(() => {
          setIsLoading(false)
        })
    }

    // show header when scroll position is top

    const scrollHandler = () => {
      const header = document.querySelector('.header')
      const bannerHeight = document.querySelector('.artwork')?.clientHeight || 0
      const headerHeight = document.querySelector('.header')?.clientHeight || 0

      if (window.scrollY > bannerHeight - headerHeight) {
        header?.classList.add('d-block')
        header?.classList.remove('d-none')
      } else {
        header?.classList.add('d-none')
        header?.classList.remove('d-block')
      }
    }

    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  })

  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          <Helmet>
            <title>skybookings.com</title>
            <meta property='og:title' content={ogData.title} />
            <meta property='og:description' content={ogData.description} />
            <meta property='og:url' content={ogData.url} />
            <meta property='og:image' content={ogData.image} />
            <meta property='og:site_name' content={ogData.siteName} />
            <meta property='og:type' content={ogData.type} />
            <meta property='og:street_address' content={ogData.street_address} />
            <meta property='og:start_time' content={ogData.start_time} />
            <meta property='og:end_time' content={ogData.end_time} />
          </Helmet>

          {/* Banner */}
          <div
            className={clsx('d-flex justify-content-end flex-column flex-grow-1 bg-black artwork', {
              'banner-background': !isMobileDevice(),
            })}
            style={{
              backgroundImage: !isMobileDevice() ? `url(${publicEvent.banner})` : '',
            }}
          >
            {isMobileDevice() && (
              <>
                <img src={publicEvent.artwork} alt={publicEvent.name} className='w-100' />
              </>
            )}
            <div
              className={clsx(
                'd-flex flex-column container justify-content-lg-center justify-content-end text-white',
                {
                  'p-8 p-lg-15 p-xl-20': isMobileDevice(),
                }
              )}
              style={{zIndex: 1}}
            >
              <h1 className='display-5 mb-0 display-lg-3 text-white text-shadow'>
                {publicEvent.name}
              </h1>
              <div className='d-flex mb-5 mb-lg-10'>By {publicEvent.organiserName}</div>

              <div className='d-flex'>
                <div className='d-flex flex-wrap'>
                  {/* date */}
                  <div className='fs-4 me-4 mb-3 d-flex text-shadow d-block-inline align-items-center'>
                    <KTIcon
                      iconName='calendar'
                      iconType='solid'
                      className='me-2 fs-4x text-light'
                    />
                    <div className='d-flex flex-column fw-bold'>
                      <span
                        className='me-2 text-shadow w-100'
                        dangerouslySetInnerHTML={{
                          __html: eventDateLabel(publicEvent.startDate, publicEvent.endDate),
                        }}
                      />
                      <span className='fw-normal text-shadow'>
                        {formatDateDMYHI(publicEvent.startDate)}
                      </span>
                    </div>
                  </div>

                  <div className='fs-4 me-4 mb-3 d-flex text-shadow align-items-center'>
                    <KTIcon
                      iconName='geolocation'
                      iconType='solid'
                      className='me-2 fs-4x text-light'
                    />
                    <div className='d-flex flex-column'>
                      <span className='fw-bold text-shadow'>Event Location:</span>
                      <span className='fw-normal text-shadow'>{publicEvent.location}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end:: banner */}

          {/* begin:: call to action section */}
          <div className='bg-black p-5 pb-10'>
            <div className='container'>
              <div className='row'>
                <div className='col-xs-12 col-lg-8 pe-5 pe-lg-15 d-flex flex-column justify-content-center'>
                  <p className='text-white fs-5'>{publicEvent.excerpt}</p>

                  {/* tickets available from */}
                  {publicEvent.sellTickets && (
                    <>
                      <div className='d-flex mb-4'>
                        <span className='text-white fs-4 me-2'>Available from</span>
                        <span className='text-white fs-4 fw-bold'>
                          {formatMoney(publicEvent.stats.minTicketPrice, publicEvent.currency, 0)}
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className='col-xs-12 col-lg-4'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex flex-column'>
                      {/* booking button */}
                      <button
                        onClick={() => navigate('booking')}
                        disabled={publicEvent.canBook.canBook === false}
                        className='btn btn-danger btn-lg fw-bolder w-100 mb-5'
                      >
                        {publicEvent.canBook.canBook === false
                          ? publicEvent.canBook.code === 'eventFull'
                            ? 'Sold Out'
                            : 'Not Available'
                          : publicEvent.buttonLabel}
                      </button>
                      {bookingsPCT > 80 && (
                        <>
                          <div className='d-flex flex-column text-white'>
                            <div className='d-flex justify-content-between fw-bold fs-6 opacity-75 w-100 mb-2'>
                              <span>{publicEvent.stats.bookingsCount} tickets booked</span>
                              <span>{bookingsPCT}%</span>
                            </div>

                            <div className='h-8pxw-100 bg-secondary bg-opacity-50 rounded'>
                              <div
                                className='bg-primary rounded h-8px'
                                role='progressbar'
                                style={{width: `${bookingsPCT}%`}}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end:: call to action section */}

          {/* create fixed header, only visible when scroll position:top is 0 */}
          <div className='header fixed-top bg-black p-5 d-none'>
            <div className='container'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center'>
                  <div
                    style={{
                      backgroundImage: `url(${publicEvent.artwork})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundColor: 'black',
                    }}
                    className='w-40px h-40px symbol symbol-40px border-1 border-grey(400)'
                  />
                  <div className='d-flex flex-column justify-content-center ms-3 mt-1'>
                    <h1 className='fs-7 fs-lg-3 mb-0 fw-bolder text-light pb-0 title'>
                      {publicEvent.name}
                    </h1>

                    {publicEvent.sellTickets && (
                      <div className=' text-muted mb-1 fs-7'>
                        Available from{' '}
                        {formatMoney(publicEvent.stats.minTicketPrice, publicEvent.currency, 0)}
                      </div>
                    )}
                  </div>
                </div>
                <div className='d-flex'>
                  <button
                    onClick={() => navigate('booking')}
                    disabled={publicEvent.canBook.canBook === false}
                    className='btn btn-danger btn-sm'
                  >
                    {publicEvent.canBook.canBook === false
                      ? publicEvent.canBook.code === 'eventFull'
                        ? 'Sold Out'
                        : 'Not Available'
                      : publicEvent.buttonLabel}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* begin:  map */}
          <div className='map mb-20'>
            {publicEvent.location && <MapViewer zoom={12} location={publicEvent.location} />}
          </div>
          {/* end: map */}

          <div className='single-event container mt-10'>
            <div className='row'>
              <div className='col-12 col-lg-8'>
                {/* Details */}
                <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 p-5 p-lg-0'>
                  <li className='nav-item'>
                    <a
                      className='nav-link active text-active-dark fs-4'
                      data-bs-toggle='tab'
                      href='#kt_tab_pane_1'
                    >
                      Event Details
                    </a>
                  </li>

                  {/* Coming Soon */}
                  <li className='nav-item'>
                    <a
                      className='nav-link text-active-dark fs-4'
                      data-bs-toggle='tab'
                      href='#kt_tab_pane_2'
                    >
                      Organiser
                    </a>
                  </li>
                </ul>

                <div className='tab-content p-5 p-lg-0' id='myTabContent'>
                  <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
                    <div
                      className='fs-4'
                      dangerouslySetInnerHTML={{__html: publicEvent.content}}
                    ></div>
                  </div>
                  <div className='tab-pane fade fs-4' id='kt_tab_pane_2' role='tabpanel'>
                    {/* organier details if they exist in EventModel */}
                    {publicEvent.organiserName && (
                      <div className='d-flex flex-column'>
                        <div className='d-flex mb-10'>
                          <span className='fw-bold me-2'>Organiser:</span>
                          <span>{publicEvent.organiserName}</span>
                        </div>

                        {publicEvent.organiserEmail && (
                          <div className='d-flex mb-10'>
                            <span className='fw-bold me-2'>Email:</span>
                            <a
                              rel='noreferrer'
                              target='_blank'
                              href={`mailto:${publicEvent.organiserEmail}`}
                            >
                              {publicEvent.organiserEmail}
                            </a>
                          </div>
                        )}

                        {publicEvent.organiserPhone && (
                          <div className='d-flex mb-10'>
                            <span className='fw-bold me-2'>Phone:</span>
                            <a
                              rel='noreferrer'
                              target='_blank'
                              href={`tel:${publicEvent.organiserPhone}`}
                            >
                              {publicEvent.organiserPhone}
                            </a>
                          </div>
                        )}

                        {/* organiserWebsite */}
                        {publicEvent.organiserWebsite && (
                          <div className='d-flex mb-10'>
                            <span className='fw-bold me-2'>Website:</span>

                            <a rel='noreferrer' target='_blank' href={publicEvent.organiserWebsite}>
                              {publicEvent.organiserWebsite}
                            </a>
                          </div>
                        )}

                        {/* organiserWhatsapp */}
                        {publicEvent.organiserWhatsapp && (
                          <div className='d-flex mb-10'>
                            <span className='fw-bold me-2'>WhatsApp:</span>
                            <a
                              rel='noreferrer'
                              target='_blank'
                              href={`https://wa.me/${publicEvent.organiserWhatsapp}`}
                            >
                              {publicEvent.organiserWhatsapp}
                            </a>
                          </div>
                        )}

                        {/* organiserFB */}
                        {publicEvent.organiserFB && (
                          <div className='d-flex mb-10'>
                            <span className='fw-bold me-2'>Facebook:</span>
                            <a rel='noreferrer' target='_blank' href={publicEvent.organiserFB}>
                              {publicEvent.organiserFB}
                            </a>
                          </div>
                        )}

                        {/* organiserX */}
                        {publicEvent.organiserX && (
                          <div className='d-flex mb-10'>
                            <span className='fw-bold me-2'>(X)Twitter:</span>
                            <a
                              rel='noreferrer'
                              target='_blank'
                              href={`https://x.com/${publicEvent.organiserX}`}
                            >
                              {publicEvent.organiserX}
                            </a>
                          </div>
                        )}

                        {/* organiserIG */}
                        {publicEvent.organiserIG && (
                          <div className='d-flex mb-10'>
                            <span className='fw-bold me-2'>Instagram:</span>
                            <a
                              rel='noreferrer'
                              target='_blank'
                              href={`https://instagram.com/${publicEvent.organiserIG}`}
                            >
                              {publicEvent.organiserIG}
                            </a>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='col-12 col-lg-4 d-none d-lg-block'>
                {publicEvent.artwork && (
                  <div className='mb-5'>
                    <img
                      src={publicEvent.artwork}
                      alt={publicEvent.name}
                      className='img-fluid rounded'
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* end::Row */}
        </>
      )}
    </>
  )
}

export {EventPublicPage}
