/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAuthStore} from '../core/authStore'
import {PatronAuth} from '../../patrons/PatronAuth'
import {createBrowserHistory} from 'history'
import {ClientAuth} from '../../clients/ClientAuth'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [currentLocation, setCurrentLocation] = useState('')
  const {login} = useAuthStore()
  const navigate = useNavigate()
  const history = createBrowserHistory()

  useEffect(() => {
    // get route path using the useLocation hook
    console.log(history.location.pathname)
    if (history.location.pathname === '/auth/' || history.location.pathname === '/auth') {
      history.push('/auth/login')
    }
    setCurrentLocation(history.location.pathname)
  }, [history])

  const handleChangeBrowswerHistory = (path: string) => {
    setCurrentLocation(path)
    history.push(path)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      const loginStatus = await login(values.email, values.password)
      setSubmitting(false)
      setLoading(false)

      if (loginStatus) {
        navigate('/select-account')
      } else {
        setStatus('Invalid email or password')
      }
    },
  })

  return (
    <div className='text-center mb-11'>
      <div className='text-gray-500 d-flex flex-column align-items-center fw-semibold fs-6'>
        {/* nav links to switch login mode */}
        <div className='d-flex flex-columns mb-5 flex-lg-rows align-items-center justify-content-center fs-6'>
          <div className='d-flex fs-5 mt-0 me-5 text-dark fw-bold'>login as</div>
          <ul className='nav nav-pills fs-6  p-lg-0'>
            {/* main user login option */}
            <li className='nav-item'>
              <button
                onClick={() => handleChangeBrowswerHistory('/auth/login')}
                className={clsx(
                  'btn-link bg-active-primary nav-link text-muted text-active-white fs-5',
                  {
                    active: currentLocation === '/auth/login' || currentLocation === '/auth',
                  }
                )}
                data-bs-toggle='tab'
              >
                a curator
              </button>
            </li>
            {/* patron button */}
            <li className='nav-item'>
              <button
                onClick={() => handleChangeBrowswerHistory('/auth/patron')}
                className={clsx(
                  'btn-link bg-active-primary nav-link text-muted text-active-white fs-5',
                  {
                    active: currentLocation === '/auth/patron',
                  }
                )}
                data-bs-toggle='tab'
              >
                a patron
              </button>
            </li>

            {/* login as client */}
            <li className='nav-item'>
              <button
                onClick={() => handleChangeBrowswerHistory('/auth/client')}
                className={clsx(
                  'btn-link bg-active-primary nav-link text-muted text-active-white fs-5',
                  {
                    active: currentLocation === '/auth/client',
                  }
                )}
                data-bs-toggle='tab'
              >
                a client
              </button>
            </li>
          </ul>
        </div>

        {currentLocation === '/auth/login' && (
          <>
            {/* user tab */}
            <div className='p-0 m-0 w-100 w-lg-400px'>
              <div className='mb-10 text-dark'>Manage events, sell tickts, and get analytics.</div>
              <div className='card card-custom p-10'>
                <form
                  className='form w-100 text-start'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  {formik.status ? (
                    <div className='mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                  ) : null}

                  {/* begin::Form group */}
                  <div className='fv-row mb-8'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                    <input
                      placeholder='Email'
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                      type='email'
                      name='email'
                      autoComplete='off'
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}

                  {/* begin::Form group */}
                  <div className='fv-row mb-3'>
                    <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                    <input
                      type='password'
                      autoComplete='off'
                      {...formik.getFieldProps('password')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                          'is-valid': formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}

                  {/* begin::Wrapper */}
                  <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                    <div />

                    {/* begin::Link */}
                    <Link to='/auth/forgot-password' className='link-primary'>
                      Forgot Password ?
                    </Link>
                    {/* end::Link */}
                  </div>
                  {/* end::Wrapper */}

                  {/* begin::Action */}
                  <div className='d-grid mb-10'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {!loading && <span className='indicator-label'>Continue</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Action */}

                  <div className='text-gray-500 text-center fw-semibold fs-6'>
                    Not a registered user yet?{' '}
                    <Link to='/auth/registration' className='link-primary'>
                      Sign up
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}

        {currentLocation === '/auth/patron' && (
          <>
            {/* patron tab */}
            <div className='p-0 m-0 w-100 w-lg-400px'>
              <div className='mb-10 text-dark'>
                Retrieve tickets, modify bookings and make payments.
              </div>
              <div className='card card-custom p-10'>
                <div className='text text-start'>
                  <PatronAuth onLoggedIn={() => navigate('/patron')} />
                </div>
              </div>
            </div>
          </>
        )}

        {currentLocation === '/auth/client' && (
          <>
            {/* patron tab */}
            <div className='p-0 m-0 w-100 w-lg-400px'>
              <div className='mb-10 text-dark'>
                Keep track of managed events, analytics and bookings.
              </div>
              <div className='card card-custom p-10'>
                <div className='text text-start'>
                  <ClientAuth onLoggedIn={() => navigate('/client')} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
