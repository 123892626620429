import {FieldArray, Form, Formik} from 'formik'
import React, {FC, useEffect} from 'react'
import {BookingModel, BookingTicketModel, bookingStore} from '../core/bookingsStore'
import {usePatronStore} from '../../patrons/core/patronStore'
import {eventsStore} from '../../events/core/eventsStore'
import {useNavigate, useParams} from 'react-router-dom'
import toast, {Toaster} from 'react-hot-toast'
import {formatNumber, getCurrencySymbol} from '../../../../_helpers/_helpers'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {BookingTicketItem} from '../PublicBookingPartials/BookingTicketItem'
import {PatronSearchAuth} from '../../patrons/PatronSearchAuth'
import {TransactionModel, useTransactionStore} from '../../transactions/core/transactionsStore'
import * as Yup from 'yup'

const manualPaymentValidationSchema = Yup.object().shape({
  transactionDescription: Yup.string().required('Description is required'),
  transactionAmount: Yup.number()
    .min(0)
    .required('Amount is required')
    .test(
      'less-than-booking-value',
      'Transaction Amount must be less than booking value',
      (value, context) => {
        const max = context.parent.bookingTickets.reduce(
          (acc, bookingTicket) => acc + bookingTicket.qty * bookingTicket.ticket.price,
          0
        )
        if (value > max) {
          return false
        } else {
          return true
        }
      }
    ),
})

const ManualBookingButton: FC = () => {
  const {currentBooking, syncTickets, saveBooking, resetCurrentBooking} = bookingStore()
  const {postTransaction} = useTransactionStore()
  const {currentPatron} = usePatronStore()
  const {publicEvent, getPublicEvent} = eventsStore()
  const {eventId} = useParams<{eventId: string}>()
  const [manuallyAddPayment, setManuallyAddPayment] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const [amountTouched, setAmountTouched] = React.useState<boolean>(false)
  useEffect(() => {
    resetCurrentBooking()
    setIsLoading(true)

    if (eventId) {
      // get event id
      getPublicEvent(eventId)
        .then((res) => {
          syncTickets(res.tickets)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  const handleSubmit = async (values: BookingModel, action) => {
    setIsLoading(true)
    try {
      // 1. CREATE THE BOOKING
      const payload: BookingModel = {
        ...values,
        event: {id: publicEvent.id || ''},
        patron: {id: currentPatron.id || ''},
        value: values.bookingTickets.reduce(
          (acc, bookingTicket) => acc + bookingTicket.qty * bookingTicket.ticket.price,
          0
        ),
      }
      const booking = await saveBooking(payload)

      //   manually add payment
      if (manuallyAddPayment) {
        // get amount
        const amount =
          (values.bookingTickets.length &&
            !amountTouched &&
            values.bookingTickets.reduce(
              (acc, bookingTicket) => acc + bookingTicket.qty * bookingTicket.ticket.price,
              0
            )) ||
          values.transactionAmount

        // 2. CREATE THE PAYMENT
        const paymentPayload: TransactionModel = {
          patron: booking.patron.id || '0',
          booking: booking.id || '0',
          status: 'complete',
          amount: amount,
          description: values?.transactionDescription || 'Manual Payment',
          type: 'payment',
          gateway: 'manual',
          gatewayTransactionId: '0',
          reconciled: false,
        }
        if (booking.event && booking.event.id) {
          paymentPayload.event = booking.event.id
        }

        await postTransaction(paymentPayload)
      }

      //   redirect to the booking page
      setIsLoading(false)
      resetCurrentBooking()
      navigate(`/bookings/${booking.id}/`)
    } catch (err: any) {
      setIsLoading(false)
      toast.error(err.data.response.message)
      return
    }
  }

  return (
    <>
      <Toaster position='top-center' />
      {/* PATRON LOGIN */}

      {publicEvent.canBook.canBook && (
        <div className='btn btn-sm btn-light-info' onClick={() => setShowModal(true)}>
          {isLoading ? (
            <>
              <span className='spinner-border spinner-border-sm me-3'></span>
              <span className=''>Just a sec</span>
            </>
          ) : (
            <>
              <KTIcon className='fs-2' iconType='outline' iconName='plus' />
              Add Booking
            </>
          )}
        </div>
      )}

      <Modal show={showModal} className='modal-lg' onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='accordion accordion-flush mb-5' id='bookingForm'>
            <div className='accordion-item'>
              <h3 className='accordion-header fw-bolder text-dark' id='search-patron'>
                <button
                  className='accordion-button fw-bolder bg-light fs-4 text-dark'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#search-patron-collapse'
                  aria-expanded='false'
                  aria-controls='search-patron-collapse'
                >
                  Step 1. Search or Create Patron
                  <span className='ms-3 text-muted fw-normal fs-5'>
                    {currentPatron.id
                      ? 'Add booking under ' + currentPatron.name
                      : 'No Patron Selected'}
                  </span>
                </button>
              </h3>
            </div>
            <div
              id='search-patron-collapse'
              className='accordion-collapse collapse'
              aria-labelledby='search-patron-collapse'
              data-bs-parent='#bookingForm'
            >
              <div className='accordion-body'>
                {!currentPatron.id && (
                  <>
                    <p className='text-muted'>
                      An accurate phone number is important to distribute notifications to the
                      correct recipient
                    </p>
                  </>
                )}
                <PatronSearchAuth />
              </div>
            </div>
          </div>

          <Formik
            initialValues={currentBooking}
            validationSchema={manualPaymentValidationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {(form) => {
              return (
                <Form>
                  {/* d-flex flex-column flex-row justify-content-center align-items-center */}
                  <div className='accordion accordion-flush' id='bookingForm-2'>
                    {currentPatron.id && (
                      <div className='accordion-item mb-5'>
                        <div className='accordion-header'>
                          <h2 className='card-title fw-bolder fs-4'>
                            <button
                              className='accordion-button fw-bolder bg-light fs-4 text-dark'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#flush-basic-collapse'
                              aria-expanded='false'
                              aria-controls='flush-basic-collapse'
                            >
                              Step 2. Choose Tickets
                              {publicEvent.sellTickets && (
                                <span className='text-muted fs-5 fw-normal ms-5'>
                                  Total: {getCurrencySymbol(publicEvent.currency)}{' '}
                                  {formatNumber(
                                    form.values.bookingTickets
                                      ? form.values.bookingTickets.reduce(
                                          (acc, bookingTicket) =>
                                            acc + bookingTicket.qty * bookingTicket.ticket.price,
                                          0
                                        )
                                      : 0,
                                    0
                                  )}
                                </span>
                              )}
                            </button>
                          </h2>
                        </div>
                        <div
                          id='flush-basic-collapse'
                          className='accordion-collapse collapse'
                          aria-labelledby='flush-basic-collapse'
                          data-bs-parent='#bookingForm-2'
                        >
                          <div className='accordion-body'>
                            <FieldArray name='bookingTickets'>
                              {({push, remove, form}) => {
                                return (
                                  form.values.bookingTickets &&
                                  form.values.bookingTickets.map(
                                    (bookingTicket: BookingTicketModel, index) => (
                                      <BookingTicketItem
                                        key={bookingTicket.ticket.id}
                                        index={index}
                                        bookingTicket={bookingTicket}
                                        form={form}
                                      />
                                    )
                                  )
                                )
                              }}
                            </FieldArray>

                            {publicEvent.sellTickets && (
                              <div className='card-footer'>
                                <h3 className='d-flex justify-content-end '>
                                  Total: {getCurrencySymbol(publicEvent.currency)}{' '}
                                  {formatNumber(
                                    form.values.bookingTickets
                                      ? form.values.bookingTickets.reduce(
                                          (acc, bookingTicket) =>
                                            acc + bookingTicket.qty * bookingTicket.ticket.price,
                                          0
                                        )
                                      : 0,
                                    0
                                  )}
                                </h3>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* accordion item for payments */}
                    {currentPatron.id && (
                      <div className='accordion-item' id='payment-panel'>
                        <div className='accordion-header fs-4 fw-bolder'>
                          <button
                            className='accordion-button bg-light fw-bolder fs-4 text-dark'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#payment-panel-collapse'
                            aria-expanded='false'
                            aria-controls='payment-panel-collapse'
                          >
                            {publicEvent.sellTickets
                              ? 'Step 3. How do you want to handle payment?'
                              : 'Step 3. Confirm Booking'}
                          </button>
                        </div>
                        <div
                          id='payment-panel-collapse'
                          className='accordion-collapse collapse'
                          aria-labelledby='payment-panel'
                          data-bs-parent='#bookingForm-2'
                        >
                          <div className='accordion-body'>
                            {publicEvent.sellTickets && (
                              <>
                                <div className='form-check form-check-custom form-check-solid mb-5'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='flexCheckDefault'
                                    value={manuallyAddPayment ? 'true' : 'false'}
                                    onChange={() => setManuallyAddPayment(!manuallyAddPayment)}
                                  />
                                  <label
                                    className='form-check-label fw-bold text-dark'
                                    htmlFor='flexCheckDefault'
                                  >
                                    Add Manual Payment
                                  </label>
                                </div>
                              </>
                            )}

                            {/* payment method */}
                            {manuallyAddPayment && (
                              <>
                                <div className='fv-row mb-5 row'>
                                  <div className='col col-md-12'>
                                    <label className='form-label required'>
                                      Booking Description
                                    </label>
                                    <input
                                      type='text'
                                      defaultValue={'Manual Payment'}
                                      className='form-control form-control-lg '
                                      name='transactionDescription'
                                    />
                                  </div>
                                </div>
                                <div className='fv-row mb-10 row'>
                                  <div className='col col-lg-6'>
                                    <label className='form-label required'>Payment Method</label>
                                    <select className='form-select' name='transactionPaymentMethod'>
                                      <option value='cash'>Cash</option>
                                      <option value='eft'>EFT</option>
                                      <option value='card'>Card</option>
                                    </select>
                                  </div>
                                  <div className='col col-md-6 '>
                                    <label className='form-label required'>
                                      Transaction Amount
                                    </label>
                                    <input
                                      type='number'
                                      className='form-control form-control-lg'
                                      defaultValue={
                                        (form.values.bookingTickets &&
                                          !form.touched.transactionAmount &&
                                          form.values.bookingTickets.reduce(
                                            (acc, bookingTicket) =>
                                              acc + bookingTicket.qty * bookingTicket.ticket.price,
                                            0
                                          )) ||
                                        form.values.transactionAmount
                                      }
                                      max={
                                        (form.values.bookingTickets &&
                                          form.values.bookingTickets.reduce(
                                            (acc, bookingTicket) =>
                                              acc + bookingTicket.qty * bookingTicket.ticket.price,
                                            0
                                          )) ||
                                        form.values.transactionAmount
                                      }
                                      onChange={(e) => {
                                        form.setFieldValue('transactionAmount', e.target.value)
                                        setAmountTouched(true)
                                      }}
                                      name='transactionAmount'
                                    />
                                    {form.errors.transactionAmount && (
                                      <div className='text-danger'>
                                        {form.errors.transactionAmount}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {/* transaction status hidden */}
                                <input type='hidden' name='transactionStatus' value='complete' />
                                <input type='hidden' name='transactionGateway' value='manual' />
                              </>
                            )}

                            <button
                              type='submit'
                              disabled={
                                form.isValid === false ||
                                (isLoading === false &&
                                  form.values.bookingTickets &&
                                  !form.values.bookingTickets.some((item) => item.qty > 0))
                              }
                              className={clsx('btn btn btn-lg w-100 fw-bolder', {
                                'btn-primary':
                                  form.values.bookingTickets &&
                                  form.values.bookingTickets.some((item) => item.qty > 0),
                                'btn-secondary':
                                  form.values.bookingTickets &&
                                  !form.values.bookingTickets.some((item) => item.qty > 0),
                              })}
                            >
                              {isLoading ? (
                                <>
                                  <span className='spinner-border spinner-border-sm align-middle me-3'></span>
                                  <span className=''>Just a sec</span>
                                </>
                              ) : (
                                <>Confirm Booking</>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {ManualBookingButton}
