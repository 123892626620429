import axios from "axios"
import { EventModel } from "./eventsStore"

export const EVENTS_ENDPOINT = `/events`
export const TICKETS_ENDPOINT = `tickets`
export const CHECKPOINTS_ENDPOINT = `checkpoints`
export const CHECKPOINT_SETTINGS_ENDPOINT = `checkpoint-settings`
export const HANDLE_EXISTS = '/events/handle-exists'

// Server should return AuthModel
export function _postEvent(args: Partial<EventModel>) {

    const data = new FormData();

    // append args into data
    for (const key in args) {
        data.append(key, args[key])
    }

    return axios.post(EVENTS_ENDPOINT, data, {
        headers: {
            'Content-Type': 'multipart/form-data: boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
        }
    })
}

export function _getEvent($id: string) {
    return axios.get(`${EVENTS_ENDPOINT}/${$id}`)
}

export function _getEventByHandle(handle: string) {
    return axios.get(`${EVENTS_ENDPOINT}/handle/${handle}`)
}

export function _getEventDashboard($id: string) {
    return axios.get(`${EVENTS_ENDPOINT}/${$id}/dashboard`)
}

export function _getEvents(query) {
    return axios.get(EVENTS_ENDPOINT, { params: { ...query } })
}

export function _searchEvents(query) {
    return axios.get(`${EVENTS_ENDPOINT}/search`, { params: { ...query } })
}

export function _patchEvent($id, args: any) {

    const data = new FormData();

    // add args to data
    for (const key in args) {
        data.append(key, args[key])
    }

    return axios.patch(`${EVENTS_ENDPOINT}/${$id}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data: boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
        }
    })
}

export function _patchEventImage($id, fieldName, image: string) {

    const data = { [fieldName]: image };
    return axios.patch(`${EVENTS_ENDPOINT}/${$id}`, data)
}

export function _handleExists(handle: string) {

    return axios.post<{ exists: boolean }>(HANDLE_EXISTS, {
        handle,
    })
}

export function _deleteEvent($id: string) {
    return axios.delete(`${EVENTS_ENDPOINT}/${$id}`)
}

// PUBLIC EVENT 

export function _getPublicEvent(eventId: string) {
    return axios.get(`${EVENTS_ENDPOINT}/${eventId}/public`)
}

// CHECKPOINTS

export function _postCheckpoint(eventId: string, args: any) {
    return axios.post(`${EVENTS_ENDPOINT}/${eventId}/${CHECKPOINTS_ENDPOINT}`, args)
}

export function _getCheckpoints(eventId: string, query: any) {
    return axios.get(`${EVENTS_ENDPOINT}/${eventId}/${CHECKPOINTS_ENDPOINT}`, { params: { ...query } })
}

export function _patchCheckpoint(eventId: string, $id, args: any) {
    return axios.patch(`${EVENTS_ENDPOINT}/${eventId}/${CHECKPOINTS_ENDPOINT}/${$id}`, args)
}

export function _deleteCheckpoint(eventId: string, $id: string) {
    return axios.delete(`${EVENTS_ENDPOINT}/${eventId}/${CHECKPOINTS_ENDPOINT}/${$id}`)
}


// TICKETS 

export function _postTicket(eventId: string, args: any) {
    return axios.post(`${EVENTS_ENDPOINT}/${eventId}/${TICKETS_ENDPOINT}`, args)
}

export function _getTickets(eventId: string, query: any) {
    return axios.get(`${EVENTS_ENDPOINT}/${eventId}/${TICKETS_ENDPOINT}`, { params: { ...query } })
}

export function _patchTicket(eventId: string, $id, args: any) {
    return axios.patch(`${EVENTS_ENDPOINT}/${eventId}/${TICKETS_ENDPOINT}/${$id}`, args)
}

export function _deleteTicket(eventId: string, $id: string) {
    return axios.delete(`${EVENTS_ENDPOINT}/${eventId}/${TICKETS_ENDPOINT}/${$id}`)
}




// TICKET CHECKPOINT SETTINGS

export function _postCheckpointSettings(eventId: string, ticketId: string, args: any) {
    return axios.post(`${EVENTS_ENDPOINT}/${eventId}/${TICKETS_ENDPOINT}/${ticketId}/${CHECKPOINT_SETTINGS_ENDPOINT}`, args)
}

export function _patchCheckpointSettings(eventId: string, ticketId: string, $id, args: any) {
    return axios.patch(`${EVENTS_ENDPOINT}/${eventId}/${TICKETS_ENDPOINT}/${ticketId}/${CHECKPOINT_SETTINGS_ENDPOINT}/${$id}`, args)
}