/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PieChartDataModel, useEventsStore} from '../core/eventsStore'
import {KTIcon} from '../../../../_metronic/helpers'
import {formatNumber, pluralise} from '../../../../_helpers/_helpers'
import {Doughnut} from 'react-chartjs-2'
import {isMobileDevice} from '../../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
}

const AnalyticsTicketsCards: FC<Props> = ({className}) => {
  const {getEventDashboard, currentEvent} = useEventsStore()

  const chartColors = [
    '#3e97ff',
    '#50cd89',
    '#cccccc',
    '#f1416c',
    '#ffc700',
    'rgba(255, 159, 64, 1)',
    '#7e57c2', // Added color: Deep Purple
    '#ff7043', // Added color: Deep Orange
    'rgba(153, 102, 255, 1)', // Added color: Soft Purple
    '#26a69a', // Added color: Teal
    '#78909c', // Added color: Blue Grey
  ]

  const [pieData, setPieData] = useState<PieChartDataModel>({
    labels: [],
    series: [],
    legend: [],
  })

  useEffect(() => {
    if (!currentEvent.id) return

    // get pie data
    getEventDashboard(currentEvent?.id).then((response) => {
      setPieData(response.bookingsPieData)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent.id])

  return (
    <>
      {currentEvent.stats.bookingsCount > 0 ? (
        <>
          <div className={`card card-flush  ${className}`}>
            <div className='card-header pt-5'>
              <div className='card-title d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  {currentEvent.sellTickets && (
                    <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>
                      {currentEvent.currency}
                    </span>
                  )}
                  <span className='display-6 display-lg-7 fw-bold text-dark me-2 lh-1 ls-n2'>
                    {currentEvent.sellTickets
                      ? formatNumber(pieData?.series.reduce((a, b) => a + b, 0) || 0, 0) +
                        ' in Sales'
                      : pieData?.series.reduce((a, b) => a + b, 0) +
                        pluralise(
                          pieData?.series.reduce((a, b) => a + b, 0),
                          ' Ticket'
                        ) +
                        ` Issued`}
                  </span>
                </div>
                {/* <div className='d-flex ms-12'>
                  <span className='text-gray-400 pt-1 fs-6'>{`from ${currentEvent.stats.bookingsTicketsCount} confirmed tickets. `}</span>
                </div> */}
              </div>
            </div>

            <div className='card-body my-5 pt-2 pb-4 d-flex flex-wrap align-items-center'>
              <div className='d-flex'>
                {/* CHART */}
                <div className='h-100px w-100px w-lg-150px h-lg-150px'>
                  <Doughnut
                    data={{
                      labels: pieData.legend || [],
                      datasets: [
                        {
                          // set data
                          data: pieData.series || [],
                          backgroundColor: chartColors,
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      aspectRatio: 1,
                      cutout: isMobileDevice() ? 20 : 30,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                </div>

                {/* LEGEND */}
                <div className='d-flex flex-column pt-5 h-100 ms-3'>
                  {pieData.legend.map((label, index) => (
                    <div key={index} className='d-flex align-items-center mb-2'>
                      <div
                        className='badge symbol badge-circle me-2 w-10px h-10px'
                        style={{backgroundColor: chartColors[index]}}
                      ></div>
                      <span className='fs-6'>{label}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='card w-100 w-lg-450px min-h-200 bg-transparent mt-3 border-1 border-dashed border-shadow-0'>
          <div className='card-body d-flex flex-center flex-column'>
            <KTIcon iconName='alert-circle' className='fs-2hx text-muted' />
            <span className='text-gray-400 fw-bold fs-3'>No bookings yet</span>
            <span className='text-gray-400 fs-6'>Bookings and sales data will appear here.</span>
          </div>
        </div>
      )}
    </>
  )
}

export {AnalyticsTicketsCards}
