import { StateCreator, create } from "zustand"
import { devtools } from "zustand/middleware"
import { _getAdminDashboard, _getDashboard } from "./dashboardApi"
import { number, string } from "yup"

export type EventsBookingsCard = {
    totalEvents,
    countBookings,
    countBookingTickets,
    countBookingsThisWeek,
    countBookingsLastWeek,
    countBookingTicketsThisWeek,
    countBookingTicketsLastWeek,
}

export type lineChartDataModel = {
    labels: string[],
    series: number[],
    total: number
}

export type walletStatsModel = {
    totalTransactions: number,
    totalBalance: number,
    totalPendingPayouts: number,
    totalPayable: number,
}

export type DashboardModel = {
    eventsBookingsCard: EventsBookingsCard,
    bookingsChart: lineChartDataModel,
    salesChart: lineChartDataModel,
    walletStats: walletStatsModel
}


type DashboardsStore = {
    dashboardData: DashboardModel,
    getDashboardData: (account: string) => Promise<void>
    getAdminDashboardData: () => Promise<void>
}


const createStore: StateCreator<DashboardsStore> = (set, get) => ({
    dashboardData: {} as DashboardModel,

    getDashboardData: async (account) => {
        // get dashboard data
        const response = await _getDashboard(account)
        set({ dashboardData: response.data })
    },

    getAdminDashboardData: async () => {
        // get admin dashboard data
        const response = await _getAdminDashboard()
        set({ dashboardData: response.data })

    }
})

export const dashboardsStore = create(devtools(createStore))
export const useDashboardsStore = dashboardsStore