import React, {FC, useEffect} from 'react'

import {ErrorMessage, Field, Form, Formik} from 'formik'
import {PatronModel, usePatronStore} from './core/patronStore'
import {useEventsStore} from '../events/core/eventsStore'
import {bookingStore} from '../bookings/core/bookingsStore'
import toast, {Toaster} from 'react-hot-toast'

import PhoneField from '../../../_components/PhoneField'
import {KTIcon} from '../../../_metronic/helpers'
import SubmitButton from '../../../_components/SubmitButton'

type Props = {
  onLoggedIn?: () => void
  onLoginError?: (error: any) => void
}

const PatronSearchAuth: FC<Props> = ({onLoggedIn}) => {
  const [createNewPatron, setCreateNewPatron] = React.useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const {publicEvent} = useEventsStore()
  const {currentPatron, isPatronLoggedIn, postPatron, searchPatron, unsetPatron, setCurrentPatron} =
    usePatronStore()
  const [bookingValue, setBookingValue] = React.useState<number>(0)
  const [buttonLabel, setButtonLabel] = React.useState<string>('Next')
  const {currentBooking} = bookingStore()

  useEffect(() => {
    // calculate the value of the tickets base on currentBooking.bookingTickets price X qty
    if (currentBooking.bookingTickets) {
      const total = currentBooking.bookingTickets.reduce((acc, bookingTicket) => {
        return acc + bookingTicket.value
      }, 0)
      setBookingValue(total)
    }

    isPatronLoggedIn && onLoggedIn && onLoggedIn()
  }, [currentBooking.bookingTickets])

  const handleSubmit = async (values: PatronModel, actions) => {
    setIsSubmitting(true)

    if (!currentPatron.id && !createNewPatron) {
      // SEARCH PATRON
      try {
        const r = await searchPatron({phone: values.phone.toString()})
        setCurrentPatron(r)
      } catch (err: any) {
        if (err.response.status === 404) {
          setCreateNewPatron(true)
          setButtonLabel('Create Patron')
        } else {
          toast.error(err.response.data.message)
        }
        setIsSubmitting(false)
      }
    } else if (createNewPatron === true) {
      // CREATE NEW PATRON
      try {
        if (publicEvent.id === null) throw new Error('Event not found')

        await postPatron(publicEvent.id, values)
        setCreateNewPatron(false)
      } catch (err: any) {
        toast.error(err.response.data.message)
      }
    }

    setIsSubmitting(false)
  }

  const handleCancelClick = async (form) => {
    if (currentPatron.id) {
      await unsetPatron()
      setCreateNewPatron(false)

      return
    } else if (createNewPatron) {
      setCreateNewPatron(false)
    }
  }

  return (
    <>
      <Formik initialValues={currentPatron} enableReinitialize onSubmit={handleSubmit}>
        {(form) => {
          return (
            <Form>
              <Toaster position='top-center' />

              <div className='d-flex flex-column w-100'>
                {currentPatron.id ? (
                  <>
                    <div className='mb-5'>
                      <div className='alert-text fs-5'>
                        You are about to create a booking for {currentPatron.name}.
                        <button
                          onClick={handleCancelClick}
                          className='fw-bold text-primary btn btn-link d-inline ms-3'
                        >
                          Use another account
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className=''>
                      <div className='mb-10 fv-row'>
                        <label className='form-label mb-3' aria-required>
                          Phone Number (required)
                        </label>
                        <PhoneField name='phone' />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='phone' />
                        </div>
                      </div>

                      {createNewPatron && (
                        <>
                          <div className='mb-10 fv-row'>
                            <label className='form-label mb-3'>Email Address (optional)</label>
                            <Field
                              type='text'
                              className='form-control form-control-lg '
                              name='email'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='email' />
                            </div>
                          </div>

                          <div className='mb-10 fv-row'>
                            <label className='form-label mb-3'>Your Name (required)</label>

                            <Field
                              type='text'
                              className='form-control form-control-lg '
                              name='name'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='name' />
                            </div>
                          </div>
                        </>
                      )}

                      <div className='mb-10 fv-row '>
                        <SubmitButton
                          label={buttonLabel}
                          isSubmitting={isSubmitting}
                          className='btn btn-primary w-100 mb-5'
                        />
                        {createNewPatron && (
                          <button
                            onClick={() => handleCancelClick(form)}
                            type='button'
                            className='btn btn-light-danger w-100'
                          >
                            <KTIcon iconName='angle-right' className='me-2' />
                            Cancel
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export {PatronSearchAuth}
