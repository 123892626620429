import { BookingModel } from "../app/modules/bookings/core/bookingsStore"
import { EventModel } from "../app/modules/events/core/eventsStore"
import { daysUntilDate, formatNumber, getCurrencySymbol } from "./_helpers"

export function eventDateLabel(startDate: string, endDate: string) {
    if (!startDate && !endDate) return ''

    if (new Date(endDate) < new Date())
        return `Ended ${daysUntilDate(new Date(endDate))} ago`

    if (new Date(startDate) < new Date() && new Date(endDate) > new Date())
        return `<span class=''>Started.</span> Ends In ${daysUntilDate(new Date(endDate))}`

    if (new Date(startDate) > new Date())
        return `Starts In ${daysUntilDate(new Date(endDate))}`

    return ''
}

export function eventStatusLabel(event: EventModel) {
    // summary label
    let label = `@${event.handle} is ${event.canBook.canBook ? 'available' : 'not available'}. `

    if (event.canBook.canBook) {

        // check if event has started
        if (new Date(event.startDate) < new Date() && new Date(event.endDate) > new Date()) {
            label += `Started. Ends in ${daysUntilDate(new Date(event.endDate))}. `
        }
        else {
            label += `Starts in ${daysUntilDate(new Date(event.startDate))}. `
        }

        // total bookings
        if (event.stats.bookingsCount > 0 && !event.sellTickets)
            label += `${event.stats.bookingsCount} bookings so far. `

        // if event sells tickets get the total sales amount
        if (event.sellTickets) {
            if (event.stats.transactionsAmount > 0) {
                const currency = getCurrencySymbol(event.currency || 'ZAR')
                const val = formatNumber(event.stats.bookingsValue, 0)
                label += `Total sales: ${currency} ${val} from ${event.stats.bookingsCount} bookings.`
            }
            else {
                label += 'No sales yet. '
            }
        }

    } else {
        label += `${event.canBook.reason}. `
    }
    return label;
}


export function bookingStatusLabel(booking: BookingModel) {
    // summary label
    const currency = getCurrencySymbol(booking.event.currency || 'ZAR')
    let label =
        `Booked ` + daysUntilDate(new Date(booking.dateCreated.toString()), true) + ` ago. `

    label += `${(booking && booking.stats && booking.stats.ticketsCount) || 0} tickets`

    if (booking.event?.sellTickets && booking.value > 0) {
        const val = formatNumber(booking.value, 0)
        label += ` valued at ${currency}${val}. `

        if ((booking.value > (booking && booking.stats && booking.stats.ticketsCount) || 0) && booking.value - booking.stats.transactionsValue > 0) {



            const pendingPaymentVal = formatNumber(
                booking.value - booking.stats.transactionsValue,
                0
            )

            label += `Pending payment of ${currency}${pendingPaymentVal}. `

        } else {
            label += `Fully Paid. `
        }
    } else {
        label += '.'
    }

    return label;
}